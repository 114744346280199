import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import AddCryptoFundsModal, {
  AddCryptoFundsStates,
} from 'modules/account/pages/components/Modals/AddCryptoFundsModal';
import { PaymentModalType } from '../types';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';
import { useAppSelector } from 'hooks/reduxToolkit';
import { getTopPosition } from 'utils/functions';

interface CardPaymentModalProps {
  isOpen: boolean;
  state: AddCryptoFundsStates;
  openedFrom?: string;
  header?: JSX.Element;
  onClose: () => void;
  onNoAvailableCards: () => void;
  onPaymentComplete: () => void;
  purchaseExpired: () => void;
  switchState?: (state: AddCryptoFundsStates) => void;
  onCardData: () => void;
  onUseCard?: () => void;
  onError: () => void;
}

const AddCryptoFunds: FC<CardPaymentModalProps> = ({
  isOpen,
  state,
  onClose,
  onPaymentComplete,
  onError,
  onNoAvailableCards,
  onCardData,
  switchState,
  purchaseExpired,
  openedFrom,
  header,
}) => {
  const dispatch = useAppDispatch();

  const { expiryTime } = useAppSelector((state) => state.modalSlice);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseExpired();
    } else if (isOpen) {
      dispatch(setModalType('AddCryptoFunds'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top={getTopPosition('10%')}
      mobileTop="2%"
      withSettings={false}
      withProceedingText={
        state === AddCryptoFundsStates.USA_STATE_NOT_AVAILABLE ||
        state === AddCryptoFundsStates.METAMASK_REQUIRED_INFO
          ? false
          : true
      }
      withFeeText={
        state === AddCryptoFundsStates.USA_STATE_NOT_AVAILABLE ||
        state === AddCryptoFundsStates.METAMASK_REQUIRED_INFO
          ? false
          : true
      }
      feeBottomPos="60px"
      content={
        <AddCryptoFundsModal
          openedFrom={openedFrom}
          type={PaymentModalType.IFrame}
          state={state}
          header={header}
          onCardData={() => {
            onCardData();
          }}
          switchState={(new_state) => {
            if (switchState) {
              switchState(new_state);
            }
          }}
          onNoAvailableCards={onNoAvailableCards}
          onChange={onClose}
          onPaymentComplete={onPaymentComplete}
          onError={onError}
        />
      }
    />
  );
};

export default memo(AddCryptoFunds);
