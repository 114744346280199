import Button from "common/components/Button/Button";
import styled from "styled-components";

export const HeadContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  width: 100%;
`;

export const BottomContainer = styled(HeadContainer)`
  padding: 0;
  align-items: center;
`;

export const EndButton = styled(Button)`
  padding: 0 50px;
`;

export const CustomSpacer = styled.div`
  height: 75px;

  @media (min-width: 768px) {
    height: 100px;
  }
`;

export const CardButton = styled(Button)`
  padding: 0;
`;
