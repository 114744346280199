import React, { FC } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import AccountSetupLink from './AccountSetupLink';
import { md } from 'utils/constants';
import { AccountSetupStepStates } from './types';

interface AccountSetupStepsProps {
  steps: {
    account_approved: AccountSetupStepStates;
    drop_created: AccountSetupStepStates;
    payout_setup: AccountSetupStepStates;
  };
}

const AccountSetupSteps: FC<AccountSetupStepsProps> = ({ steps }) => {
  return (
    <SetupContainer>
      <Title text="Account Setup Steps" fontWeight="bold" />
      <CloseIcon text="X" />
      <AccountSetupLink
        text="Account Approval"
        status={steps?.account_approved || AccountSetupStepStates.not_complete}
      />
      <AccountSetupLink
        text="Create Artist Profile"
        status={steps?.account_approved || AccountSetupStepStates.not_complete}
        link="/artist/settings"
      />
      <AccountSetupLink
        text="Upload First Drop"
        status={steps?.drop_created || AccountSetupStepStates.not_complete}
      />
      <AccountSetupLink
        text="Setup Payout"
        status={steps?.payout_setup || AccountSetupStepStates.not_complete}
        link="/artist/accounting"
      />
    </SetupContainer>
  );
};

const SetupContainer = styled.div`
  height: 207px;
  width: 100%;

  display: block;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;

  &:hover .text {
    color: ${theme.colors.yellow};
  }

  &:hover .button {
    background: ${theme.colors.yellow};
    color: ${theme.colors.black};
  }

  @media (min-width: ${md}px) {
    padding-left: 25px;
  }
`;

const Title = styled(Typography)`
  font-family: 'HKGrotesk-Black';
`;

const CloseIcon = styled(Typography)`
  position: absolute;
  right: 0px;
  top: 0;

  @media (min-width: ${md}px) {
    right: 5px;
  }
`;

export default AccountSetupSteps;
