import React, { FC, memo } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import { getTopPosition } from 'utils/functions';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  isPurchasePossible: boolean;
  purchaseLoading: boolean;
  initiatePurchase: () => void;
  deductionText: string;
  header: JSX.Element;
}

const ConfirmPurchase: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  header,
  initiatePurchase,
  purchaseLoading,
}) => {
  const viewport = useViewport();

  const getBottomTextPosition = () => {
    const width = viewport.width;
    const height = viewport.height;

    // alert(`Height: ${height}, Width: ${width}`);

    // iPhone 8
    if (width <= 375 && height <= 667) {
      return 140;
    } else if (width < 576) {
      return 25;
    } else if (width >= 1024 && width < 1200 && width > height) {
      return 70;
    } else {
      return 5;
    }
  };

  return (
    <SBModal
      isOpen={isOpen}
      withProceedingText={false}
      withFeeText={false}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top={getTopPosition('10%')}
      mobileTop="2%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text="confirmation"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Spacer height={70} />
            <FirstButton
              className="change-selection-button"
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              bgColor={theme.colors.black}
              labelColor={theme.colors.yellow}
              label={
                <TextContent
                  text="change selection"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={onClose}
            />
            <Spacer height={20} />
            <FirstButton
              className="confirm-purchase-button"
              isLoading={purchaseLoading}
              height={45}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text="confirm"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={initiatePurchase}
            />
          </div>

          <>
            <div
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: getBottomTextPosition(),
                paddingLeft: viewport.width < 576 ? '0px' : '20px',
                paddingRight: viewport.width < 576 ? '12px' : '50px',
              }}>
              <FeeText
                fontSize="fz10"
                fontColor={theme.colors.yellow}
                text={`* 10% sales and payment processing fee to be included`}
              />

              <SmallText
                fontSize="fz10"
                text="By proceeding, I agree to SongBits Terms of Service and acknowledge that this transaction is irreversible and nonrefundable."
              />
            </div>
          </>
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 357px;
  }
`;

const FeeText = styled(Typography)<{ fontSize: string }>`
  color: ${theme.colors.yellow};
  width: auto;
  text-align: center;
  font-size: 12px;
  font-family: 'HKGrotesk-Regular';
  line-height: 14px;
  margin-bottom: 20px;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const SmallText = styled(Typography)<{ fontSize: string }>`
  color: ${theme.colors.white};
  text-align: center;
  font-size: 10px;
  font-family: 'HKGrotesk-Regular';
  line-height: 14px;
`;

export default memo(ConfirmPurchase);
