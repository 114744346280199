import { useFormik } from 'formik';
import React, { useMemo, FC, useState, useEffect } from 'react';
import { useViewport } from 'use-viewport';
import { isBrowserSupported } from 'utils/browser-check';
import styled from 'styled-components';
import * as Yup from 'yup';

import Select from 'react-select';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';

import Card from 'common/icons/Card.icon';
import { USDistricts } from 'utils/us-districts';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { CryptoAddressSchema, PaymentModalType } from 'modules/payments/types';
import { menuStyleFullWidth } from 'common/styles/DropdownStylingFullWidth';
import restService from 'services/rest.service';
import Button from 'common/components/Button/Button';
import theme from 'theme/theme';
import MetamaskClient from 'services/web3.client';
import { useStoreActions } from 'store/store';
import { GlobalBannerProps } from 'typings/types';
import MetaMask from 'common/icons/MetaMask.icon';
import { setPersistModalState } from 'store-persist/reducers/modalSlice';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  amount: Yup.number().required('Amount is required.'),
});

type OptionType = {
  value: string;
  label: string;
};

interface ModalProps {
  onChange: () => void;
  onPaymentComplete: (id: string) => void;
  onNoAvailableCards?: () => void;
  onCardData?: () => void;
  onError: (error: Error) => void;
  header?: JSX.Element;
  switchState: (state: AddCryptoFundsStates) => void;
  openedFrom?: string;
  type: PaymentModalType;
  cost?: string;
  noClose?: boolean;
  state: AddCryptoFundsStates;
}

export enum AddCryptoFundsStates {
  INIT,
  PAYMENT,
  NON_SUPPORTED_BROWSER,
  BROWSER_INCOMPATIBLE,
  BROWSER_INCOMPATIBLE_INFO,
  METAMASK_REQUIRED,
  METAMASK_REQUIRED_INFO,
  CONNECT_METAMASK,
  USDC_TOO_LOW,
  ETH_TOO_LOW,
  PAYMENT_FAILED,
  PAYMENT_SUCCESSFUL,
  CONNECTION_SUCCESSFUL,
  CHECK_USA_STATE,
  USA_STATE_NOT_AVAILABLE,
}

const AddCryptoFundsModal: FC<ModalProps> = ({
  onChange,
  onPaymentComplete,
  onNoAvailableCards,
  onCardData,
  switchState,
  cost,
  type,
  state,
  openedFrom,
  header,
}) => {
  const viewport = useViewport();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [modalState, setModalState] = useState<AddCryptoFundsStates>(
    AddCryptoFundsStates.INIT,
  );

  useEffect(() => {
    setModalState(state);
  }, []);

  const [address, setAddress] = useState<CryptoAddressSchema>({
    address: '',
    nickname: '',
    currency: '',
    chain: '',
    status: '',
    addressTag: '',
  });
  const [walletBalanceETH, setwalletBalanceETH] = useState<string>('');
  const [walletBalanceUSDC, setwalletBalanceUSDC] = useState<string>('');

  walletBalanceETH;
  walletBalanceUSDC;

  type AmountError = {
    show: boolean;
    msg: string;
  };

  const [showAmountError, setShowAmountError] = useState<AmountError>({
    show: false,
    msg: '',
  });

  const initialValues = useMemo(
    () => ({
      amount: '',
    }),
    [],
  );

  useEffect(() => {
    if (cost) {
      setFieldValue('amount', cost);
    }
  }, []);

  const metamaskClient = new MetamaskClient(
    process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
  );

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  // Get available crypto addresses
  useEffect(() => {
    setTimeout(() => {
      if (modalState === AddCryptoFundsStates.PAYMENT) {
        metamaskClient.connect().then(() => {
          getAddresses()
            .then((res) => {
              const addresses: CryptoAddressSchema[] = [];
              res.forEach((address: any) => {
                if (address.status === 'incoming') {
                  addresses.push({
                    address: address.address,
                    nickname: address.nickname,
                    chain: address.chain,
                    currency: address.currency,
                    status: address.status,
                  });
                }
              });
              setAddress(addresses[0]);
            })
            .catch((error) => {
              setGlobalBanner({
                title: 'Failed to connect wallet: ',
                text: error.message,
              });
            });
        });
      }
    }, 3000);
  }, [modalState]);

  async function getAddresses() {
    const addresses = await restService.getCryptoAddresses().catch((error) => {
      setBanner({
        title: 'Failed to retrieve address data:',
        text: error.message,
      });
    });
    return addresses;
  }

  function setBanner(bannerProps: GlobalBannerProps) {
    if (type === PaymentModalType.IFrame) {
      (window as any).top.setGlobalBanner(bannerProps);
    } else {
      setGlobalBanner(bannerProps);
    }
  }

  async function submitHandler() {
    const transfer_amount = parseFloat(values.amount);
    metamaskClient.connect().then(() => {
      metamaskClient
        .getBalanceETH()
        .then(() => {
          const eth_bal = parseFloat(metamaskClient.balance_ETH);
          if (eth_bal === 0) {
            setModalState(AddCryptoFundsStates.ETH_TOO_LOW);
            return;
          }
          setwalletBalanceETH(metamaskClient.balance_ETH);
          metamaskClient
            .getBalanceUSDC()
            .then(() => {
              const usdc_bal = parseFloat(metamaskClient.balance_USDC);
              if (usdc_bal < transfer_amount) {
                setModalState(AddCryptoFundsStates.USDC_TOO_LOW);
              }
              setwalletBalanceUSDC(metamaskClient.balance_USDC);
              if (address) {
                metamaskClient
                  .transferUSDC(address.address, parseFloat(values.amount))
                  .then((transaction) => {
                    transaction;
                    handleSuccessPayment();
                  })
                  .catch((error) => {
                    setBanner({
                      title: 'Transfer failed: ',
                      text: error.message,
                    });
                    console.error('Transfer error', error);
                  });
              } else {
                console.error('No Incoming Address Available');
              }
            })
            .catch((error) => {
              setBanner({
                title: 'Failed to retrieve USDC balance: ',
                text: error.message,
              });
              setwalletBalanceUSDC('');
            });
        })
        .catch((error) => {
          setBanner({
            title: 'Failed to retrieve ETH balance: ',
            text: error.message,
          });
          console.error(error);
          setwalletBalanceETH('');
        });
    });
  }

  function handleClose() {
    switch (modalState) {
      case AddCryptoFundsStates.BROWSER_INCOMPATIBLE_INFO:
        setModalState(AddCryptoFundsStates.BROWSER_INCOMPATIBLE);
        return;
      case AddCryptoFundsStates.METAMASK_REQUIRED_INFO:
        switchState(AddCryptoFundsStates.METAMASK_REQUIRED);
        setModalState(AddCryptoFundsStates.METAMASK_REQUIRED);
        return;
      default:
        onChange();
        return;
    }
  }

  function switchCardPayment() {
    restService
      .getCardsList()
      .then((res) => {
        const numCards = res.length;

        if (numCards > 0) {
          if (onCardData) {
            onCardData();
          }
        } else {
          // Pop add card modal

          if (onNoAvailableCards) {
            onNoAvailableCards();
          }
        }
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve cards:',
          text: error.message,
        });
      });
  }

  function handleSuccessPayment() {
    onPaymentComplete('abc');
  }

  function fund_account_content() {
    return (
      <>
        <BoldText
          text="Fund your account via crypto"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={30} />

        <Typography
          text={`Network: Ethereum Main Network`}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
        <Spacer height={10} />
        <Typography
          text={`Currency: US Dollars (USDC)`}
          fontSize="fz18"
          fontColor={theme.colors.white}
          fontWeight="light"
        />
      </>
    );
  }

  function crypto_payment_form() {
    return (
      <>
        {fund_account_content()}
        <InputContainer>
          <AmountFieldText>
            <Typography
              text="USDC $ |"
              fontSize="fz24"
              fontColor={theme.colors.white}
            />
          </AmountFieldText>
          <InputWithPadding
            height={70}
            type="text"
            placeholder="enter amount"
            value={values.amount}
            tabIndex={0}
            withBottomLine
            onChange={handleChange('amount')}
          />
        </InputContainer>
        <Spacer height={10} />
        {showAmountError.show && (
          <Typography
            text={showAmountError.msg}
            fontColor={theme.colors.yellow}
            fontSize="fz12"
            fontWeight="light"
          />
        )}
        <Spacer height={40} />
        <ButtonContainer>
          <WhiteButton
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            labelColor={theme.colors.black}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="Add Funds"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              </div>
            }
            onClick={() => {
              if (values.amount.length === 0) {
                setShowAmountError({
                  show: true,
                  msg: 'Amount is required.',
                });
              } else if (!Number(values.amount)) {
                setShowAmountError({
                  show: true,
                  msg: 'Amount must be a number.',
                });
              } else {
                setShowAmountError({ show: false, msg: '' });
                setModalState(AddCryptoFundsStates.CONNECTION_SUCCESSFUL);
              }
            }}
          />
        </ButtonContainer>
      </>
    );
  }

  function metamask_header() {
    return (
      <>
        <BoldText
          text="Fund your account via crypto"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={20} />

        <MetamaskContainer>
          <MetaMaskWrapper>
            <MetaMask width={30} />
          </MetaMaskWrapper>
          <Typography
            text="MetaMask Required"
            fontColor={theme.colors.white}
            fontWeight="bold"
            fontSize="fz18"
          />
        </MetamaskContainer>
      </>
    );
  }

  function non_supported_browser_info() {
    return (
      <>
        {metamask_header()}

        <InfoParagraph>
          <SpacedTypography
            text="Your browser is not compatible with the MetaMask crypto wallet extension."
            fontSize="fz18"
            fontWeight="light"
          />
          <BrowserInfo>
            <Typography
              text={
                <>
                  <span
                    style={{
                      lineHeight: '24px',
                      fontFamily: 'HKGrotesk-Light',
                    }}>
                    Please use
                    <UnderlineText
                      target={'_blank'}
                      href="https://www.google.com/chrome/">
                      Chrome
                    </UnderlineText>
                    or
                    <UnderlineText target={'_blank'} href="https://brave.com/">
                      Brave
                    </UnderlineText>
                    or visit Songbits.com on a mobile with the MetaMask app
                    installed.
                  </span>
                </>
              }
            />
          </BrowserInfo>
        </InfoParagraph>
      </>
    );
  }

  function non_supported_browser() {
    return (
      <>
        {/*<BoldText
          text="Fund your account via crypto"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

    <Spacer height={50} />*/}

        {header ? (
          header
        ) : (
          <>
            <BoldText
              text="Fund your account via crypto"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
              fontSize="fz24"
            />
          </>
        )}

        {header ? (
          <>
            <Spacer height={60} />
          </>
        ) : (
          <>
            <Spacer height={50} />
          </>
        )}

        <WhiteButton
          label={
            <Typography
              text="Non-Supported Browser"
              fontWeight="bold"
              fontSize="fz16"
              fontColor={theme.colors.yellow}
            />
          }
          onClick={() => {}}
          labelColor={theme.colors.yellow}
          borderColor={theme.colors.yellow}
          borderRadius={50}
          height={45}
        />
        <Spacer height={20} />
        <WhiteButton
          label={
            <Typography
              text="use card payment"
              fontWeight="bold"
              fontSize="fz16"
              fontColor={theme.colors.black}
            />
          }
          onClick={() => {
            switchCardPayment();
          }}
          labelColor={theme.colors.yellow}
          borderColor={theme.colors.yellow}
          bgColor={theme.colors.yellow}
          borderRadius={50}
          height={45}
        />

        <Spacer height={10} />
        <WhiteButton
          label={
            <Typography
              text="View Support Article"
              fontWeight="light"
              fontSize="fz18"
            />
          }
          onClick={() => {
            setModalState(AddCryptoFundsStates.BROWSER_INCOMPATIBLE_INFO);
          }}
          labelColor={theme.colors.white}
          height={45}
        />
      </>
    );
  }

  const metamask_required = () => {
    return (
      <>
        <Spacer height={10} />
        <BoldText
          text="MetaMask required!"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <MetamaskRequiredContainer>
          <MetaMaskRequiredWrapper>
            <MetaMask width={30} />
          </MetaMaskRequiredWrapper>
          <MetaMaskButton
            label={
              <Typography
                text="How to install and use MetaMask"
                fontColor={theme.colors.white}
                fontWeight="regular"
                fontSize="fz18"
              />
            }
            onClick={() => {
              switchState(AddCryptoFundsStates.METAMASK_REQUIRED_INFO);
              setModalState(AddCryptoFundsStates.METAMASK_REQUIRED_INFO);
            }}
            labelColor={theme.colors.white}
            height={45}
          />
        </MetamaskRequiredContainer>

        <Spacer height={100} />
        <WhiteButton
          label={
            <Typography
              text="Connect to MetaMask"
              fontWeight="light"
              fontSize="fz16"
              fontColor={theme.colors.white}
            />
          }
          onClick={() => {
            // window.location.reload();
            metamaskClient.connect().then(() => {
              setModalState(AddCryptoFundsStates.PAYMENT);
            });
          }}
          // bgColor={theme.colors.white}
          borderColor={theme.colors.white}
          borderRadius={50}
          height={45}
        />
      </>
    );
  };

  const metamask_required_info = () => {
    return (
      <>
        {metamask_header()}

        <InfoParagraph>
          <SpacedTypography
            text="SongBits uses MetaMask Wallet."
            fontSize="fz18"
            fontWeight="light"
          />
          <SpacedTypography
            text="Please install the MetaMask extension."
            fontSize="fz18"
            fontWeight="light"
          />
          <SpacedTypography
            text="Visit the extension store on your browser or search metamask browser extension."
            fontSize="fz18"
            fontWeight="light"
          />

          <WhiteButton
            label={
              <Typography
                text="MetaMask Support"
                fontWeight="light"
                fontColor={theme.colors.white}
                fontSize="fz16"
              />
            }
            onClick={() => {
              window.open('https://metamask.io/download/', '_blank');
            }}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
          <Spacer height={20} />
        </InfoParagraph>
      </>
    );
  };

  function connect_to_metamask() {
    return (
      <>
        <BoldText
          text="Fund your account via crypto"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={180} />
        <WhiteButton
          label={
            <Typography
              text="Connect to MetaMask"
              fontColor={theme.colors.black}
              fontSize="fz16"
              fontWeight="bold"
            />
          }
          onClick={() => {
            metamaskClient.connect().then(() => {
              setModalState(AddCryptoFundsStates.PAYMENT);
            });
          }}
          height={45}
          bgColor={theme.colors.white}
          labelColor={theme.colors.black}
          borderColor={theme.colors.white}
          borderRadius={50}
        />
      </>
    );
  }

  function ETH_too_low() {
    return (
      <div>
        {metamask_header()}
        <Spacer height={80} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="ETH balance too low"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          {/*<WhiteButton
            label="Contact Support"
            onClick={() => {}}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
        />*/}
          <WhiteButton
            label="Try Again"
            onClick={() => {
              setModalState(AddCryptoFundsStates.PAYMENT);
            }}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            borderColor={theme.colors.yellow}
            borderRadius={50}
            height={45}
          />
        </div>
      </div>
    );
  }

  function USDC_too_low() {
    return (
      <div>
        {metamask_header()}
        <Spacer height={80} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="USDC balance too low"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          {/*<WhiteButton
            label="Contact Support"
            onClick={() => {}}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
        />*/}
          <WhiteButton
            label="Try Again"
            onClick={() => {
              setModalState(AddCryptoFundsStates.PAYMENT);
            }}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            borderColor={theme.colors.yellow}
            borderRadius={50}
            height={45}
          />
        </div>
      </div>
    );
  }

  function payment_failed() {
    return (
      <div>
        {metamask_header()}
        <Spacer height={80} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Payment Failed"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          {/*<WhiteButton
            label="Contact Support"
            onClick={() => {}}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
        />*/}
          <WhiteButton
            label="Try Again"
            onClick={() => {
              setModalState(AddCryptoFundsStates.PAYMENT);
            }}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            borderColor={theme.colors.yellow}
            borderRadius={50}
            height={45}
          />
        </div>
      </div>
    );
  }

  function payment_successful() {
    return (
      <div>
        <BoldText
          text="Fund your account via crypto"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Typography
            text="Payment Successful"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          <WhiteButton
            label="Close"
            onClick={onChange}
            labelColor={theme.colors.white}
            borderColor={theme.colors.white}
            borderRadius={50}
            height={45}
          />
        </div>
      </div>
    );
  }

  function connection_successful() {
    return (
      <div>
        {fund_account_content()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Spacer height={30} />
          <Typography
            text="Connection Successful"
            fontWeight="light"
            fontColor={theme.colors.white}
          />
          <WhiteButton
            label={
              <Typography
                text="Confirm Payment"
                fontColor={theme.colors.black}
                fontSize="fz16"
                fontWeight="bold"
              />
            }
            onClick={submitHandler}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            borderColor={theme.colors.yellow}
            borderRadius={50}
            height={45}
          />
        </div>
      </div>
    );
  }

  const VALIDATION_COUNTY_SCHEMA = Yup.object().shape({
    county: Yup.string().required('County is required'),
  });

  const initialCountyValues = useMemo(
    () => ({
      county: '',
      county_name: '',
    }),
    [],
  );

  const {
    values: valuesCounty,
    setFieldValue: setCountyFieldValue,
    errors: errorsCounty,
    touched: touchedCounty,
  } = useFormik({
    initialValues: initialCountyValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_COUNTY_SCHEMA,
    onSubmit: stateSubmitHandler,
  });

  function stateSubmitHandler() {
    if (
      valuesCounty.county === 'NY' ||
      valuesCounty.county === 'HI' ||
      valuesCounty.county === 'MN' ||
      valuesCounty.county === 'NY'
    ) {
      switchState(AddCryptoFundsStates.USA_STATE_NOT_AVAILABLE);
      setModalState(AddCryptoFundsStates.USA_STATE_NOT_AVAILABLE);
    } else {
      restService
        .getCryptoAddresses()
        .then((res) => {
          // Check if incoming address exists
          let incomingAddress: any;
          res.forEach((address: any) => {
            if (address.status === 'incoming') {
              incomingAddress = address;
            }
          });

          if (incomingAddress) {
            if (incomingAddress.chain === 'ETH') {
              if (!(window as any).ethereum) {
                setModalState(AddCryptoFundsStates.METAMASK_REQUIRED);
              } else if (!isBrowserSupported()) {
                setModalState(AddCryptoFundsStates.NON_SUPPORTED_BROWSER);
              } else {
                if ((window.ethereum as any)['isBraveWallet']) {
                  setModalState(AddCryptoFundsStates.METAMASK_REQUIRED);
                }

                metamaskClient.connect(incomingAddress.nickname).then(() => {
                  if (openedFrom === 'purchase') {
                    setModalState(AddCryptoFundsStates.CONNECTION_SUCCESSFUL);
                  } else {
                    setModalState(AddCryptoFundsStates.INIT);
                  }
                });
              }
            } else {
              // Switch chain
              setModalState(AddCryptoFundsStates.CONNECT_METAMASK);
              //next({ state: QuickFlowStates.ConnectWallet });
            }
          } else {
            setModalState(AddCryptoFundsStates.CONNECT_METAMASK);
          }
        })
        .catch((error) => {
          setGlobalBanner({
            title: 'Failed to retrieve addresses:',
            text: error.message,
          });
        });
    }
  }

  function check_usa_state() {
    return (
      <div>
        <Spacer height={30} />
        <ModalText
          text="We are required to check which state US residents reside within when you choose to pay with USDC crypto."
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.white}
        />
        <Spacer height={20} />
        <ModalText
          text="Please select your state below: "
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.white}
        />
        <DropdownContainer>
          <DropDown
            options={USDistricts}
            placeholder="Type State"
            styles={menuStyleFullWidth}
            onChange={(option) => {
              setCountyFieldValue('county', (option as OptionType).value);
              setCountyFieldValue('county_name', (option as OptionType).label);
              console.table(values);
            }}
          />
        </DropdownContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
          }}>
          <Typography
            text={
              (!valuesCounty.county && touchedCounty.county) ||
              (errorsCounty.county && touchedCounty.county)
                ? 'State is required'
                : ''
            }
            fontSize="fz10"
            fontColor={theme.colors.yellow}
          />
        </div>

        <Spacer height={20} />
        <WhiteSubmitButton
          label={<Typography text="submit" fontWeight="light" fontSize="fz16" />}
          onClick={() => {
            stateSubmitHandler();
          }}
          labelColor={theme.colors.white}
          borderColor={theme.colors.white}
          borderRadius={50}
          height={45}
          width={172}
        />
      </div>
    );
  }

  function usa_crypto_unavailable() {
    return (
      <div>
        <Spacer height={30} />
        <BoldText
          text="Crypto purchase not available!"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={3} />
        <TextContent
          text={
            <TextContentInnerContainer>
              Since you currently reside in{' '}
              <TextContentBold>{valuesCounty.county_name}</TextContentBold>{' '}
              state - we are unable to process purchases for residents of that
              US state.
            </TextContentInnerContainer>
          }
          fontSize="fz14"
          fontColor={theme.colors.white}
        />

        <Spacer height={30} />
        <ModalText
          text="If this was an error, please contact support or use card payment below instead."
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.white}
        />

        <PaymentOptionsContainer>
          <PaymentOption
            onClick={() => {
              switchCardPayment();
            }}>
            <IconContainer>
              <Card />
            </IconContainer>
            <TextContent
              fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
              fontColor={theme.colors.white}
              text="Buy using credit/debit card"
            />
          </PaymentOption>
        </PaymentOptionsContainer>
      </div>
    );
  }

  function getModalContent() {
    switch (modalState) {
      case AddCryptoFundsStates.PAYMENT:
        return crypto_payment_form();
      case AddCryptoFundsStates.CHECK_USA_STATE:
        return check_usa_state();
      case AddCryptoFundsStates.USA_STATE_NOT_AVAILABLE:
        state = AddCryptoFundsStates.USA_STATE_NOT_AVAILABLE;
        return usa_crypto_unavailable();
      case AddCryptoFundsStates.BROWSER_INCOMPATIBLE:
        (window as any).gtag("event", "purchase_crypto_not_supported", {});
        return non_supported_browser();
      case AddCryptoFundsStates.BROWSER_INCOMPATIBLE_INFO:
        return non_supported_browser_info();
      case AddCryptoFundsStates.METAMASK_REQUIRED:
        setPersistModalState('MetaMaskRequired');
        return metamask_required();
      case AddCryptoFundsStates.METAMASK_REQUIRED_INFO:
        return metamask_required_info();
      case AddCryptoFundsStates.CONNECT_METAMASK:
        (window as any).gtag("event", "purchase_crypto_connect", {});
        return connect_to_metamask();
      case AddCryptoFundsStates.CONNECTION_SUCCESSFUL:
        (window as any).gtag("event", "purchase_crypto_connected", {});
        return connection_successful();
      case AddCryptoFundsStates.USDC_TOO_LOW:
        (window as any).gtag("event", "purchase_crypto_usdc_error", {});
        return USDC_too_low();
      case AddCryptoFundsStates.ETH_TOO_LOW:
        (window as any).gtag("event", "purchase_crypto_eth_error", {});
        return ETH_too_low();
      case AddCryptoFundsStates.PAYMENT_FAILED:
        return payment_failed();
      case AddCryptoFundsStates.PAYMENT_SUCCESSFUL:
        return payment_successful();
      default:
        return crypto_payment_form();
    }
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text={openedFrom === 'purchase' ? 'crypto payment' : 'add funds'}
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      {getModalContent()}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const InputWithIcon = styled(TextInput)`
  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const ModalText = styled(Typography)`
  line-height: 24px;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const AmountFieldText = styled.div`
  position: absolute;
  left: 0px;
  top: 24px;
`;

const InputWithPadding = styled(InputWithIcon)`
  & > input {
    padding-left: 110px;
    padding-top: 2px;
    font-size: 24px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 357px;
`;

const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  width: 90%;
  border-width: 1px;
  font-size: 16px;

  @media (min-width: 768px) {
    margin: initial;
    width: 357px;
  }
`;

const MetaMaskButton = styled(Button)`
  margin: 0 auto;
  text-align: left;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin: initial;
    width: auto;
  }
`;

const WhiteSubmitButton = styled(Button)`
  padding: 0 30px;
  width: 172px;
  border-width: 1px;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const MetamaskContainer = styled.div`
  display: flex;
  gap: 25px;
  width: inherit;
  margin-left: 30px;
  margin-top: 40px;
`;

const MetamaskRequiredContainer = styled.div`
  display: flex;
  gap: 15px;
  width: inherit;
  margin-left: 30px;
  margin-top: 30px;
`;

const MetaMaskRequiredWrapper = styled.div`
  transform: scale(2.5);
  margin-top: 15px;
`;
const SpacedTypography = styled(Typography)`
  line-height: 24px;
`;

const MetaMaskWrapper = styled.div`
  transform: scale(2.5);
`;

const InfoParagraph = styled.div`
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`;

const UnderlineText = styled.a`
  text-decoration: underline;
  margin-left: 5px;
  margin-right: 5px;
  font-family: HKGrotesk-Light;
`;

const BrowserInfo = styled.div`
  display: flex;
  gap: 5px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

export const DropDown = styled(Select)`
  width: 100%;
`;

export const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.023em;
  font-size: 18px;
  font-family: 'HKGrotesk-Light';
  font-weight: 300;
`;

export const TextContentInnerContainer = styled.div``;

export const TextContentBold = styled.span`
  font-weight: bold;
  font-family: 'HKGrotesk-Black';
`;

const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 30px;
`;

const PaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  text-align: center;
  max-width: 130px;
  line-height: 30px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 63px;
  display: flex;
  justify-content: center;
  transform: scale(0.7);

  @media (min-width: 576px) {
    transform: none;
  }
`;

export default AddCryptoFundsModal;
