import React from 'react';

import styled from 'styled-components';
import ConnectWalletButton from 'common/components/WalletButton/ConnectWalletButton';

//import PayoutConnectCryptoFlow from 'modules/payments/PaymentModals/PayoutConnectCryptoFlow';

const ConnectWalletOutgoingDisabled = () => {

  return (
    <>
      <USDCCryptoWrapper>
        <ContentContainer>
          <ConnectWalletButton onClickHandler={() => {}}></ConnectWalletButton>
        </ContentContainer>

      </USDCCryptoWrapper>
    </>
  );
};

const ContentContainer = styled.div<{ first?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0.5;
`;

const USDCCryptoWrapper = styled.div`
  margin-bottom: 10px;
`;

export default ConnectWalletOutgoingDisabled;
