import React, { FC, memo } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

interface PaymentModalProps {
  isOpen: boolean;
  country: string;
  onClose: () => void;
}

const RestrictedCountry: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  country,
}) => {
  const viewport = useViewport();
  country
  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      height="518px"
      withSettings={false}
      withProceedingText={false}
      content={
        <>
          <CloseButton onClick={onClose}>X</CloseButton>
          <BoldText
            text="sorry"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          <Spacer height={30} />
          <BoldText
            text="Unavaliable!"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            fontSize="fz24"
          />

          <Spacer height={10} />
          <TextContent
            text={
              <TextContentInnerContainer>
                Whoops! We dont support your region yet, but were working on it. Check back soon!
              </TextContentInnerContainer>
            }
            fontSize="fz18"
            fontColor={theme.colors.white}
          />
        </>
      }
    />
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.023em;
  font-size: 18px;
  font-family: 'HKGrotesk-Light';
  font-weight: 300;
`;

export const TextContentInnerContainer = styled.div``;

export const TextContentBold = styled.span`
  font-weight: bold;
  font-family: 'HKGrotesk-Black';
`;

export default memo(RestrictedCountry);
