import React, { useEffect, useState } from 'react';
import store, { useStoreActions } from 'store/store';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import * as Yup from 'yup';

import Typography from 'common/components/Typography/Typography';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Eye from 'common/icons/Eye.icon';
import EyeClose from 'common/icons/EyeClose.icon';
import Lock from 'common/icons/Lock.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import { SPECIAL_CHARACTER_VALIDATION_PATTERN } from 'utils/validators';

import Slider1 from '../assets/forgot-password.jpg';

import {
  FirstButton,
  Form,
  HeadContainer,
  HeadContent,
  HeadingSection,
  HiddenContainer,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  PasswordVisibility,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContentNew,
} from './styled/ResetPassword.styled';

import { RESET_PASSWORD } from '../graphql/Mutations.graphql';
import { RESET_PASSWORD_MUTATION, RESET_PASSWORD_VARIABLES } from '../types';
import { sm, xs } from 'utils/constants';
import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must contain 6 characters, including 1 special character')
    .matches(
      SPECIAL_CHARACTER_VALIDATION_PATTERN,
      'Password must contain 6 characters, including 1 special character',
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords don’t match')
    .required('Password confirmation is required!'),
});

const ResetPassword = () => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    const user = searchParams.get('username');
    if (user) {
      setUsername(user);
    }
  }, [searchParams]);

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );
  const setIsHeaderTransparent = useStoreActions(
    (state) => state.style.setHeaderTransparent,
  );

  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );

  useEffect(() => {
    setIsHeaderTransparent(true);
    setMainFooterHidden(true);

    return () => {
      setMainFooterHidden(false);
    };
  }, []);

  const navigate = useNavigate();
  const viewport = useViewport();
  const { shutdown } = useIntercom();

  const logout = useStoreActions((state) => state.authentication.setLogout);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  async function submitHandler() {
    try {
      const response = await resetPassword();

      if (!response.errors) {
        if (response.data) {
          navigate('/login');
          setGlobalBanner({
            title: 'Login required.',
            text: 'Please login below.',
          });
        }
      }
    } catch (e: any) {
      setGlobalBanner({
        title: 'Error',
        text: e.message + ', Please use forgot password again.',
      });
    }
  }

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [resetPassword] = useMutation<
    RESET_PASSWORD_MUTATION,
    RESET_PASSWORD_VARIABLES
  >(RESET_PASSWORD, {
    variables: {
      tokenValue: token ? token : '',
      password: values.password,
    },
  });

  useEffect(() => {
    store.persist.clear().then(() => {
      // Shutdown Intercom
      shutdown();
      logout();
    });
  }, []);

  const passwordVisibilityHandler = useCallback(() => {
    setShowPassword(!showPassword);
    setShowConfirmPassword(!showPassword);
  }, [showPassword]);

  const confirmPasswordVisibilityHandler = useCallback(() => {
    setShowConfirmPassword(!showConfirmPassword);
    setShowPassword(!showConfirmPassword);
  }, [showConfirmPassword]);

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer pageTitle='Reset Password | SongBits'>
      <ImageContainer>
        <Image src={Slider1} />
        <ImageGradient banner={Slider1} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <div style={{ display: 'flex' }}>
              <HeadContent
                text="let’s reset"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentNew
            text="Choose a New Password"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />

          <Form>
            <HiddenContainer>
              <InputWithIcon
                value={username}
                autoComplete="username"
                onChange={() => {}}
              />
            </HiddenContainer>

            <InputContainer>
              <Icon>
                <Lock />
              </Icon>
              <InputWithIcon
                value={values.password}
                height={viewport.width < sm ? 63 : 70}
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                onChange={handleChange('password')}
                label="Choose New Password"
                withBottomLine
                error={
                  Boolean(errors.password && touched.password)
                    ? errors.password
                    : undefined
                }
              />
              <PasswordVisibility onClick={passwordVisibilityHandler}>
                {showPassword ? <EyeClose /> : <Eye />}
              </PasswordVisibility>
            </InputContainer>

            <InputContainer>
              <Icon>
                <Lock />
              </Icon>
              <InputWithIcon
                value={values.confirmPassword}
                height={viewport.width < sm ? 63 : 70}
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={handleChange('confirmPassword')}
                inputName="new-password2"
                autoComplete="new-password"
                label="Repeat Password"
                withBottomLine
                error={
                  Boolean(errors.confirmPassword && touched.confirmPassword)
                    ? errors.confirmPassword
                    : undefined
                }
              />
              <PasswordVisibility onClick={confirmPasswordVisibilityHandler}>
                {showConfirmPassword ? <EyeClose /> : <Eye />}
              </PasswordVisibility>
            </InputContainer>

            <FirstButton
              height={45}
              width={172}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <Typography text="change" fontSize="fz16" fontWeight="bold" />
                  &nbsp;
                  <Typography
                    text="it"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={handleSubmit}
            />
          </Form>
        </HeadingSection>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(ResetPassword);
