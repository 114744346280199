import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import AddNewSongIcon from 'common/icons/AddNewSong.icon';
import theme from 'theme/theme';

import { MyCollectionDataType } from 'modules/account/types';

type DropsProps = { drops: MyCollectionDataType[] };

const Drops = ({ drops }: DropsProps) => {
  const navigate = useNavigate();

  function parseDate(date: string) {
    const d = new Date(date).toDateString().split(' ');
    return `${d[2]} ${d[1]} ${d[3]}`;
  }

  return (
    <DropsContainer>
      <DropTypeContainer>
        <Typography
          text="My Collection"
          fontSize={'fz24'}
          fontWeight="bold"
          fontColor="#FFD600"
        />
        <Spacer height={10} />

        {drops.map((d, i) => (
          <DropContainer key={`Key for DropButton is - ${d.name} - ${i}`}>
            <Drop>
              <AddNewSongIcon width={12} height={12} />
              <WidthSpacer width={10} />
              <RowButton
                onClick={() => {
                  navigate(d.link);
                }}
                label={<Cover src={d.cover} />}
              />
              <WidthSpacer width={10} />
              <DropDetailsContainer>
                <SongNameLink
                  label={
                    <Typography
                      text={d.name}
                      fontSize="fz18"
                      fontWeight="regular500"
                      className="songName"
                      fontColor={theme.colors.white}
                    />
                  }
                  onClick={() => {
                    navigate(d.link);
                  }}
                />
                <Spacer height={5} />
                <DropDetails>
                  <RowButton
                    onClick={() => {
                      navigate(d.link);
                    }}
                    label={
                      <SongInfoContainer>
                        <Typography
                          text={parseDate(d.date)}
                          fontSize="fz14"
                          fontWeight="light"
                          fontColor={theme.colors.white}
                        />
                        <Pipe>|</Pipe>
                        <Typography
                          fontSize="fz14"
                          fontWeight="light"
                          fontColor={theme.colors.white}
                          text={`${d.noBits} bits`}
                        />
                      </SongInfoContainer>
                    }
                  />

                </DropDetails>
              </DropDetailsContainer>
            </Drop>
          </DropContainer>
        ))}
      </DropTypeContainer>
    </DropsContainer>
  );
};

const DropsContainer = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    width: 350px;
  }
`;

const Drop = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Pipe = styled.p`
  color: ${theme.colors.white};
  padding: 0 10px;
`;

const DropDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 992px) {
    width: 350px;
  }
`;

const DropDetails = styled.div`
  font-family: 'HKGrotesk-Light';
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DropContainer = styled.div`
  width: 405px;
  padding: 0;
  cursor: pointer;

  &:hover {
    background: transparent;
  }

  &:hover .songName {
    color: ${theme.colors.yellow};
  }

  @media (min-width: 576px) {
    width: 450px;
  }
`;

const SongNameLink = styled(Button)`
  padding: 0;
  &:hover {
    background: transparent;
  }

  &:hover .songName {
    color: ${theme.colors.yellow};
  }
`;

const SongInfoContainer = styled.div`
  display: flex;
  font-family: 'HKGrotesk-Light';
  letter-spacing: -0.3px;
`;

const Cover = styled.img`
  width: 40px;
  height: 40px;
`;

const DropTypeContainer = styled.div`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -1.2px;
  width: 100%;
  height: min-content;
`;

const WidthSpacer = styled.div<{ width: number }>`
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
`;

const RowButton = styled(Button)`
  padding: 0;

  &:hover {
    background: transparent;
  }
`;

export default Drops;
