import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import StepButton, { StepButtonProps } from '../StepButton/StepButton';
import theme from 'theme/theme';

interface StepProps {
  label: string;
  subLabel?: string;
  buttonConfig: StepButtonProps;
  linkConfig?: {
    link: string;
    dowloadFileName: string;
  };
}

const Step: FC<StepProps> = ({ label, subLabel, buttonConfig, linkConfig }) => {
  const { onClick: onButtonClick, ...restButtonConfig } = buttonConfig;

  const [showSubLabel, setShowSubLabel] = useState<boolean>(false);

  useEffect(() => {
    if (!showSubLabel) return;

    const timer = setTimeout(() => {
      setShowSubLabel(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showSubLabel]);

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setShowSubLabel(true);
    onButtonClick && onButtonClick(e);
  };

  return (
    <StepContainer>
      <StepLabel
        text={label}
        fontSize="fz16"
        fontWeight="bold"
        letterSpacing="-0.01em"
      />
      {linkConfig ? (
        <LinkWrapper
          href={linkConfig.link}
          download={linkConfig.dowloadFileName}
          target="_blank"
          rel="noreferrer">
          <StepButton {...restButtonConfig} onClick={handleButtonClick} />
        </LinkWrapper>
      ) : (
        <StepButton {...restButtonConfig} onClick={handleButtonClick} />
      )}
      {subLabel && showSubLabel && (
        <StepLabel
          text={subLabel}
          fontSize="fz16"
          fontWeight="light"
          fontColor={theme.colors.yellow}
        />
      )}
    </StepContainer>
  );
};

const StepContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-left: 20px;
`;

const StepLabel = styled(Typography)`
  margin-left: 20px;
`;

const LinkWrapper = styled.a`
  text-decoration: none;
`;

export default Step;
