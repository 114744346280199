import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStoreState } from 'store/store';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 50);

    return () => {
      setReady(false);
    };
  }, []);

  return !ready ? (
    <></>
  ) : isAuth ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location, banner: true }} />
  );
};
