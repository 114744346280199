import React, { FC } from 'react';
import styled from 'styled-components';

import QRCode from 'react-qr-code';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

interface ModalProps {
  authenticator_uri: string;
  onNext: () => void;
  onManualEntry: () => any;
  onHelp: () => any;
}

const TwoFaSetup: FC<ModalProps> = ({ authenticator_uri, onNext, onHelp, onManualEntry }) => {

  return (
    <CustomComponentContainer>
      <Spacer height={20} />

      <Typography
        text="Open the authenticator app on your mobile device."
        lineHeight="24px"
      />

      <div onClick={onHelp}>
        <LinkText text="What is an Authenticator app?" />
      </div>

      <Spacer height={20} />
      <QrWrapper>
        {authenticator_uri ? (
          <div
            style={{
              background: 'white',
              padding: '5px',
              width: '110px',
              height: '110px',
            }}>
            <QRCode size={100} value={authenticator_uri} />
          </div>
        ) : (
          <></>
        )}

        <QrHelp>
          <HelpList>
            <ListItem>
              <Typography text="Tap the '+' button" lineHeight="24px" />
            </ListItem>
            <ListItem>
              <Typography text="Select 'Scan QR Code'" lineHeight="24px" />
            </ListItem>
          </HelpList>

          <Spacer height={20} />

          <div onClick={onManualEntry}>
            <LinkText text="Wont Scan?" />
          </div>
        </QrHelp>
      </QrWrapper>

      <Spacer height={25} />
      <FirstButton
        height={45}
        width={226}
        borderRadius={50}
        borderColor={theme.colors.white}
        label={
          <div style={{ display: 'flex' }}>
            <ContentButton text="request" fontSize="fz16" fontWeight="bold" />
            &nbsp;
            <ContentButton
              text="authorization"
              fontSize="fz16"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
            />
          </div>
        }
        onClick={onNext}
      />
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const LinkText = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  color: ${theme.colors.yellow};

  cursor: pointer;
`;

const QrWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QrHelp = styled.div`
  display: flex;
  flex-direction: column;

  color: ${theme.colors.white};

  padding-left: 40px;
`;

const HelpList = styled.ul`
  padding-left: 20px;
`;

const ListItem = styled.li`
  list-style-type: disc;
`;

const FirstButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

export default TwoFaSetup;
