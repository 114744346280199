import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as R from 'ramda';
import * as Yup from 'yup';

import theme from 'theme/theme';
import { getUserIdFromJWT } from 'utils/functions';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { useViewport } from 'use-viewport';
import { useStoreActions } from 'store/store';
import geoService from 'services/geo.service';
import restService from 'services/rest.service';

import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';

import PageLayout from 'common/components/PageLayout/PageLayout';
import SBModal from 'common/components/Modal/SBModal';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
import Caption from 'common/components/Caption/Caption';
import ImagePicker from 'common/components/ImagePicker/ImagePicker';
import Media from 'common/components/Media/Media';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';

import AddIncomingCryptoModal from './components/Modals/AddIncomingCryptoModal';
import AddOutgoingCryptoModal from './components/Modals/AddOutgoingCryptoModal';
import CryptoIncomingModal from './components/Modals/CryptoIncomingModal';
import CardPaymentResultModal from './components/Modals/CardPaymentResultModal';
import CardPaymentModal from './components/Modals/CardPaymentModal';
import AddCardModal from './components/Modals/AddCardModal';
import TwoFA from './components/2FA/TwoFA';

import Toggle from 'modules/artist/pages/components/Toggle/Toggle';
import { CHANGE_EMAIL_CONFIRM } from 'modules/auth/graphql/Mutations.graphql';
import ChangePassword from 'modules/account/pages/components/ChangePassword/ChangePassword';
import ChangeEmail from 'modules/account/pages/components/ChangeEmail/ChangeEmail';
import ChangeUsername from 'modules/account/pages/components/ChangeUsername/ChangeUsername';
//import WalletOverview from 'modules/account/pages/components/Wallet/WalletOverview';

import './styles/AddressAutoComplete.css';

import { UPDATE_USER_BY_ID } from '../graphql/Mutations.graphql';

import {
  SocialMediaTypes,
  UPDATE_USER_BY_ID_MUTATION,
  UPDATE_USER_BY_ID_VARIABLES,
} from '../types';

import { PaymentModalType } from '../../payments/types';
import { countryCodeById } from 'utils/country-ids';
import {
  Icon,
  InputContainer,
} from 'modules/auth/pages/styled/CreateAccount.styled';
import { DropdownContainer } from 'modules/auth/pages/styled/CreateAccount.styled';
import { DropDown } from 'modules/auth/pages/styled/CreateAccount.styled';
import { menuStyleFullWidthSignUp } from 'common/styles/DropdownStylingFullWidthSignUp';
import { CommonOptionType } from 'common/common.types';
import World from 'common/icons/World.icon';

type AccountSettingsTypes = {
  avatar?: string;
  avatarId?: string;
  username: string;
  slug: string;
  location: string;
  email: string;
  socialMedia?: SocialMediaTypes;
  publicWalletAddress?: string;
  myCollectionPublic: boolean;
  mfaEnabled: boolean;
};

type AccountSettingsResponseTypes = {
  image?: string;
  avatar_id?: string;
  username: string;
  slug?: string;
  location: string;
  email: string;
  social_media?: {
    facebook?: string;
    twitter?: string;
    tiktok?: string;
    youtube?: string;
    instagram?: string;
  };
  publicWalletAddress?: string;
  my_collection_public: boolean;
  mfa_enabled: boolean;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().required('Artist Name is required'),
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
});

const AccountSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const viewport = useViewport();
  const { token } = useParams();

  const paymentId = location.search.replace('?id=', '');
  const userId = getUserIdFromJWT();

  /*const [showSuccessBanner, setShowSuccessBanner] = useState<{
    show: boolean;
    type: 'email' | 'password';
  }>({ show: false, type: 'email' });*/

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [accountData, setAccountData] =
    useState<AccountSettingsResponseTypes>();

  const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);
  const [showCardPaymentModal, setShowCardPaymentModal] =
    useState<boolean>(false);
  const [showCardPaymentResultModal, setShowCardPaymentResultModal] =
    useState<boolean>(false);
  const [showCryptoIncomingModal, setShowCryptoIncomingModal] =
    useState<boolean>(false);
  const [showAddIncomingCryptoModal, setShowAddIncomingCryptoModal] =
    useState<boolean>(false);
  const [showAddOutgoingCryptoModal, setShowAddOutgoingCryptoModal] =
    useState<boolean>(false);
  const [usersCountry, setUsersCountry] = useState<string>('');
  const [showCountrySelection, setShowCountrySelection] =
    useState<boolean>(false);

  useEffect(() => {
    if (token) {
      confirmChangeEmail();
    }
  }, []);

  useEffect(() => {
    geoService
      .getUserIP()
      .then((userIP) => {
        if (userIP)
          geoService.getUserCountry(userIP).then((countryResponse) => {
            if (countryResponse) {
              setUsersCountry(countryResponse.country);
            }
          });
      })
      .catch(() => {
        setUsersCountry('0');
        setShowCountrySelection(true);
      });

    window.document.addEventListener('scroll', () => {
      const autocompleteHTMLElements = Array.from(
        window.document.getElementsByClassName('pac-container'),
      );
      autocompleteHTMLElements.forEach((element) => {
        element.setAttribute('style', 'display: none;');
      });
    });
  }, []);

  const [confirmEmailChange] = useMutation(CHANGE_EMAIL_CONFIRM, {
    variables: {
      tokenValue: token,
    },
  });

  async function getAccountSettings() {
    const transactions = await restService
      .getAccountSettings()
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve account settings: ',
          text: error.message,
        });
      });
    return transactions;
  }

  useEffect(() => {
    getAccountSettings()
      .then((res) => {
        const data = {
          image: res.image,
          avatar_id: res.avatar_id,
          username: res.username,
          slug: res.slug,
          location: res.location,
          email: res.email,
          social_media: {
            facebook: res.social_media.facebook,
            instagram: res.social_media.instagram,
            twitter: res.social_media.twitter,
            youtube: res.social_media.youtube,
            tiktok: res.social_media.tiktok,
          },

          my_collection_public: res.my_collection_public,
          mfa_enabled: res['2fa_enabled'],
        };
        setAccountData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const initialValues = useMemo<AccountSettingsTypes>(
    () => ({
      avatar: R.defaultTo('', accountData?.image),
      avatarId: R.defaultTo('', accountData?.avatar_id),
      username: R.defaultTo('', accountData?.username),
      slug: R.defaultTo('', accountData?.slug),
      location: R.defaultTo('', accountData?.location),
      email: R.defaultTo('', accountData?.email),
      publicWalletAddress: '',
      socialMedia: {
        facebook: R.defaultTo('', accountData?.social_media?.facebook),
        instagram: R.defaultTo('', accountData?.social_media?.instagram),
        twitter: R.defaultTo('', accountData?.social_media?.twitter),
        youtube: R.defaultTo('', accountData?.social_media?.youtube),
        tiktok: R.defaultTo('', accountData?.social_media?.tiktok),
      },
      myCollectionPublic: R.defaultTo(false, accountData?.my_collection_public),
      mfaEnabled: R.defaultTo(false, accountData?.mfa_enabled),
    }),
    [accountData],
  );

  const { values, handleChange, setFieldValue, errors, touched, setValues } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: submitHandler,
    });

  const quickLinks = [
    {
      title: 'My Wallet',
      link: '/account/wallet',
    },
    {
      title: 'My Collection',
      link: '/library/my-collection',
    },
    /*{
      title: 'My Royalties',
      link: '/account/royalty',
    },*/
    { title: 'View Public Profile', link: `/${accountData?.slug}` },
    /*{
        title: 'Payouts',
        link: '/account/payouts',
      },*/
  ];

  // Handle manual country selection
  useEffect(() => {
    if (showCountrySelection) {
      submitHandlerLocation();
    }
  }, [values.location]);

  // Handle manual country selection
  useEffect(() => {
    if (showCountrySelection) {
      submitHandlerLocation();
    }
  }, [values.location]);

  const [updateUserProfile] = useMutation<
    UPDATE_USER_BY_ID_MUTATION,
    UPDATE_USER_BY_ID_VARIABLES
  >(UPDATE_USER_BY_ID, {
    variables: {
      id: userId,
      facebook: values.socialMedia?.facebook,
      instagram: values.socialMedia?.instagram,
      twitter: values.socialMedia?.twitter,
      youtube: values.socialMedia?.youtube,
      tiktok: values.socialMedia?.tiktok,
      myCollectionPublic: values.myCollectionPublic,
    },
  });

  const [updateUserProfileAvatar] = useMutation<
    UPDATE_USER_BY_ID_MUTATION,
    UPDATE_USER_BY_ID_VARIABLES
  >(UPDATE_USER_BY_ID, {
    variables: {
      id: userId,
      avatarId: values.avatarId,
    },
  });

  const handleImage = (avatar: string, avatarId: string) => {
    setValues({ ...values, avatar, avatarId });
    // update profile with image
    submitHandlerAvatar();
  };

  async function submitHandlerAvatar() {
    try {
      await updateUserProfileAvatar();
    } catch (error: any) {
      console.error(error);
    }
  }

  const [updateUserProfileLocation] = useMutation<
    UPDATE_USER_BY_ID_MUTATION,
    UPDATE_USER_BY_ID_VARIABLES
  >(UPDATE_USER_BY_ID, {
    variables: {
      id: userId,
      location: values.location,
    },
  });

  async function submitHandlerLocation() {
    try {
      await updateUserProfileLocation();
    } catch (error: any) {
      console.error(error);
    }
  }

  async function submitHandler() {
    try {
      await updateUserProfile();
    } catch (error: any) {
      console.error(error);
    }
  }

  async function confirmChangeEmail() {
    try {
      const response = await confirmEmailChange();
      if (!response.errors) {
        if (response.data) {
          navigate('/account/settings');
          setGlobalBanner({
            title: 'Email Address Updated!',
            text: '',
          });
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  function handleLocationSelect(place: google.maps.places.PlaceResult) {
    const city = place.address_components?.find((component) =>
      component.types.includes('locality'),
    )?.long_name;

    const county = place.address_components?.find((component) =>
      component.types.includes('administrative_area_level_2'),
    )?.long_name;

    const country = place.address_components?.find((component) =>
      component.types.includes('country'),
    )?.short_name;

    let location_string = '';

    if (city) {
      location_string += city;
    }

    if (county) {
      if (location_string) {
        location_string += ', ';
        location_string += county;
      } else {
        location_string += county;
      }
    }

    if (country) {
      if (location_string) {
        location_string += ', ';
        location_string += country;
      } else {
        location_string += country;
      }
    }

    setFieldValue('location', location_string);

    submitHandlerLocation();
  }

  const secondColumn = (
    <>
      {!loading ? (
        <>
          <ChangeUsername username={values.username} />
          {values.location === '0' || showCountrySelection ? (
            <>
              <InputContainer>
                <Icon bottom={5}>
                  <World />
                </Icon>
                <DropdownContainer marginLeft={32}>
                  <DropDown
                    className="search-country"
                    options={countryCodeById}
                    value={countryCodeById.find(
                      (data) => data.label === values.location,
                    )}
                    styles={menuStyleFullWidthSignUp}
                    placeholder="Search country"
                    onChange={(option) => {
                      setShowCountrySelection(true);
                      const country = (option as CommonOptionType).label;
                      setFieldValue('location', country);
                    }}
                  />
                </DropdownContainer>
              </InputContainer>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}>
                <Typography
                  text={
                    touched.location && !values.location
                      ? 'Country is required'
                      : ''
                  }
                  fontSize="fz12"
                  fontColor={theme.colors.yellow}
                />
              </div>
            </>
          ) : (
            <>
              <TextInput
                height={viewport.width >= 576 ? 70 : 55}
                type="text"
                withBottomLine
                value={values.location}
                onChange={handleChange('location')}
                placeholder="Start typing city"
                error={
                  Boolean(errors.location && touched.location)
                    ? errors.location
                    : undefined
                }
                autoComplete={'false'}
                googleAutoComplete={{
                  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                  onPlaceSelected: (place) => handleLocationSelect(place),
                  options: {
                    componentRestrictions: {
                      country: usersCountry,
                    },
                    types: [
                      'locality',
                      'administrative_area_level_2',
                      'country',
                    ],
                  },
                }}
              />
            </>
          )}
          <Spacer height={20} />
          <Caption text="Email" />
          <ChangeEmail email={values.email} />
          <Spacer height={20} />
          <Media
            tip="Enter your social media accounts for your public profile (optional)"
            type="socialMedia"
            values={values.socialMedia || {}}
            handleSetState={(field, value) => {
              setFieldValue(field, value).then(() => {
                submitHandler();
              });
            }}
          />
          <Spacer height={20} />
          <ToggleContainer>
            <Typography
              fontSize="fz18"
              fontWeight="regular"
              text="Show Purchases on My Profile"
              fontColor={theme.colors.white}
            />
            <Toggle
              field="myCollectionPublic"
              toggle={values.myCollectionPublic}
              setToggle={setFieldValue}
              onToggle={() => {
                setTimeout(() => {
                  submitHandler();
                }, 1000);
              }}
            />
          </ToggleContainer>
          <Spacer height={20} />
          <YellowLine />
          <Spacer height={20} />
          {/*<Caption text="My Wallet Overview" />
          <Spacer height={20} />
          <WalletOverview />
            <Spacer height={20} />*/}
          <Caption text="Security Settings" tip="Edit your security settings" />
          <Spacer height={30} />
          <Caption text="Password" />
          <ChangePassword
            email={values.email}
            onPasswordReset={() => {
              setGlobalBanner({
                title: 'Rest Password',
                text: 'Email sent!',
              });
            }}
          />

          <Spacer height={20} />
          <Caption text="Two Factor Authentication" />
          <Spacer height={10} />
          <TwoFA
            mfaEnabled={values.mfaEnabled}
            onUpdate={() => {
              getAccountSettings().then((res) => {
                const data = {
                  image: res.image,
                  avatar_id: res.avatar_id,
                  username: res.username,
                  slug: res.slug,
                  location: res.location,
                  email: res.email,
                  facebook: res.social_media.facebook,
                  instagram: res.social_media.instagram,
                  twitter: res.social_media.twitter,
                  youtube: res.social_media.youtube,
                  tiktok: res.social_media.tiktok,
                  my_collection_public: res.my_collection_public,
                  mfa_enabled: res['2fa_enabled'],
                };

                setAccountData(data);
              });
              setGlobalBanner({
                title: 'Two Factor Authetication: ',
                text: 'Setup!',
              });
            }}
            refreshAccountSettings={() => {
              getAccountSettings().then((res) => {
                const data = {
                  image: res.image,
                  avatar_id: res.avatar_id,
                  username: res.username,
                  slug: res.slug,
                  location: res.location,
                  email: res.email,
                  facebook: res.social_media.facebook,
                  instagram: res.social_media.instagram,
                  twitter: res.social_media.twitter,
                  youtube: res.social_media.youtube,
                  tiktok: res.social_media.tiktok,
                  my_collection_public: res.my_collection_public,
                  mfa_enabled: res['2fa_enabled'],
                };

                setAccountData(data);
              });
            }}
          />
          {viewport.width <= 576 ? (
            <>
              <Spacer height={20} />
              <ButtonLink to={'/' + initialValues.slug}>
                View Public Profile
              </ButtonLink>
            </>
          ) : (
            <></>
          )}

          <Spacer height={viewport.width >= 576 ? 80 : 10} />
        </>
      ) : (
        <>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <YellowLine />
          <Spacer height={20} />

          <TextInputPlaceholder></TextInputPlaceholder>

          <Spacer height={viewport.width >= 576 ? 80 : 10} />
        </>
      )}
    </>
  );

  return (
    <PageContainer pageTitle={'Account Settings | SongBits'}>
      <BreadcrumbsContainer>
        {viewport.width < 576 && <MobileBreadcrumbs />}
      </BreadcrumbsContainer>
      <PageLayout
        padding={
          viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
        }
        loading={loading}
        title="Account Settings"
        sections={[
          {
            content: (
              <>
                <ProfileImageContainer>
                  {!loading ? (
                    <ImagePicker
                      type="avatar"
                      image={values.avatar}
                      handleImage={handleImage}
                    />
                  ) : (
                    <ImagePickerPlaceholder></ImagePickerPlaceholder>
                  )}
                </ProfileImageContainer>
              </>
            ),
          },
          {
            content: secondColumn,
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                }}>
                {loading && viewport.width >= 767 ? (
                  <QuickLinksPlaceholder noLinks={4}></QuickLinksPlaceholder>
                ) : (
                  <>
                    {viewport.width <= 767 ? (
                      <></>
                    ) : (
                      <QuickLinks links={quickLinks} isLogout />
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />

      <SBModal
        isOpen={showAddCardModal}
        width="440px"
        content={
          <AddCardModal
            type={PaymentModalType.Normal}
            onChange={() => {
              setShowAddCardModal(false);
            }}
            onCardAdded={() => {
              setShowAddCardModal(false);
              setShowCardPaymentModal(true);
            }}
          />
        }
      />
      <SBModal
        isOpen={showCardPaymentModal}
        width="434px"
        height="515px"
        content={
          <CardPaymentModal
            type={PaymentModalType.Normal}
            onClose={() => {
              setShowCardPaymentModal(false);
            }}
            onPaymentComplete={() => {
              setShowCardPaymentModal(false);
            }}
            onPaymentFail={() => {}}
            onAddCard={() => {}}
          />
        }
      />
      <SBModal
        isOpen={showCardPaymentResultModal}
        width="434px"
        height="515px"
        content={
          <CardPaymentResultModal
            uuid={paymentId}
            onChange={() => {
              setShowCardPaymentResultModal(false);
            }}
          />
        }
      />
      <SBModal
        isOpen={showCryptoIncomingModal}
        width="434px"
        height="515px"
        content={
          <CryptoIncomingModal
            type={PaymentModalType.Normal}
            onChange={() => {
              setShowCryptoIncomingModal(false);
            }}
          />
        }
      />
      <SBModal
        isOpen={showAddIncomingCryptoModal}
        width="434px"
        height="515px"
        content={
          <AddIncomingCryptoModal
            type={PaymentModalType.Normal}
            onChange={() => {
              setShowAddIncomingCryptoModal(false);
            }}
          />
        }
      />
      <SBModal
        isOpen={showAddOutgoingCryptoModal}
        width="434px"
        height="515px"
        content={
          <iframe src="" id="outgoing_crypto_frame">
            <AddOutgoingCryptoModal
              type={PaymentModalType.Normal}
              onChange={() => {
                setShowAddOutgoingCryptoModal(false);
              }}
              onConnected={() => {
                setShowAddOutgoingCryptoModal(false);
                // Show crypto payment modal
              }}
            />
          </iframe>
        }
      />
    </PageContainer>
  );
};

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const YellowLine = styled.div`
  display: block;
  height: 1px;
  background: 1px linear-gradient(0.25turn, #ffd600, #ffd600, transparent);
  opacity: 0.5;
  transform: translateX(-5px);
  width: inherit;
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  padding: 0 20px 0 5px;
`;

export default AccountSettings;
