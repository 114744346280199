import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import CopyIcon from 'common/icons/Copy.icon';
import Button from 'common/components/Button/Button';
import theme from 'theme/theme';

interface TwoFaManualEntryProps {
  manualData: {
    issuer: string;
    label: string;
    secret: string;
  };
  onNext: () => void;
}

const TwoFaManualEntry: FC<TwoFaManualEntryProps> = ({
  manualData,
  onNext,
}) => {

  const [accountCopied, setAccountCopied] = useState<boolean>(false);
  const [keyCopied, setKeyCopied] = useState<boolean>(false);

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text);

    if (type === 'account') {
      setAccountCopied(true)
    }
    if (type === 'key') {
      setKeyCopied(true)
    }
  };

  useEffect(() => {

    if (keyCopied) {
      setTimeout(() => {
        setKeyCopied(false);
      }, 3000);
    }
    if (accountCopied) {
      setTimeout(() => {
        setAccountCopied(false);
      }, 3000);
    }

  }, [accountCopied, keyCopied])

  return (
    <CustomComponentContainer>
      <Spacer height={20} />
      <Typography lineHeight={'24px'} text="In the authenticator app, tap the ‘+’ buttion and select ‘enter code’ Enter the following details to add SongBits" />

      <Spacer height={30} />

      <DetailsContainer>
        <DetailsCol width="25%">
          <Typography text="Account: " />
        </DetailsCol>

        <DetailsCol width="50%">
          <Typography text={manualData.issuer + ' (' + manualData.label + ')'} fontWeight="bold" />
        </DetailsCol>

        <IconWrapper>
          <CopyButton onClick={() => copyToClipboard(manualData.issuer + ' (' + manualData.label + ')', 'account')}>
            <CopyIcon />
          </CopyButton>
          { accountCopied && (
            <Typography text={'copied!'} fontWeight="light" fontColor='yellow' fontSize='fz14' />
          )}
        </IconWrapper>
      </DetailsContainer>


      <DetailsContainer>
        <DetailsCol width="25%">
          <Typography text="Key: " />
        </DetailsCol>

        <DetailsCol width="50%">
          <Typography text={manualData.secret.replaceAll('=', '')} fontWeight="bold" />
        </DetailsCol>

        <IconWrapper>
        <CopyButton onClick={() => copyToClipboard(manualData.secret.replaceAll('=', ''), 'key')}>
          <CopyIcon />
          </CopyButton>
          { keyCopied && (
            <Typography text={'copied!'} fontWeight="light" fontColor='yellow' fontSize='fz14' />
          )}
        </IconWrapper>
      </DetailsContainer>

      <Spacer height={15} />

      <FirstButton
        height={45}
        width={226}
        borderRadius={50}
        borderColor={theme.colors.white}
        label={
          <div style={{ display: 'flex' }}>
            <ContentButton text="request" fontSize="fz16" fontWeight="bold" />
            &nbsp;
            <ContentButton
              text="authorization"
              fontSize="fz16"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
            />
          </div>
        }
        onClick={onNext}
      />
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const DetailsContainer = styled.div`
  width: 100%;

  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
`;

const DetailsCol = styled.div<{ width: string }>`
  width: ${(props) => props.width};

  overflow: hidden;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  margin-left: 10px;

  cursor: pointer;
`;

const FirstButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const CopyButton = styled.button`
  background-color: rgb(0, 0, 0, 0);
  border: none;
  margin-left: auto;
  cursor: pointer;
`;

export default TwoFaManualEntry;
