import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import QuestionMark from 'common/icons/QuestionMark.icon';
import theme from 'theme/theme';
import { sm, md } from 'utils/constants';

type TipProps = {
  text: string | JSX.Element;
  width?: number;
  marginTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  left?: number;
  top?: number;
  position?: 'left' | 'right';
  gtag_event?: string;
};

const Tip = ({
  text,
  width,
  marginTop,
  marginLeft,
  marginBottom,
  left,
  top,
  position,
  gtag_event,
}: TipProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside = ({ target }: Event) => {
      if (isOpen && !ref.current?.contains(target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  const openTip = () => {
    setOpen(true);
    if (gtag_event) {
      (window as any).gtag('event', gtag_event, {});
    }
  };

  return (
    <Container
      ref={ref}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginBottom={marginBottom}>
      <TipButton label={<QuestionMark />} onClick={openTip} />
      {isOpen && (
        <TipContainer width={width} left={left} top={top} position={position}>
          {typeof text === 'string' ? (
            <Typography
              text={text}
              fontSize="fz18"
              fontColor={theme.colors.yellow}
            />
          ) : (
            <>{text}</>
          )}
        </TipContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{
  marginTop?: number;
  marginLeft?: number;
  marginBottom?: number;
}>`
  position: relative;
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px` : '')};
  ${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px` : ''};
`;

const TipContainer = styled.div<{
  width?: number;
  left?: number;
  top?: number;
  position?: 'left' | 'right';
}>`
  ${(props) =>
    props.width ? `width: ${props.width}px;` : 'width: max-content;'}

  min-height: 50px;
  min-width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 25px;
  left: ${(props) => (props.left ? props.left : '-200')}px;
  ${(props) => props.top && `top: ${props.top}px;`}

  padding: 15px;
  border: 1px solid ${theme.colors.yellow};
  border-radius: ${(props) =>
    props.position
      ? props.position === 'left'
        ? '25px 25px 0 25px'
        : '25px 25px 25px 0;'
      : '25px 25px 0 25px;'};
  z-index: 10;

  background: ${theme.colors.black};

  @media (min-width: ${sm}px) {
    border-radius: 25px 25px 25px 0;
    left: 25px;
  }

  // @media (min-width: ${md}px) {
  //   left: 4px;
  //   bottom: 30px;
  // }
`;

const TipButton = styled(Button)`
  transform: scale(0.8);
  z-index: 10;
  padding: 0;

  @media (min-width: 576px) {
    transform: none;
  }
`;

export default Tip;
