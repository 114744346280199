import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { useViewport } from 'use-viewport';

import Typography from 'common/components/Typography/Typography';
import Step from '../Step/Step';

import DownloadIcon from 'common/icons/Download.icon';
import Instagram from 'common/icons/Instagram.icon';
import CopyFilledIcon from 'common/icons/CopyFilled.icon';

import { xs } from 'utils/constants';

interface InstgaramShareModalProps {
  shareUrl: string;
  imageLink: string;
}

const InstgaramShareModal: FC<InstgaramShareModalProps> = ({
  shareUrl,
  imageLink,
}) => {
  const viewport = useViewport();

  const iconsSize = viewport.width >= xs ? 55 : 34;

  const steps = useMemo(
    () => [
      {
        label: '1: save this image',
        subLabel: 'image downloaded!',
        buttonConfig: {
          text: 'download image',
          leftIcon: <DownloadIcon />,
        },
        linkConfig: {
          link: imageLink,
          dowloadFileName: imageLink,
        },
      },
      {
        label: '2: tap or click the link below to copy:',
        subLabel: 'link coied!',
        buttonConfig: {
          text: shareUrl,
          leftIcon: <CopyFilledIcon />,
          onClick: () => {
            navigator.clipboard.writeText(shareUrl);
          },
        },
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <DescriptionContainer>
        <IconWrapper>
          <Instagram height={iconsSize} width={iconsSize} opacity={1} />
        </IconWrapper>
        <Typography
          text={'To share this song via a post or story on instagram:'}
          fontSize="fz18"
          lineHeight='22px'
          fontWeight="light"
        />
      </DescriptionContainer>

      <StepsContainer>
        {steps.map((stepData, index) => (
          <Step key={`Share instagram step ${index}`} {...stepData} />
        ))}
      </StepsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const DescriptionContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 45px;

  gap: 12px;
`;

const IconWrapper = styled.div`
  width: 34px;
  min-width: 34px;

  @media (min-width: ${xs}px) {
    width: 55px;
    min-width: 55px;
  }
`;

const StepsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 20px;
`;

export default InstgaramShareModal;
