import React, { FC } from 'react';
import { useViewport } from 'use-viewport';

import {
  CloseButton,
  ModalContainer,
  ModalContentContainer,
  Title,
} from '../Modals/index.styled';

import { ModalProps } from '../Modals/index.type';

interface ModalLayoutProps extends ModalProps {
  title?: string;
}

const ModalLayout: FC<ModalLayoutProps> = ({
  title = '',
  children,
  onClose,
}) => {
  const viewport = useViewport();

  return (
    <ModalContainer>
      <CloseButton onClick={() => onClose()}>X</CloseButton>
      <Title
        text={title}
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz36'}
        letterSpacing="-0.03em"
      />

      <ModalContentContainer
        flexDir="row"
        justifyContent="space-around"
        padding="10px 0 0 0">
        {children}
      </ModalContentContainer>
    </ModalContainer>
  );
};

export default ModalLayout;
