import React, { FC } from 'react';

import { ModalContentContainer } from '../Modals/index.styled';

import { Button } from '../../styled/SongProfilePublic.styled';

import { ModalProps } from '../Modals/index.type';
import styled from 'styled-components';
import { sm } from 'utils/constants';
import { useStoreState } from 'store/store';
import { InstractionalStages } from 'typings/types';

interface ModalLayoutProps extends ModalProps {
  show: boolean;
  onNext: () => void;
}

const ModalInstructionalLayout: FC<ModalLayoutProps> = ({
  show,
  children,
  onClose,
  onNext,
}) => {
  const stage = useStoreState((state) => state.instructions.stage);

  if (!show) return null;

  return (
    <ModalWrapper>
      <ModalContainer>
        <ModalContentContainer
          flexDir="row"
          justifyContent="space-around"
          padding="10px 0 0 0">
          {children}
        </ModalContentContainer>

        <ButtonContainer>
          <Button
            backgroundColor="#000000"
            textColor="#FFFFFF"
            border={'#FFFFFF'}
            onClick={onClose}>
            cancel
          </Button>
          {stage === InstractionalStages.bitsPicker ? (
            <Button
              backgroundColor="#FFD600"
              textColor="#000000"
              border={'#FFD600'}
              onClick={onClose}>
              finish
            </Button>
          ) : (
            <Button
              backgroundColor="#000000"
              textColor="#FFFFFF"
              border={'#FFD600'}
              onClick={onNext}>
              next
            </Button>
          )}
        </ButtonContainer>
      </ModalContainer>
    </ModalWrapper>
  );
};

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;

  background: rgba(0, 0, 0, 0.95);
`;

const ButtonContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-beetwen;
  align-items: center;

  margin: ${(props) => (props.margin ? props.margin : '0')};

  position: absolute;
  bottom: 50px;
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, 0);
  row-gap: 20px;

  @media (min-width: 414px) {
    bottom: 100px;
  }

  @media (min-width: ${sm}px) {
    bottom: 150px;
    flex-direction: row;
    justify-content: start;
  }
`;

export default ModalInstructionalLayout;
