import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import Spacer from 'common/components/Spacer/Spacer';
import PageContainer from 'common/layout/PageContainer';
import Typography from 'common/components/Typography/Typography';
import Caption from 'common/components/Caption/Caption';
import IconCaption from 'common/components/IconCaption/IconCaption';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Visa from 'common/icons/Visa.icon';
import Clock from 'common/icons/Clock.icon';

import { MONTHS } from 'utils/constants';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import theme from 'theme/theme';
import { getUserIdFromJWT } from 'utils/functions';
import { GET_USERS_COUNTRY } from 'modules/library/graphql/Queries.graphql';
import PayoutFlow from 'modules/payments/PaymentModals/PayoutFlow';
import SBModal from 'common/components/Modal/SBModal';
import AddIncomingCryptoModal from './components/Modals/AddIncomingCryptoModal';
import CryptoIncomingModal from './components/Modals/CryptoIncomingModal';

import {roundNumberDown} from 'utils/functions'

import Tip from 'common/components/Tip/Tip';
import {
  PaymentModalType,
} from 'modules/payments/types';
import restService from 'services/rest.service';
import Mastercard from 'common/icons/Mastercard.icon';
import { useStoreActions, useStoreState } from 'store/store';
import AddCard from 'modules/payments/PaymentModals/AddCard';
import { ClipLoader as Loader } from 'react-spinners';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { v4 as uuidv4 } from 'uuid';

import { sm } from 'utils/constants';
import {
  AvailablePayments,
  BorderLineYellow,
  CardButtonContainer,
  EditCard,
  IconButton,
  NewCard,
  SmallText,
  TransactionLink,
  Tabs,
  Tab,
  PaddingContainer,
  PayPalButtonLabelContainer,
  PayPalButton,
  PayPalIconContainer
} from './styled/MyWallet.styled';

import { TypographyFontSizes, TypographyFontWeight } from 'common/common.types';
import Edit from 'common/icons/Edit.icon';
import Delete from 'common/icons/Delete.icon';
import RemoveCardModal from './components/Wallet/RemoveCardModal';
import RestrictedCountry from 'modules/payments/PaymentModals/RestrictedCountry';
import PageLayout from 'common/components/PageLayout/PageLayout';
import ImagePicker from '../../../common/components/ImagePicker/ImagePicker';
import ConnectWalletButton from '../../../common/components/WalletButton/ConnectWalletButton';
//import DisconnectWalletButton from '../../../common/components/WalletButton/DisconnectWalletButton';
import OpenYellow from '../../../common/icons/OpenYellow.icon';
import { Button } from '../../library/pages/styled/SongProfilePublic.styled';
import USDCCryptoIcon from '../../../common/icons/USDCCryptoIcon';
import PayPal from '../../../common/icons/PayPal.icon';
//import Email from '../../../common/icons/Email.icon';
import ConnectWalletOutgoingDisabled from './components/ConnectWalletOutgoingDisabled';

import {
  UPDATE_USER_BY_ID_MUTATION,
  UPDATE_USER_BY_ID_VARIABLES,
} from '../types';
import { UPDATE_USER_BY_ID } from '../graphql/Mutations.graphql';

export interface CardDetails {
  cardid: string;
  nickname: string;
  last4: string;
  bin: string;
}

interface RemoveCardType {
  show: boolean;
  cardid: string;
}


const AccountSettings = () => {
  const userId = getUserIdFromJWT();
  const viewport = useViewport();

  const accessToken = useStoreState(
    (state) => state.authentication.accessToken,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );
  
  const [connectPayPalLoading, setConnectPayPalLoading] = useState<boolean>(false);
  const [disconnectPayPalLoading, setDisconnectPayPalLoading] = useState<boolean>(false);
  const [payoutRequested, setPayoutRequested] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  const [showRestrictedCountryModal, setShowRestrictedCountryModal] =
    useState<boolean>(false);
  const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);
  const [showCryptoIncomingModal, setShowCryptoIncomingModal] =
    useState<boolean>(false);
  const [showAddIncomingCryptoModal, setShowAddIncomingCryptoModal] =
    useState<boolean>(false);
  const [cardId, setCardId] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);
  const [paymentMethod, setPaymentMethod] = useState<string>('card');
  const [avatarId, setAvatarId] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  
  const [payoutOption, setPayoutOption] = useState<string>('paypal');
  //const [paypalEmail, setPaypalEmail] = useState<string>('');
  //const [openedWindow, setOpenedWindow] = useState<any>({});

  /*const setOAuthWindow = useStoreActions(
    (actions) => actions.authentication.setOAuthWindow,
  );*/
  /*const closeOAuthWindow = useStoreActions(
    (actions) => actions.authentication.closeOAuthWindow,
  );*/

  const { data: userCountry, loading: countryLoading } = useQuery(
    GET_USERS_COUNTRY,
    {
      variables: { id: userId },
      notifyOnNetworkStatusChange: false,
    },
  );

  const [showRemoveCardModal, setShowRemoveCardModal] =
    useState<RemoveCardType>({
      show: false,
      cardid: '',
    });

  const isMobile = useMemo(() => viewport.width < sm, [viewport.width]); 


  const quickLinks = useMemo(
    () => [
      {
        title: 'My Collection',
        link: '/library/my-collection',
      },
      {
        title: 'Transaction History',
        link: '/account/transactions',
      },
      {
        title: 'Account Settings',
        link: '/account/settings',
      },
      /*{
        title: 'My Royalties',
        link: '/account/royalty',
      },*/
      /*{
        title: 'Payouts',
        link: '/account/payouts',
      },*/
    ],
    [],
  );
  

  function formatDate(date: string) {
    const d = new Date(date);
    return d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + d.getFullYear();
  }


  function refetch() {
    setLoading(true);
    restService.myWallet().then((data) => {
        if (parseFloat(data.balance) <= 0) {
          data.balance = "0.00"
        } else (
          data.balance = roundNumberDown(data.balance)
        )
        if (parseFloat(data.balance_increase_month.replace('%', '').replace('+', '')) <= 0) {
          data.balance_increase_month = "+ 0.00%"
        } else {
          data.balance_increase_month = data.balance_increase_month.replace('+', '+ ')
        }

        setData(data)
        console.log(data)
        
        setAvatar(data.profile_image)
        /*if (data.paypal) {
          setPaypalEmail(data.paypal.email_address);
        }*/
        setLoading(false);
    }).catch((error: any) => {
      setLoading(false);
      setGlobalBanner({
        title: 'Failed to retrieve my wallet settings: ',
        text: error.message,
      });
    })
  }

  function connectSocket() {
    const wsURL = process.env.REACT_APP_REST_URL?.replace('https', 'wss') + '/ws';

    const { sendJsonMessage } = useWebSocket(wsURL, {
      share: true,
      onOpen: () => {
        console.log('Websocket connection opened in MyWallet.tsx');
        if (accessToken) {
          sendJsonMessage({
            message_id: uuidv4(),
            command: 'authenticate',
            params: {
              token: accessToken,
            },
          });
        }
      },
      onClose: () =>
        console.log('Websocket connection closed in MyWallet.tsx'),
      onMessage: (message) => {
        const messageData = JSON.parse(message.data);

        let status = 'pending';
        try {
          status = messageData.event.data.paypal_auth.status;
          console.log(messageData)
        } catch {}

        if (status === 'success') {
          refetch();
          //closeOAuthWindow(true); /* cors error ugh */
        } else if (status === 'error') {
          refetch();
          //closeOAuthWindow(true); /* cors error ugh */
        }
      },
    });
  }

  connectSocket();

  useEffect(() => {
    refetch();
  }, []);

  const [updateUserProfileAvatar] = useMutation<
    UPDATE_USER_BY_ID_MUTATION,
    UPDATE_USER_BY_ID_VARIABLES
  >(UPDATE_USER_BY_ID, {
    variables: {
      id: userId,
      avatarId: avatarId,
    },
  });

  const handleImage = (avatar: string, avatarId: string) => {
    // update profile with image
    setAvatar(avatar)
    setAvatarId(avatarId)
    submitHandlerAvatar();
  };

  async function submitHandlerAvatar() {
    try {
      await updateUserProfileAvatar();
    } catch (error: any) {
      console.error(error);
    }
  }
  function openWindow(response: any, popup: any) {
    return window.open(response.oauth_url, popup.target, `width=400,height=650,screenX=${popup.left},left=${popup.left},screenY=${popup.top},top=${popup.top},status=yes,menubar=no`);
  }

  async function connectPayPalSubmitHandler() {
    setConnectPayPalLoading(true)
    const response = await restService.connectPayPal().catch((error) => {
      setConnectPayPalLoading(false)
      setGlobalBanner({
        title: 'Failed',
        text: error.message,
      });
    });
    setConnectPayPalLoading(false)

    const popup = {
      left: 0,
      top: 0,
      target: "paypal_auth",
    }

    if (isMobile === true) {
      popup.target = "_self";
    }
    if (window.screen.availHeight > 650 && window.screen.availWidth > 400) {
      popup.left = window.screenLeft + ((window.innerWidth - 400) / 2);
      popup.top = window.screen.availHeight + (window.outerHeight - window.innerHeight);
    }
    openWindow(response, popup);
    //setOAuthWindow(opened_window);
  }
  async function disconnectPayPalSubmitHandler() {
    setDisconnectPayPalLoading(true)
    await restService.logoutPayPal().catch((error) => {
      setDisconnectPayPalLoading(false);
      setGlobalBanner({
        title: 'Failed',
        text: error.message,
      });
    });
    setDisconnectPayPalLoading(false)
    setGlobalBanner({
      title: 'Disconnected Successfully!',
      text: '',
    });
    refetch();
  }

  const WhiteCaption = ({
    text,
    description,
    tip,
    fontSize,
    fontSizeDescription,
    fontWeight,
  }: {
    text: string;
    description?: string;
    tip?: string;
    fontSize?: keyof typeof TypographyFontSizes;
    fontSizeDescription?: keyof typeof TypographyFontSizes;
    fontWeight?: keyof typeof TypographyFontWeight;
  }) => {
    return (
      <AvailablePayments>
        <Typography
          text={text}
          fontSize={fontSize ? fontSize : 'fz18'}
          fontWeight={fontWeight ? fontWeight : 'regular'}
          fontColor={theme.colors.white}
        />
        {description && (
          <>
            <Spacer height={10} />
            <Typography
              text={description}
              fontSize={fontSizeDescription ? fontSizeDescription : 'fz18'}
              fontWeight={fontWeight ? fontWeight : 'regular'}
              fontColor={theme.colors.white}
            />
          </>
        )}
        {tip && (
          <Tip
            width={210}
            text={
              <div>
                <Typography
                  text={
                    tip
                  }
                  fontSize={'fz14'}
                  lineHeight={'18px'}
                />
              </div>
            }
          />
        )}
      </AvailablePayments>
    );
  };

  const Card = ({ icon, text }: { icon: JSX.Element; text: string }) => {
    return (
      <EditCard>
        <IconCaption
          className="linked-card"
          icon={icon}
          text={text}
          fontSize="fz12"
          fontWeight="regular"
          fontColor={theme.colors.white}
        />
      </EditCard>
    );
  };

  const SmallCaption = ({
    icon,
    text,
  }: {
    icon: JSX.Element;
    text: string;
  }) => {
    return (
      <EditCard>
        <IconCaption
          icon={icon}
          text={text}
          fontSize="fz14"
          fontWeight="bold"
          fontColor={theme.colors.white}
        />
      </EditCard>
    );
  };

  const EditButtons = ({ card }: { card?: string; email?: string }) => {
    setCardId(card);
    return (
      <CardButtonContainer>
        <IconButton
          label={<Delete />}
          className="remove-card-button"
          onClick={() => {
            if (card) setShowRemoveCardModal({ show: true, cardid: card });
            //if (paypalEmail) setPaypalEmail('');
            //if (paypalEmail) setIsPaypal(true);
          }}
        />
        <IconButton
          label={<Edit width={19} height={19} />}
          className="edit-card-button"
          onClick={() => {
            if (card) setShowAddCardModal(true);
            //if (paypalEmail) setIsPaypal(true);
          }}
        />
      </CardButtonContainer>
    );
  };

  const secondColumn = (
    <>
      {loading || countryLoading ? (
        <>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={viewport.width >= 576 ? 80 : 10} />
        </>
      ) : (
        <>
          <PaddingContainer>
            <Typography
              fontSize={viewport.width >= 576 ? "fz60" : "fz38"}
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              text={'$' + data.balance}
            />
            <Spacer height={6} />
            <Typography fontSize="fz24" fontWeight="light" text="available*" />
            <Spacer height={10} />
            <Typography fontSize="fz14" fontWeight="bold" text={data.balance_increase_month} />
            <Spacer height={10} />
            <TransactionLink to={`/account/transactions`}>
              <OpenYellow width={20} height={20} />
              <Typography
                text={'View transaction history'}
                fontSize="fz18"
                fontWeight="regular500"
                lineHeight="24px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
            </TransactionLink>
            <Spacer height={20} />
            {data.payouts ? <>
            <Typography fontSize="fz24" fontWeight="light" text="pending payout" />
            <Spacer height={12} />
            <Typography fontSize="fz14" fontWeight="bold" text={'$' + data.payouts.amount + '  (Requested ' + formatDate(data.payouts.timestamp) + ')'}  />
            </> : <>
            <Button
              onClick={() => {
                setPayoutRequested(true)
              }}
              disabled={parseFloat(data.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00") && (data.paypal) ? false : true}
              opacity={parseFloat(data.balance) > parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00") && (data.paypal) ? 1 : 0.5}
              backgroundColor={"#000000"}
              textColor={"#FFFFFF"}
              border={"#FFFFFF"}>
              withdraw
            </Button>
            </>}
            
            
            <Spacer height={19} />
          </PaddingContainer>
          <BorderLineYellow />
          <PaddingContainer>
            <Spacer height={24} />
            <Typography
              fontSize="fz24"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              text="Payment Options"
            />
            <Spacer height={30} />
            <Caption fontWeight="bold" text="Select Payment Method" tip="Add a payment method to use to purchase SongBits" />
            <Spacer height={26} />
            <Tabs>
              <Tab
                isActive={paymentMethod === 'card'}
                onClick={() => setPaymentMethod('card')}>
                <Visa width={31} height={24} />
                &nbsp;Card&nbsp;&nbsp;
              </Tab>
              <Tab
                isActive={paymentMethod === 'crypto'}
                onClick={() => setPaymentMethod('crypto')}>
                <USDCCryptoIcon
                  fill={theme.colors.yellow}
                  width={25}
                  height={25}
                />
                &nbsp;USDC Crypto
              </Tab>
            </Tabs>
            <Spacer height={30} />
            {paymentMethod === 'card' && (
              <>
                <SmallCaption
                  icon={<Clock />}
                  text="Instant | Visa & Mastercard Only"
                />
                {data.card && data.card.cardid ?
                  <>
                    <Spacer height={30} />
                    <EditCard >
                      <Card
                        icon={
                          data.card.bin.startsWith('4') ? <Visa /> : <Mastercard />
                        }
                        text={`   ●●●● ●●●● ●●●●`}
                      />
                      <Typography fontSize="fz18" text={data.card.pan_suffix} />
                      <EditButtons card={data.card.cardid} />
                    </EditCard>
                  </>
                  : <></>}
                
                {!data.card && (
                  <>
                    <Spacer height={30} />
                    <NewCard
                      className="add-new-card-button"
                      label="Add New Card"
                      onClick={() => {
                        if (
                          userCountry &&
                          userCountry.userById.countryByCountry.circleProhibited
                        ) {
                          setShowRestrictedCountryModal(true);
                        } else {
                          setShowAddCardModal(true);
                        }
                      }}
                    />
                  </>
                )}
              </>
            )}
            {paymentMethod === 'crypto' && (
              <>
                <SmallCaption
                  icon={<Clock />}
                  text="Instant | Gas fees apply"
                />
                <Spacer height={8} />
                <WhiteCaption
                  text={'Chain: ETH Main Network'}
                  fontWeight="light"
                  fontSize="fz14"
                />
                <Spacer height={8} />
                <SmallText
                  fontSize="fz14"
                  text="Supported coins: ETH, USDC"
                />
                { data.crypto ? (
                  <>
                    
                    <Spacer height={30} />
                    <WhiteCaption
                      text="Public Wallet Address:"
                      description={data.crypto.address}
                      fontWeight="light"
                      fontSize="fz18"
                      fontSizeDescription="fz14"
                    />
                    <Spacer height={30} />
                  </>
                ) : <><Spacer height={42} /></>}
                
                {data.crypto ? (
                  <>{/*<DisconnectWalletButton />*/}</>
                ) : (
                  <ConnectWalletButton />
                )}
              </>
            )}
            <Spacer height={25} />
          </PaddingContainer>
          <BorderLineYellow />
          <PaddingContainer>
            <Spacer height={23} />
            <Typography
              fontSize="fz24"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
              text="Payout Options"
            />
            <Spacer height={25} />
            <Caption fontWeight="bold" text="Select Payout Method" tip="Add a payout method to be able to withdraw your royalties" />
            <Spacer height={24} />
            <Tabs>
              <Tab
                isActive={payoutOption === 'paypal'}
                onClick={() => setPayoutOption('paypal')}>
                <PayPal width={24} height={24} />
                PayPal Transfer
              </Tab>
              {<Tab
                isActive={payoutOption === 'crypto'}
                onClick={() => setPayoutOption('crypto')}>
                <USDCCryptoIcon
                  fill={theme.colors.yellow}
                  width={25}
                  height={25}
                />
                &nbsp;USDC Crypto
                </Tab>}
            </Tabs>
            <Spacer height={30} />
            {payoutOption === 'paypal' && (
              <>
                <SmallCaption icon={<Clock />} text="same day transfers Mon-Fri" />
                <Spacer height={10} />
                <SmallText fontSize="fz14" text={"Minimum payout: $" + parseFloat(process.env.REACT_APP_PAYOUT_MINIMUM || "0.00").toFixed(2)} />
                <Spacer height={30} />
                
                {data.paypal ? (
                  <>
                    <Spacer height={10} />
                    <EditCard>
                      <Typography fontSize={viewport.width >= 576 ? "fz18" : "fz14"} text={data.paypal.email_address} />
                    </EditCard>
                    <Spacer height={15} />
                    {(data.paypal.authorization_approved === null) ? (
                      <>
                        {(data.paypal.latest_error_description !== null) ? (
                          <> 
                            <Spacer height={15} />
                            <Typography
                              fontSize="fz12"
                              lineHeight="1.5"
                              fontColor={theme.colors.yellow}
                              text={"ERROR: " + (data.paypal.latest_error === 'Error' ? 'Account connect system error' : data.paypal.latest_error_description)}
                            />
                          </>
                        ) : ( <> </> )}
                        <Spacer height={30} />
                        <PayPalButton
                          borderColor={theme.colors.white}
                          borderRadius={20}
                          label={<><PayPalButtonLabelContainer>
                            <PayPalIconContainer>
                              <PayPal width={20} height={20} />
                            </PayPalIconContainer>
                            {connectPayPalLoading ? <>
                              <div style={{'display': 'flex', 'width': '101px', 'justifyContent': 'center'}}>
                              <Loader color="white" loading={true} size={20} />
                              </div>
                              </> : <>
                            <Typography fontSize="fz14" fontWeight="bold" text="Reconnect PayPal" />
                            </>} 
                          </PayPalButtonLabelContainer></>}
                          onClick={connectPayPalSubmitHandler}
                        />
                      </>
                    ) : (
                      <>
                        <PayPalButton
                          borderColor={theme.colors.white}
                          borderRadius={20}
                          label={<><PayPalButtonLabelContainer>
                            <PayPalIconContainer>
                              <PayPal width={20} height={20} />
                            </PayPalIconContainer>
                            {disconnectPayPalLoading ? <>
                              <div style={{'display': 'flex', 'width': '101px', 'justifyContent': 'center'}}>
                              <Loader color="white" loading={true} size={20} />
                              </div>
                              </> : <>
                            <Typography fontSize="fz14" fontWeight="bold" text="Disconnect PayPal" />
                            </>} 
                          </PayPalButtonLabelContainer></>}
                          onClick={disconnectPayPalSubmitHandler}
                        />
                      </>
                    )}
                  </>
                ) : (
                  
                  <>
                  <PayPalButton
                    borderColor={theme.colors.white}
                    borderRadius={20}
                    label={<><PayPalButtonLabelContainer>
                      <PayPalIconContainer>
                        <PayPal width={20} height={20} />
                      </PayPalIconContainer>
                      {connectPayPalLoading ? <>
                        <div style={{'display': 'flex', 'width': '101px', 'justifyContent': 'center'}}>
                        <Loader color="white" loading={true} size={20} />
                        </div>
                        </> : <>
                      <Typography fontSize="fz14" fontWeight="bold" text="Connect PayPal" />
                      </>} 
                    </PayPalButtonLabelContainer></>}
                    onClick={connectPayPalSubmitHandler}
                  />
{/*
                    <Formik
                      initialValues={{ email: paypalEmail }}
                      validationSchema={VALIDATION_SCHEMA}
                      onSubmit={(values) => {

                        restService.setPayPal(values.email).then(() => {
                          setData({...data, paypal: {email_address: values.email}})
                          setPaypalEmail(values.email);
                          setIsPaypal(false);
                        })
                        
                      }}>
                      {({ submitForm, handleChange, touched, errors, values }) => (
                        <Form noValidate={true}>
                          <InputContainer>
                            <Icon>
                              <Email />
                            </Icon>
                            <InputWithIcon
                              height={62}
                              type="email"
                              value={values.email}
                              placeholder="enter paypal email address"
                              onChange={handleChange('email')}
                              small={true}
                              error={
                                Boolean(touched.email && errors.email)
                                  ? errors.email
                                  : undefined
                              }
                            />
                          </InputContainer>

                          <PayPalButton
                            borderColor={theme.colors.white}
                            borderRadius={20}
                            label={<><PayPalButtonLabelContainer>
                                <Typography fontSize="fz14" fontWeight="bold" text="Save PayPal Address" />
                                </PayPalButtonLabelContainer></>}
                            onClick={submitForm}
                          />

                        </Form>
                      )}
                      </Formik>*/}
                  </>
                )}
              </>
            )}
            {payoutOption === 'crypto' && (
              <>
                {/*<SmallCaption
                  icon={<Clock />}
                  text="Instant | Gas fees apply"
                />
                <Spacer height={8} />
                <WhiteCaption
                  text={'Chain: ETH Main Network'}
                  fontWeight="light"
                  fontSize="fz14"
                />
                <Spacer height={8} />
                <SmallText
                  fontSize="fz14"
                  text="Supported coins: USDC"
                />
                <Spacer height={8} />*/}
                {/*<SmallText
                  fontSize="fz14"
                  text="Minimum Payout: 10USDC"
            />*/}
                {data.crypto ? (
                  <>
                    {/*<Spacer height={30} />*/}

                    <Typography fontSize="fz18" text={"Coming Soon"} />
                    {/*<WhiteCaption
                      text="Public Wallet Address:"
                      description={data.crypto.address}
                      fontWeight="light"
                      fontSize="fz18"
                      fontSizeDescription="fz14"
                />*/}
                    <Spacer height={30} />
                    <ConnectWalletOutgoingDisabled />
                    <Spacer height={20} />
                  </>
                ) : <>
                <Spacer height={37} />
                </>}
                {/*data.crypto ? (
                <>{/*<DisconnectWalletButton />}</>
                ) : (<ConnectWalletOutgoing />)*/}
                <Spacer height={10} />
                {/*<Typography
                  fontSize="fz12"
                  fontColor={theme.colors.yellow}
                  lineHeight="1.5"
                  text="WARNING: Using Incorrect wallet or chain could result in complete loss of funds."
                />*/}
              </>
            )}
          </PaddingContainer>
          <Spacer height={25} />
          <BorderLineYellow />
          <PaddingContainer>
            <Spacer height={25} />
            <Typography
                    fontSize="fz12"
                    fontColor={theme.colors.white}
                    lineHeight="1.5"
                    text="*Subject to the delayed royalty reports having been received from the distributor"
                  />
          </PaddingContainer>
        </>
      )}
    </>
  );

  return (
    <>
      <PageContainer pageTitle={'My Wallet | SongBits'}>
        <BreadcrumbsContainer>
          {viewport.width < 576 && <MobileBreadcrumbs />}
        </BreadcrumbsContainer>
        <PageLayout
          loading={
            loading || countryLoading
          }
          secondColumnWidth="340px"
          padding={
            viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
          }
          title="My Wallet"
          sections={[
            {
              content: (
                <>
                  {!(loading || countryLoading) ? <>
                  <ProfileImageContainer>
                    <ImagePicker type="avatar" image={avatar} handleImage={handleImage} />
                  </ProfileImageContainer>
                  </> : <>
                  <ImagePickerPlaceholder></ImagePickerPlaceholder>
                  </>}
                </>
              ),
            },
            {
              content: secondColumn,
            },
            {
              content: (
                <div
                  style={{
                    display: 'flex',
                    gap: '25px',
                    flexDirection: 'column',
                    marginTop: '15px',
                  }}>
                  {loading
                   ? (
                    <QuickLinksPlaceholder></QuickLinksPlaceholder>
                  ) : (
                    <>
                      {viewport.width <= 767 ? (
                        <></>
                      ) : (
                        <QuickLinks
                          addFundsTrigger={() => {}}
                          addFunds={false}
                          links={quickLinks}
                          isLogout
                        />
                      )}
                    </>
                  )}
                </div>
              ),
            },
          ]}
        />

        <AddCard
          isOpen={showAddCardModal}
          type={PaymentModalType.Normal}
          fromMyWallet={true}
          cardId={cardId}
          onClose={() => {
            refetch();
            setShowAddCardModal(false);
          }}
          onCardAdded={() => {
            refetch();
            setShowAddCardModal(false);
          }}
        />

        <RestrictedCountry
          isOpen={showRestrictedCountryModal}
          onClose={() => {
            setShowRestrictedCountryModal(false);
          }}
          country={
            userCountry && userCountry.userById.countryByCountry.name
              ? userCountry.userById.countryByCountry.name
              : ''
          }
        />
        
        <SBModal
          isOpen={showCryptoIncomingModal}
          content={
            <CryptoIncomingModal
              type={PaymentModalType.Normal}
              onChange={() => {
                setShowCryptoIncomingModal(false);
              }}
            />
          }
        />

        <SBModal
          isOpen={showAddIncomingCryptoModal}
          content={
            <AddIncomingCryptoModal
              type={PaymentModalType.Normal}
              onChange={() => {
                setShowAddIncomingCryptoModal(false);
              }}
            />
          }
        />

        <SBModal
          isOpen={showRemoveCardModal.show}
          width="434px"
          height="280px"
          top="30%"
          content={
            <RemoveCardModal
              cardId={showRemoveCardModal.cardid}
              onClose={() => {
                setShowRemoveCardModal({
                  show: false,
                  cardid: '',
                });
              }}
              onDelete={() => {
                refetch()
                setShowRemoveCardModal({
                  show: false,
                  cardid: '',
                });
              }}
            />
          }
        />

      <PayoutFlow
        isOpen={payoutRequested}
        details={data}
        refresh={() => {
          refetch();
        }}
        onClose={() => {
          setPayoutRequested(false);
        }}></PayoutFlow>

      </PageContainer>
    </>
  );
};

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export default AccountSettings;
