import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import {
  setModalType,
  setShowState,
} from 'store-persist/reducers/modalSlice';

interface CardPaymentModalProps {
  isOpen: boolean;
}

const AddOutgoingCrypto: FC<CardPaymentModalProps> = ({ isOpen }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(setModalType('AddOutgoingCrypto'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);
  return (
    <SBModal
      isOpen={isOpen}
      withProceedingText={true}
      width="434px"
      content={
        <iframe src="/connect-wallet" height="95%" width="370px"></iframe>
      }
    />
  );
};

export default memo(AddOutgoingCrypto);
