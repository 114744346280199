import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useStoreActions } from 'store/store';

import styled from 'styled-components';

import restService from 'services/rest.service';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import {
  BitPurchaseSchema,
  CardSchema,
  PaymentModalType,
  BalanceDetails,
  QuickFlowStates,
  SuccessModalType,
} from 'modules/payments/types';

import AddCard from 'modules/payments/PaymentModals/AddCard';
import CardPayment from 'modules/payments/PaymentModals/CardPayment';
import AddOutgoingCrypto from 'modules/payments/PaymentModals/AddOutgoingCrypto';
import AddCryptoFunds from 'modules/payments/PaymentModals/AddCryptoFunds';
import AddFundsOptions from 'modules/payments/PaymentModals/AddFundsOptions';
import CardAuto from 'modules/payments/PaymentModals/CardAuto';
import PurchaseSuccess from 'modules/payments/PaymentModals/PurchaseSuccess';
import CardPaymentFailed from 'modules/payments/PaymentModals/CardPaymentFailed';
import CryptoAuto from 'modules/payments/PaymentModals/CryptoAuto';
import ConfirmPurchase from 'modules/payments/PaymentModals/ConfirmPurchase';
import USADetection from 'modules/payments/PaymentModals/USADetection';
import CryptoNotAvailable from 'modules/payments/PaymentModals/CryptoNotAvailable';
import CancelPurchase from 'modules/payments/PaymentModals/CancelPurchase';
import PurchaseExpired from 'modules/payments/PaymentModals/PurchaseExpired';
import ConnectWallet from 'modules/payments/PaymentModals/ConnectWallet';
import ConnectionSuccessful from 'modules/payments/PaymentModals/ConnectionSuccessful';
import InsufficientFunds from 'modules/payments/PaymentModals/InsufficentFunds';
import TryAgain from 'modules/payments/PaymentModals/TryAgain';
import { AddCryptoFundsStates } from 'modules/account/pages/components/Modals/AddCryptoFundsModal';
import QueueFull from 'modules/payments/PaymentModals/QueueFull';
import { useAppDispatch, useAppSelector } from 'hooks/reduxToolkit';
import { resetPersist } from 'store-persist/reducers/modalSlice';
import {
  setDataState,
  setModalType,
  setShowState,
  setExpiryTime,
} from 'store-persist/reducers/modalSlice';
import PurchaseTimer from './PurchaseTimer/PurchaseTimer';
import { CommissionType } from 'modules/library/types';

type BuyModalPaymentSelectionProps = {
  showModals: boolean;
  song: string;
  dai: string;
  artist: string;
  selectedBits: number;
  currentToastId?: string;
  costOfSelected: string;
  songPoster?: string;
  bitPrice: number;
  uri: string;
  nft: string;
  songId: string;
  commission: CommissionType;
  onClose: () => void;
  refetchSongProfile: () => void;
};

const BuyModalPaymentSelection: React.FC<BuyModalPaymentSelectionProps> = ({
  showModals,
  song,
  dai,
  artist,
  selectedBits,
  costOfSelected,
  songPoster,
  bitPrice,
  uri,
  nft,
  songId,
  onClose,
  refetchSongProfile,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { modalType, dataState, isReloaded, showState, expiryTime } =
    useAppSelector((state) => state.modalSlice);
  showModals;
  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [isPurchasePossible, setIsPurchasePossible] = useState<boolean>(false);

  const [balanceDetails, setBalanceDetails] = useState<BalanceDetails>({
    pendingBalance: '0.00',
    availableBalance: '0.00',
    settledBalance: '0.00',
  });
  setBalanceDetails;

  const [mostRecentCard, setMostRecentCard] = useState<CardSchema>({
    cardId: '',
    nickname: '',
    last4: '',
  });

  // Amount needed to add to funds when balance < cost of bits
  const [paymentAmount, setPaymentAmount] = useState<string>('');
  const [showQueueFullModal, setShowQueueFullModal] = useState<boolean>(false);

  // MODALS
  const [showConfirmPurchaseModal, setShowConfirmPurchaseModal] =
    useState<boolean>(false);

  const [showUSADetectionModal, setShowUSADetectionModal] =
    useState<boolean>(false);

  const [showCryptoNotAvailableModal, setShowCryptoNotAvailableModal] =
    useState<boolean>(false);

  const [restrictedCounty, setRestrictedCounty] = useState<string>('');

  const [showAddFundsModal, setShowAddFundsModal] = useState<boolean>(false);

  const [showPurchaseExpiredModal, setShowPurchaseExpiredModal] =
    useState<boolean>(false);

  const [showAddCardModal, setShowAddCardModal] = useState<{
    show: boolean;
    prevCard: string | undefined;
  }>({ show: false, prevCard: '' });

  const [showCardAutoModal, setShowCardAutoModal] = useState<boolean>(false);

  const [showCardPaymentModal, setShowCardPaymentModal] =
    useState<boolean>(false);

  const [showCardFailedModal, setShowCardFailedModal] =
    useState<boolean>(false);
  const [cardFailedText, setCardFailedText] = useState<string>('');

  const [showCryptoAutoModal, setShowCryptoAutoModal] =
    useState<boolean>(false);
  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);

  const [showConnectWalletModal, setShowConnectWalletModal] =
    useState<boolean>(false);

  const [showConnectionSuccessfulModal, setShowConnectionSuccessfulModal] =
    useState<boolean>(false);

  const [showInsufficientFundsModal, setShowInsufficientFundsModal] =
    useState<boolean>(false);

  const [showTryAgainModal, setShowTryAgainModal] = useState<boolean>(false);

  const [showAddOutgoingCryptoModal, setShowAddOutgoingCryptoModal] =
    useState<boolean>(false);

  const [showCancelPurchaseModal, setShowCancelPurchaseModal] =
    useState<boolean>(false);

  const [cost, setCost] = useState<string>(
    (selectedBits * bitPrice).toString(),
  );

  const [costWithFees, setCostWithFees] = useState<string>(
    (selectedBits * bitPrice).toString(),
  );
  costWithFees;

  type PurchaseSuccessType = {
    isOpen: boolean;
    type: SuccessModalType;
  };

  const [showPurchaseSuccessModal, setShowPurchaseSuccessModal] =
    useState<PurchaseSuccessType>({
      isOpen: false,
      type: SuccessModalType.Card,
    });

  type CryptoFundsModalProps = {
    show: boolean;
    state: AddCryptoFundsStates;
  };

  const [showAddCryptoFundsModal, setShowAddCryptoFundsModal] =
    useState<CryptoFundsModalProps>({
      show: false,
      state: AddCryptoFundsStates.PAYMENT,
    });

  useEffect(() => {
    if (isReloaded) {
      setCost(dataState.costOfSelected);
      if (
        modalType === 'AddFundsOptions' &&
        window.localStorage.getItem('purchaseId')
      ) {
        setShowAddFundsModal(true);
      }

      if (modalType === 'AddCryptoFunds') {
        setShowAddCryptoFundsModal(showState);
      }

      if (modalType === 'CryptoNotAvailable') {
        setShowCryptoNotAvailableModal(showState);
      }

      if (modalType === 'ConfirmPurchase') {
        setShowConfirmPurchaseModal(showState);
      }

      if (modalType === 'ConnectWallet') {
        setShowConnectWalletModal(showState);
      }
      if (modalType === 'ConnectionSuccessful') {
        setShowConnectionSuccessfulModal(showState);
      }
    }
  }, []);

  const clearPurchaseStore = useStoreActions(
    (state) => state.purchase.resetPurchaseData,
  );

  useEffect(() => {
    if (showConfirmPurchaseModal) {
      dispatch(setModalType('ConfirmPurchase'));
      dispatch(setShowState(showConfirmPurchaseModal));
      dispatch(
        setDataState({
          song,
          dai,
          artist,
          selectedBits,
          costOfSelected,
          songPoster,
          bitPrice,
          uri,
          nft,
          songId,
        }),
      );
    }
  }, [showConfirmPurchaseModal]);

  useEffect(() => {
    if (showConfirmPurchaseModal) {
      setCost((bitPrice * selectedBits).toString());
      dispatch(setModalType('ConfirmPurchase'));
      dispatch(setShowState(showConfirmPurchaseModal));
    }
  }, [showConfirmPurchaseModal]);

  /*useEffect(() => {
    const state = query.get('state');
    if (state === 'restore' && storedBits) {
      setCost('');
      setBits(+storedBits);
      setShowAddFundsModal(true);
      clearPurchaseStore();
    }
  }, []);*/

  useEffect(() => {
    initBalances();

    restService.getCardsList().then((res) => {
      const numCards = res.length;
      if (numCards > 0) {
        const card = res[0];
        const cardData = {
          cardId: card.cardid,
          nickname: card.nickname,
          last4: card.data.last4,
          verification_type: card.verification_type,
        };
        setMostRecentCard(cardData);
      }
    });
  }, []);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseHasExpired();
    } else if (!isReloaded) {
      setShowConfirmPurchaseModal(showModals);
    }
  }, [showModals]);

  async function initBalances() {
    setIsPurchasePossible(false);
    /*
    balanceText().then((data) => {
      setBalanceDetails({
        pendingBalance: data.pending,
        availableBalance: data.available,
        settledBalance: data.settled,
      });
      const result = parseFloat(costOfSelected) <= parseFloat(data.settled);
      setIsPurchasePossible(result);
    });
    */
  }
  /*
  async function balanceText() {
    const balances = await restService.getUserBalances().catch((error) => {
      setGlobalBanner({
        title: 'Failed to retrieve account balance:',
        text: error.message,
      });
    });
    return balances;
  }
  */

  async function purchaseHasExpired() {
    closeAllModals();
    window.localStorage.removeItem('purchaseId');
    setShowPurchaseExpiredModal(true);
  }

  function deductionText() {
    const price_decimal = parseFloat(costOfSelected).toFixed(2);
    return `This purchase will deduct $${price_decimal} from your SongBits balance`;
  }

  async function initiatePurchase() {
    setPurchaseLoading(true);
    // Reset purchase attempts
    window.localStorage.removeItem('purchase_attempt');

    const purchaseDetails: BitPurchaseSchema = {
      // userWallet: 'user1_wallet1',
      bitUUID: nft,
      numberOfBits: selectedBits,
      pricePaid: parseFloat(costOfSelected), // bit price?
    };

    // If not enough funds, simulate a fake purchase to reserve
    // selection for 15 mins

    if (!isPurchasePossible) {
      purchaseDetails.fakePurchase = true;
      const amountToPay = getFakePurchaseAmount(costOfSelected);
      purchaseDetails.paymentAmount = amountToPay;

      // Trigger a payment for the required amount
      setPaymentAmount(amountToPay);
    }

    restService
      .purchaseBit(purchaseDetails)
      .then((res) => {
        if (!isPurchasePossible) {
          window.localStorage.setItem('purchaseId', res.id);

          setPaymentAmount(res.calculated_fees.total);
          setCostWithFees(res.calculated_fees.total);
          setCost(res.calculated_fees.total);

          const data = {} as any;

          data.song = song;
          data.dai = dai;
          data.artist = artist;
          data.selectedBits = selectedBits;
          data.costOfSelected = res.calculated_fees.total;
          data.songPoster = songPoster;
          data.bitPrice = bitPrice;
          data.uri = uri;
          data.nft = nft;
          data.songId = songId;

          (window as any).gtag('event', 'purchase_confirm', {});

          (window as any).gtag('event', 'add_to_cart', {
            currency: 'USD',
            value: parseFloat(res.calculated_fees.total),
            shipping: parseFloat(res.calculated_fees.songbits_commission),
            items: [
              {
                item_id: songId,
                item_name: `${song}`,
                item_brand: `${artist}`,
                price: parseFloat(res.calculated_fees.total),
              },
            ],
          });

          dispatch(setDataState(data));

          const expiryTime = new Date();
          expiryTime.setMinutes(expiryTime.getMinutes() + 15);
          dispatch(setExpiryTime(expiryTime.getTime()));

          setShowAddFundsModal(true);
          setShowConfirmPurchaseModal(false);
          setPurchaseLoading(false);
        } else {
          setPurchaseLoading(false);
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Any,
          });
        }
      })
      .catch(function (error) {
        if (
          error.message === 'Too many bits selected for purchase' ||
          error.message === 'Too many bits selected for reservation'
        ) {
          setPurchaseLoading(false);
          setShowConfirmPurchaseModal(false);
          setShowQueueFullModal(true);
          return;
        } else if (error.message === 'Song sold out') {
          setPurchaseLoading(false);
          setShowConfirmPurchaseModal(false);
          navigate(location.pathname.replace('/bits', ''));
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
          setGlobalBanner({
            title: 'Sorry',
            text: 'it is now Sold Out!..',
          });
        } else if (error.message === 'Not for sale yet') {
          setPurchaseLoading(false);
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
          navigate(location.pathname.replace('/bits', ''));
          setGlobalBanner({
            title: 'Sorry',
            text: 'it is not for sale yet!..',
          });
        } else {
          setPurchaseLoading(false);
          setGlobalBanner({
            title: 'Purchase Failed',
            text: error.message,
          });
          return;
        }
      });
  }

  function cancelPurchase() {
    if (window.localStorage.getItem('purchaseId')) {
      restService
        .cancelPurchase({
          purchaseId: window.localStorage.getItem('purchaseId') || '',
        })
        .then((res) => {
          res;
          window.localStorage.removeItem('purchaseId');
          window.localStorage.removeItem('purchase_attempt');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          window.localStorage.removeItem('purchaseId');
          window.localStorage.removeItem('purchase_attempt');
        });
    }
  }

  /*
   * Used to determine the payment amount in the case where
   * available balance < cost of selected bits
   */
  function getFakePurchaseAmount(costOfBits: string) {
    if (parseFloat(balanceDetails.availableBalance) > 0) {
      // Balance > 0: Payment Amount = Cost of Bits - Available Balance
      return (
        parseFloat(costOfBits) - parseFloat(balanceDetails.availableBalance)
      ).toFixed(2);
    } else {
      // Balance <= 0: Payment Amount = 0 + Cost of Bits
      return (0 + parseFloat(costOfBits)).toFixed(2);
    }
  }

  function closeAllModals() {
    setShowUSADetectionModal(false);
    setShowCryptoNotAvailableModal(false);
    setShowConfirmPurchaseModal(false);
    setShowAddFundsModal(false);
    setShowAddCardModal({ show: false, prevCard: '' });
    setShowCardAutoModal(false);
    setShowCardPaymentModal(false);
    setShowCardFailedModal(false);
    setShowCryptoAutoModal(false);
    setShowConnectWalletModal(false);
    setShowConnectionSuccessfulModal(false);
    setShowAddOutgoingCryptoModal(false);
    setShowAddCryptoFundsModal({
      show: false,
      state: AddCryptoFundsStates.PAYMENT,
    });
    setShowInsufficientFundsModal(false);
    setShowTryAgainModal(false);
    setShowPurchaseSuccessModal({ isOpen: false, type: SuccessModalType.Card });
    clearPurchaseStore();
    setShowCancelPurchaseModal(false);
    window.localStorage.removeItem('purchaseId');
    dispatch(resetPersist());
    onClose();
  }

  function closeAllModalsSoft() {
    setShowUSADetectionModal(false);
    setShowCryptoNotAvailableModal(false);
    setShowConfirmPurchaseModal(false);
    setShowAddFundsModal(false);
    setShowAddCardModal({ show: false, prevCard: '' });
    setShowCardAutoModal(false);
    setShowCardPaymentModal(false);
    setShowCardFailedModal(false);
    setShowCryptoAutoModal(false);
    setShowConnectWalletModal(false);
    setShowConnectionSuccessfulModal(false);
    setShowAddOutgoingCryptoModal(false);
    setShowAddCryptoFundsModal({
      show: false,
      state: AddCryptoFundsStates.PAYMENT,
    });
    setShowInsufficientFundsModal(false);
    setShowTryAgainModal(false);
    setShowPurchaseSuccessModal({ isOpen: false, type: SuccessModalType.Card });
    setShowCancelPurchaseModal(false);
  }

  // GLOBAL FUNCTIONS
  useEffect(() => {
    const w = window as any;

    w.getCostOfSelected = function () {
      return cost;
    };

    w.hideAddCryptoModal = function () {
      setShowAddCryptoFundsModal({
        show: false,
        state: AddCryptoFundsStates.PAYMENT,
      });
    };

    w.onInitialWalletConnected = function () {
      setShowAddOutgoingCryptoModal(false);
      setShowCryptoAutoModal(true);
    };

    w.hideWalletConnectModal = function () {
      setShowAddOutgoingCryptoModal(false);
    };

    w.onPurchaseSuccess = function (type: SuccessModalType) {
      // Pass type in params
      dispatch(setExpiryTime(0));
      setShowPurchaseSuccessModal({ isOpen: true, type: type });
      setShowAddCryptoFundsModal({
        show: false,
        state: AddCryptoFundsStates.PAYMENT,
      });
    };

    w.switchToCardPayment = function () {
      setShowCardPaymentModal(true);
      setShowAddCryptoFundsModal({
        show: false,
        state: AddCryptoFundsStates.PAYMENT,
      });
    };

    w.setGlobalBanner = function ({
      title,
      text,
    }: {
      title: string;
      text: string;
    }) {
      setGlobalBanner({ title: title, text: text });
    };
  }, []);

  const renderHead = useCallback(() => {
    if (isReloaded && dataState) {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={dataState.artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={dataState.song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              {dataState.costOfSelected ? (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(costOfSelected).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              ) : (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(
                        dataState.selectedBits * dataState.bitPrice,
                      ).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              )}
            </SongDetails>
          </HeadContainer>
          {!showPurchaseSuccessModal.isOpen && !showCancelPurchaseModal && (
            <PurchaseTimer
              onZero={() => {
                if (!showPurchaseSuccessModal.isOpen) {
                  purchaseHasExpired();
                }
                refetchSongProfile();
              }}
            />
          )}
          {/* {getSelectionText()} */}
        </>
      );
    } else {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              <TextContent
                fontSize="fz24"
                fontWeight="light"
                text={
                  `` +
                  selectedBits.toLocaleString() +
                  ` bit${selectedBits === 1 ? '' : 's'}` +
                  ` | US$${Number(cost).toLocaleString()}` // TODO : Float?
                }
                fontColor={theme.colors.white}
              />
            </SongDetails>
          </HeadContainer>
          {/* {getSelectionText()} */}
          {!showPurchaseSuccessModal.isOpen && !showCancelPurchaseModal && (
            <PurchaseTimer
              onZero={() => {
                if (!showPurchaseSuccessModal.isOpen) {
                  purchaseHasExpired();
                }
                refetchSongProfile();
              }}
            />
          )}
        </>
      );
    }
  }, [
    artist,
    song,
    selectedBits,
    bitPrice,
    songPoster,
    dataState,
    isReloaded,
    cost,
    showCancelPurchaseModal,
  ]);

  const renderPurchaseHead = useCallback(() => {
    if (isReloaded && dataState) {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={dataState.artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={dataState.song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              {dataState.costOfSelected ? (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(costOfSelected).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              ) : (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(
                        dataState.selectedBits * dataState.bitPrice,
                      ).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              )}
            </SongDetails>
          </HeadContainer>
          {/* {getSelectionText()} */}
        </>
      );
    } else {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              <TextContent
                fontSize="fz24"
                fontWeight="light"
                text={
                  `` +
                  selectedBits.toLocaleString() +
                  ` bit${selectedBits === 1 ? '' : 's'}` +
                  ` | US$${Number(cost).toLocaleString()}`
                }
                fontColor={theme.colors.white}
              />
            </SongDetails>
          </HeadContainer>
          {/* {getSelectionText()} */}
        </>
      );
    }
  }, [artist, song, selectedBits, bitPrice, songPoster, dataState, isReloaded]);

  // added for linting
  dai;
  uri;

  return (
    <>
      <ConfirmPurchase
        isOpen={showConfirmPurchaseModal}
        purchaseLoading={purchaseLoading}
        isPurchasePossible={isPurchasePossible}
        initiatePurchase={initiatePurchase}
        deductionText={deductionText()}
        header={renderHead()}
        onClose={onClose}
      />

      <USADetection
        isOpen={showUSADetectionModal}
        onClose={() => {
          setShowUSADetectionModal(false);
          setShowAddFundsModal(true);
          //onClose()
        }}
        UDSCRestrictedState={(county) => {
          setRestrictedCounty(county);
          setShowUSADetectionModal(false);
          setShowCryptoNotAvailableModal(true);
        }}
        onWalletConnected={() => {
          setShowUSADetectionModal(false);
          setShowConnectionSuccessfulModal(true);
        }}
        onNoAvailableAddresses={() => {
          setShowUSADetectionModal(false);
          setShowAddCryptoFundsModal({
            show: true,
            state: AddCryptoFundsStates.CONNECT_METAMASK,
          });
        }}
        onMetaMaskNotInstalled={() => {
          setShowUSADetectionModal(false);
          setShowAddCryptoFundsModal({
            show: true,
            state: AddCryptoFundsStates.METAMASK_REQUIRED,
          });
        }}
        onBrowserNotSupported={() => {
          setShowUSADetectionModal(false);
          setShowAddCryptoFundsModal({
            show: true,
            state: AddCryptoFundsStates.BROWSER_INCOMPATIBLE,
          });
        }}
        ConnectWallet={() => {
          setShowUSADetectionModal(false);
          setShowConnectWalletModal(true);
        }}
      />

      <CryptoNotAvailable
        isOpen={showCryptoNotAvailableModal}
        onCardData={() => {
          setShowAddFundsModal(false);
          setShowCardAutoModal(true);
        }}
        onNoAvailableCards={initiatePurchase}
        country={restrictedCounty}
        onClose={() => {
          setShowCryptoNotAvailableModal(false);
          setShowAddFundsModal(true);
        }}
      />

      <AddFundsOptions
        isOpen={showAddFundsModal}
        type={PaymentModalType.Quickflow}
        header={renderHead()}
        artist={artist}
        costOfSelected={cost}
        selectedBits={selectedBits}
        song={song}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        purchaseExpired={() => {
          purchaseHasExpired();
        }}
        onNoAvailableCards={() => {
          setShowAddFundsModal(false);
          setShowCardAutoModal(true);
          //setShowAddCardModal(true);
        }}
        onWalletConnected={() => {
          setShowAddFundsModal(false);
          setShowConnectionSuccessfulModal(true);
        }}
        onNoAvailableAddresses={() => {
          setShowAddFundsModal(false);
          setShowAddCryptoFundsModal({
            show: true,
            state: AddCryptoFundsStates.CONNECT_METAMASK,
          });
        }}
        onMetaMaskNotInstalled={() => {
          setShowAddFundsModal(false);
          setShowAddCryptoFundsModal({
            show: true,
            state: AddCryptoFundsStates.METAMASK_REQUIRED,
          });
        }}
        onUSACountryDetected={() => {
          setShowUSADetectionModal(true);

          setShowAddFundsModal(false);
        }}
        onBrowserNotSupported={() => {
          setShowAddFundsModal(false);
          setShowAddCryptoFundsModal({
            show: true,
            state: AddCryptoFundsStates.BROWSER_INCOMPATIBLE,
          });
        }}
        next={({
          state,
          payload,
        }: {
          state: QuickFlowStates;
          payload?: CardSchema;
        }) => {
          if (state === QuickFlowStates.CardAuto) {
            if (payload) {
              setMostRecentCard(payload);
            }
            setShowAddFundsModal(false);
            setShowCardAutoModal(true);
          }
          if (state === QuickFlowStates.CryptoAuto) {
            setShowAddFundsModal(false);
            setShowCryptoAutoModal(true);
          }
          if (state === QuickFlowStates.ConnectWallet) {
            setShowAddFundsModal(false);
            setShowConnectWalletModal(true);
          }
        }}
      />

      <CardAuto
        isOpen={showCardAutoModal}
        header={renderHead()}
        cost={cost}
        mostRecentCard={mostRecentCard}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onCardPayment={() => {
          setShowCardPaymentModal(true);
        }}
        onPaymentComplete={() => {
          setShowCardAutoModal(false);
          // Update balance
          initBalances();
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Card,
          });

          window.localStorage.removeItem('purchase_attempt');

          const payment_type = 'card';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
        onPaymentFail={(text) => {
          setShowCardAutoModal(false);
          setCardFailedText(text);
          setShowCardFailedModal(true);
        }}
        onStartAgain={() => {
          cancelPurchase();
          refetchSongProfile();
          closeAllModals();
        }}
        onAddCard={(previousCard?: string) => {
          setShowAddCardModal({ show: true, prevCard: previousCard });
        }}
        purchaseExpired={() => {
          purchaseHasExpired();
        }}
      />

      <AddCard
        isOpen={showAddCardModal.show}
        type={PaymentModalType.Quickflow}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        changePaymentMethod={() => {
          setShowAddCardModal({ show: false, prevCard: '' });
          setShowCardAutoModal(false);
          setShowAddFundsModal(true);
        }}
        onCardAdded={(card: CardSchema) => {
          setMostRecentCard(card);
          if (showAddCardModal.prevCard) {
            restService.deleteCard(showAddCardModal.prevCard).catch((error) => {
              error;
            });
          }
          setShowAddCardModal({ show: false, prevCard: '' });
          // setShowCardPaymentModal(true);
          setShowCardAutoModal(true);
        }}
      />

      <CardPayment
        isOpen={showCardPaymentModal}
        type={PaymentModalType.Quickflow}
        mostRecentCard={mostRecentCard}
        paymentAmount={paymentAmount}
        onClose={() => {
          setShowCardPaymentModal(false);
        }}
        onPaymentComplete={() => {
          dispatch(setExpiryTime(0));
          setShowCardPaymentModal(false);
          // Update balance
          initBalances();
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Card,
          });

          const payment_type = 'card';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
        onPaymentFail={() => {
          setShowCardPaymentModal(false);
          setShowCardFailedModal(true);
        }}
        onAddCard={() => {
          setShowCardPaymentModal(false);
          setShowAddCardModal({ show: true, prevCard: '' });
        }}
      />

      <CardPaymentFailed
        isOpen={showCardFailedModal}
        header={renderHead()}
        failedText={cardFailedText}
        onRetryCard={() => {
          setShowCardFailedModal(false);
          setShowCardAutoModal(true);
        }}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onChangePaymentMethod={() => {
          closeAllModalsSoft();
          setShowAddFundsModal(true);
        }}
      />

      <AddOutgoingCrypto isOpen={showAddOutgoingCryptoModal} />
      <AddCryptoFunds
        openedFrom="purchase"
        header={renderHead()}
        isOpen={showAddCryptoFundsModal.show}
        state={showAddCryptoFundsModal.state}
        onClose={() => {
          setShowCancelPurchaseModal(true);
          /*setShowAddCryptoFundsModal({
            show: false,
            state: AddCryptoFundsStates.PAYMENT,
          });*/
        }}
        onPaymentComplete={() => {
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Crypto,
          });
          setShowAddCryptoFundsModal({
            show: false,
            state: AddCryptoFundsStates.PAYMENT,
          });

          const payment_type = 'crypto';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
        onError={() => {
          setShowCardPaymentModal(true);
          setShowAddCryptoFundsModal({
            show: false,
            state: AddCryptoFundsStates.PAYMENT,
          });
        }}
        onNoAvailableCards={() => {
          setShowAddCryptoFundsModal({
            show: false,
            state: AddCryptoFundsStates.PAYMENT,
          });
          setShowAddCardModal({ show: true, prevCard: '' });
        }}
        onUseCard={() => {
          setShowAddCryptoFundsModal({
            show: false,
            state: AddCryptoFundsStates.PAYMENT,
          });
          setShowCardAutoModal(true);
        }}
        onCardData={() => {
          setShowAddCryptoFundsModal({
            show: false,
            state: AddCryptoFundsStates.PAYMENT,
          });
          setShowCardAutoModal(true);
          //setShowCardPaymentModal(true);
        }}
        switchState={(new_state) => {
          setShowAddCryptoFundsModal({
            show: true,
            state: new_state,
          });
        }}
        purchaseExpired={() => {
          purchaseHasExpired();
        }}
      />

      <ConnectWallet
        isOpen={showConnectWalletModal}
        header={renderHead()}
        onClose={() => {
          setShowConnectWalletModal(false);
        }}
        costOfSelected={cost}
        onConnected={() => {
          setShowConnectWalletModal(false);
          setShowConnectionSuccessfulModal(true);
        }}
      />

      <ConnectionSuccessful
        isOpen={showConnectionSuccessfulModal}
        header={renderHead()}
        costOfSelected={cost}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onMetaMaskCancel={() => {
          setShowConnectionSuccessfulModal(false);
          setShowTryAgainModal(true);
        }}
        onInsufficientFunds={() => {
          setShowConnectionSuccessfulModal(false);
          setShowInsufficientFundsModal(true);
        }}
        onPaymentComplete={() => {
          setShowConnectionSuccessfulModal(false);
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Crypto,
          });

          const payment_type = 'crypto';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
      />

      <InsufficientFunds
        isOpen={showInsufficientFundsModal}
        header={renderHead()}
        costOfSelected={cost}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onMetaMaskCancel={() => {
          setShowConnectionSuccessfulModal(false);
          setShowTryAgainModal(true);
        }}
        onPaymentComplete={() => {
          setShowConnectionSuccessfulModal(false);
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Crypto,
          });

          const payment_type = 'crypto';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
      />

      <TryAgain
        isOpen={showTryAgainModal}
        header={renderHead()}
        costOfSelected={cost}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onPaymentComplete={() => {
          setShowConnectionSuccessfulModal(false);
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Crypto,
          });

          const payment_type = 'crypto';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
      />

      <CryptoAuto
        isOpen={showCryptoAutoModal}
        header={renderHead()}
        balanceDetails={balanceDetails}
        costOfSelected={cost}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onUseCard={() => {
          setShowCryptoAutoModal(false);
          setShowCardAutoModal(true);
        }}
        onError={() => {
          setShowCryptoAutoModal(false);
          setShowCardAutoModal(true);
        }}
        onPaymentComplete={() => {
          setShowCryptoAutoModal(false);
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Crypto,
          });

          const payment_type = 'crypto';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
        purchaseExpired={() => {
          purchaseHasExpired();
          setShowPurchaseExpiredModal(true);
        }}
      />

      <CancelPurchase
        isOpen={showCancelPurchaseModal}
        header={renderHead()}
        onClose={() => {
          //setShowCancelPurchaseModal(false);
        }}
        onResume={() => {
          setShowCancelPurchaseModal(false);
        }}
        onChangePaymentMethod={() => {
          closeAllModalsSoft();
          setShowAddFundsModal(true);
        }}
        onCancel={() => {
          cancelPurchase();
          refetchSongProfile();
          closeAllModals();
        }}
      />

      <PurchaseSuccess
        isOpen={showPurchaseSuccessModal.isOpen}
        header={renderHead()}
        type={showPurchaseSuccessModal.type}
        onSuccess={() => {
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
        }}
        onClose={() => {
          refetchSongProfile();
          closeAllModals();
          setShowPurchaseSuccessModal({
            isOpen: false,
            type: SuccessModalType.Card,
          });
        }}
      />

      <PurchaseExpired
        isOpen={showPurchaseExpiredModal}
        header={renderPurchaseHead()}
        onClose={() => {
          refetchSongProfile();
          closeAllModals();
          window.localStorage.removeItem('purchase_attempt');
          window.localStorage.removeItem('purchaseId');
          setShowPurchaseExpiredModal(false);
        }}
      />

      <QueueFull
        isOpen={showQueueFullModal}
        artist={artist}
        song={song}
        image={songPoster}
        onRefresh={() => {
          refetchSongProfile();
          window.location.reload();
        }}
        onClose={() => {
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
          navigate('/');
        }}
      />
    </>
  );
};

const HeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  text-decoration: inherit;
`;

const SongPosterContainer = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

const SongDetails = styled.div`
  width: 280px;
  height: 110px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'HKGrotesk-Black';
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export default BuyModalPaymentSelection;
