import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import theme from 'theme/theme';
import TextInput from 'common/components/TextInput/TextInput';
import Yup from 'yup-extended';
import { useFormik } from 'formik';

type FirstTimeAuthStages = 'CodeProvided' | 'ProvideCode' | 'Success';

interface TwoFaFirstAuthProps {
  onHide: () => void;
  code: string;
}

const TwoFaFirstAuth: FC<TwoFaFirstAuthProps> = ({ onHide, code }) => {
  const [confirmStage, setConfirmSatge] =
    useState<FirstTimeAuthStages>('CodeProvided');

  const VALIDATION_SCHEMA = Yup.object().shape({
    code: Yup.string().test('match', 'Incorrect', (codeTest) => {
      return codeTest === code;
    }),
  });

  const initialValues = useMemo<any>(
    () => ({
      code: '',
    }),
    [],
  );

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  function submitHandler() {
    setConfirmSatge('Success');
  }

  return (
    <CustomComponentContainer>
      <Spacer height={15} />
      {confirmStage === 'CodeProvided' && (
        <>
          <Typography
            text={
              <>
                In the event that you loose your device containing your
                authenticator, you will need to keep the following one time
                passcode in a <RedText>safe place</RedText> (not on your device
                with the authenticator).
              </>
            }
            lineHeight="24px"
          />
          <Spacer height={20} />
          <Typography
            text="Take a note of your One Tme Passcode:"
            fontColor={theme.colors.yellow}
            lineHeight="24px"
          />
          <Typography text={code} lineHeight="24px" />
          <Spacer height={20} />
          <ButtonsContainer>
            <FirstButton
              height={45}
              width={226}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="verify"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                  &nbsp;
                  <ContentButton
                    text="code"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => setConfirmSatge('ProvideCode')}
            />
          </ButtonsContainer>
        </>
      )}

      {confirmStage === 'ProvideCode' && (
        <>
          <Typography
            text={
              <>
                <RedText>WARNING:</RedText> SongBits support does not have
                access to this code and canot recover your account in the event
                you loose both your device that you have setup authentication on
                and this one time passcode!
              </>
            }
            lineHeight="24px"
          />
          <Spacer height={20} />

          <Typography
            text="Enter your One Time Passcode:"
            fontColor={theme.colors.yellow}
            fontWeight="light"
            lineHeight="24px"
          />
          <TextInput
            value={values.code}
            placeholder="Enter Passcode"
            onChange={handleChange('code')}
          />

          {errors.code && touched && (
            <ErrorContainer>
              <Typography
                text="Incorrect!"
                fontColor={theme.colors.red}
                fontSize="fz14"
                fontWeight="light"
                lineHeight="24px"
              />
              &nbsp;
              <Link 
                onClick={() => {setConfirmSatge('CodeProvided')}}>
              <Typography
                text="Return to code?"
                fontColor={theme.colors.yellow}
                fontSize="fz14"
                fontWeight="light"
                lineHeight="24px"
              />
              </Link>
            </ErrorContainer>
          )}

          <Spacer height={20} />
          <ButtonsContainer>
            <FirstButton
              height={45}
              width={200}
              borderRadius={50}
              borderColor={theme.colors.gray}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="back to code"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.gray}
                  />
                </div>
              }
              onClick={() => setConfirmSatge('CodeProvided')}
            />

            <FirstButton
              height={45}
              width={226}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="verify"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                  &nbsp;
                  <ContentButton
                    text="code"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={handleSubmit}
            />
          </ButtonsContainer>
        </>
      )}

      {confirmStage === 'Success' && (
        <>
          <Typography
            text="Success"
            lineHeight="24px"
            fontColor={theme.colors.yellow}
          />

          <Spacer height={20} />

          <Typography
            text="You have completed two factor authentication setup."
            lineHeight="24px"
          />

          <Spacer height={20} />

          <Typography
            text="To disable two factor authentication, refer to the instructions in Account Settings"
            lineHeight="24px"
          />

          <Spacer height={60} />

          <FirstButton
            height={45}
            width={226}
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="close" fontSize="fz16" fontWeight="bold" />
              </div>
            }
            onClick={() => onHide()}
          />
        </>
      )}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RedText = styled.span`
  color: ${theme.colors.red};
`;

const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FirstButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 6px;
`;

const Link = styled.a`
  cursor: pointer;

`;

export default TwoFaFirstAuth;
