export type UPDATE_USER_BY_ID_MODEL = {
  clientMutationId: string;
};

export type UPDATE_USER_BY_ID_MUTATION = {
  updateUserById: UPDATE_USER_BY_ID_MODEL;
};

export type UPDATE_USER_BY_ID_VARIABLES = {
  id: string;
  username?: string;
  email?: string;
  location?: string;
  avatarId?: string | null;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
  tiktok?: string;
  myCollectionPublic?: boolean;
  tutorialClosed?: boolean;
};

export type GET_USER_BY_ID_QUERY = {
  userById: {
    email: string;
    location: string;
    slug: string;
    username: string;
    fileByAvatarId: {
      url: string;
      id: string;
    };
    facebook: string;
    instagram: string;
    twitter: string;
    youtube: string;
    tiktok: string;
    myCollectionPublic: boolean;
    mfa: string | undefined;
  };
};

export type GET_USER_BY_ID_VARIABLES = {
  userId: string;
};

export type USER_UPDATE_PASSWORD_MUTATION = {
  userUpdatePassword: UPDATE_USER_BY_ID_MODEL;
};

export type USER_UPDATE_PASSWORD_VARIABLES = {
  userid: string;
  currentPassword: string;
  newPassword: string;
};

export type USER_SEND_RESET_PASSWORD_MUTATION = {
  userSendResetPassword: { responseType: { message: string } };
};

export type USER_SEND_RESET_PASSWORD_VARIABLES = {
  emailValue: string;
};

export enum ErrorsResponses {
  password = 'Current password is invalid',
}

export type SocialMediaTypes = {
  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
  tiktok?: string;
};

import { MusicPlatformsTypes } from 'modules/artist/types';

export type DropsDataType = {
  name: string;
  date: string;
  editions: string;
  songType?: string;
  sold: string;
  cover: string;
  status: string;
  link: string;
  dropSummaryLink?: string;
};

export type UserDataType = {
  id: string;
  artistName: string;
  avatar: string;
  location: string;
  intro: string;
  slug: string;
  isArtist: boolean;
  createdAt?: string;
  socialMedia: SocialMediaTypes;
  musicLinks?: MusicPlatformsTypes;
  Drops: DropsDataType[] | [];
  MyCollection: MyCollectionDataType[] | [];
  myCollectionPublic: number;
  combinedFollowerCount?: string;
  userLikes?: any;
};

export type MyCollectionDataType = {
  name: string;
  date: string;
  noBits: string;
  cover: string;
  blockexplorer: string;
  link: string;
};

export type NFTOptionsModalType = 'export' | 'image';

export type ExportModalType = 'royalty' | 'image';

export type ExportNFTImageType = {
  id: string;
  code: string;
};
