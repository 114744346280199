import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import CancelPurchaseModal from 'modules/account/pages/components/Modals/CancelPurchaseModal';
import { getTopPosition } from 'utils/functions';
// import ReactGA from 'react-ga4';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onChangePaymentMethod: () => void;
  onResume: () => void;
  header: JSX.Element;
}

const CancelPurchase: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onChangePaymentMethod,
  onCancel,
  onResume,
  header,
}) => {
  useEffect(() => {
    if (isOpen) {
      (window as any).gtag("event", "purchase_cancel", {});
    }
  }, [isOpen]);

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top={getTopPosition('10%')}
      mobileTop="2%"
      withProceedingText={true}
      withFeeText={true}
      feeBottomPos="60px"
      withSettings={false}
      content={
        <CancelPurchaseModal
          onClose={onClose}
          onChangePaymentMethod={onChangePaymentMethod}
          onCancel={() => {
            onCancel();
          }}
          onResume={onResume}
          header={header}
        />
      }
    />
  );
};

export default memo(CancelPurchase);
