import React, { FC } from 'react';
import styled from 'styled-components';

import DefaultButton from 'common/components/DefautButton/DefaultButton';
import Typography from 'common/components/Typography/Typography';
import ArrowCircleYellowIcon from 'common/icons/ArrowCircleYellow.icon';

import restService from 'services/rest.service';

interface BenefitItemProps {
  leftIcon: JSX.Element;
  title: string;
  content: string | JSX.Element;
  originaltransactionid?: string;
  fileName?: string;
  buttons: {
    text: string;
    link?: string;
    download_type?: string;
    onClick: () => void;
  }[];
  hideBottom?: boolean;
}

const BenefitItem: FC<BenefitItemProps> = ({
  leftIcon,
  title,
  originaltransactionid,
  fileName,
  buttons,
  content,
  hideBottom = false,
}) => {


  function downloadNFTImage(originaltransactionid: string, fileName: string) {
      restService.downloadNFTImage(originaltransactionid)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName + '.jpeg');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          error;
        });
  }

  function downloadMP3(originaltransactionid: string, fileName: string) {

      restService
        .downloadMP3(originaltransactionid)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName + '.mp3');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          error;
        });

  }

  return (
    <>
      <Container hideBottom={hideBottom}>
        <LeftIconContainer>{leftIcon}</LeftIconContainer>
        <ContentContainer>
          <DescriptionContainer>
            <Typography
              text={title.toLowerCase()}
              fontWeight="bold"
              fontSize="fz30"
            />
            {typeof content === 'string' ? (
              <Typography
                text={content}
                fontWeight="light"
                fontSize="fz16"
                lineHeight="20px"
                letterSpacing="-0.03em"
              />
            ) : (
              <>{content}</>
            )}
          </DescriptionContainer>

          <ButtonsContainer>
            {buttons.map((buttonElement, index) => (
              <>
                {buttonElement.link ? (
                  <DownloadLink
                    href={buttonElement.link}
                    download={buttonElement.link}
                    target="_blank"
                    rel="noreferrer">
                    <Button key={index} onClick={buttonElement.onClick}>
                      <ArrowCircleYellowIcon />
                      <ButtonText fontWeight="bold" text={buttonElement.text} />
                    </Button>
                  </DownloadLink>
                ) : (
                  <>
                    {buttonElement?.download_type ? (
                      <>
                        {buttonElement?.download_type === 'mp3_download' ? (
                          <>
                            <Button key={index} onClick={() => {downloadMP3(originaltransactionid || '', fileName || '')}}>
                              <ArrowCircleYellowIcon />
                              <ButtonText
                                fontWeight={'bold'}
                                text={buttonElement.text}
                              />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button key={index} onClick={() => {downloadNFTImage(originaltransactionid || '', fileName || '')}}>
                              <ArrowCircleYellowIcon />
                              <ButtonText
                                fontWeight={'bold'}
                                text={buttonElement.text}
                              />
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Button key={index} onClick={buttonElement.onClick}>
                          <ArrowCircleYellowIcon />
                          <ButtonText
                            fontWeight={'bold'}
                            text={buttonElement.text}
                          />
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          </ButtonsContainer>
        </ContentContainer>
      </Container>
      {!hideBottom ? <BottomLine /> : <></>}
    </>
  );
};

export const Container = styled.div<{ hideBottom?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 30px 0;
`;

const LeftIconContainer = styled.div`
  display: flex;
  justify-content: center;

  min-width: 35px;
  margin-top: 1px;
`;

export const ContentContainer = styled.div`
  min-height: 125px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  margin-top: 15px;
`;

const Button = styled(DefaultButton)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`;

const ButtonText = styled(Typography)`
  white-space: nowrap;
`;

const DownloadLink = styled.a`
  text-decoration: none;
`;

const BottomLine = styled.div`
  bottom: 0;
  height: 1px;
  margin-top: 10px;
  width: 100%;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export default BenefitItem;
