import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import CryptoNotAvailableModal from 'modules/account/pages/components/Modals/CryptoNotAvailableModal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCardData: () => void;
  onNoAvailableCards: () => void;
  country: string;
}

const CryptoNotAvailable: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onCardData,
  onNoAvailableCards,
  country,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(setModalType('CryptoNotAvailable'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height="640px"
      mobileHeight="520px"
      top="10%"
      mobileTop="2%"
      withSettings={false}
      withProceedingText={true}
      content={
        <CryptoNotAvailableModal
          onClose={onClose}
          onCardData={onCardData}
          onNoAvailableCards={onNoAvailableCards}
          country={country}
        />
      }
    />
  );
};

export default memo(CryptoNotAvailable);
