import { action } from 'easy-peasy';

import { LocationModel } from './locationModel.types';

export const location: LocationModel = {
  previousUrl: '',
  setPreviousUrl: action((state, payload) => {
    state.previousUrl = payload;
  }),
  breadcrumbs: [],
  setBreadcrumbs: action((state, payload) => {
    state.breadcrumbs = payload;
  }),
};
