import React, { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import Yup from 'yup-extended';
import styled from 'styled-components';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import restService from 'services/rest.service';

interface TwoFaRecoverModalProps {
  onHide: () => void;
}

const TwoFaRecoverModal: FC<TwoFaRecoverModalProps> = ({ onHide }) => {
  const VALIDATION_SCHEMA = Yup.object().shape({
    code: Yup.string().required('Incorrect!'),
  });

  const initialValues = useMemo<any>(
    () => ({
      code: '',
    }),
    [],
  );


  const [loading, setLoading] = useState<boolean>(false);

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {

    setLoading(true)

    await restService
      .remove2FA(values.code)
      .then((data) => {
        data
        onHide();
      })
      .catch((err) => {
        err
        errors.code = 'Incorrect!'
        setLoading(false)
      });
  }

  return (
    <TwoFaContainer>
      <CloseButton onClick={onHide}>X</CloseButton>
      <Typography
        text="two factor authentication"
        fontSize="fz48"
        fontWeight="bold"
      />

      <Spacer height={20} />

      <Typography
        text="Recover your account using your one time password you took a note of when securing your account with two factor authentication."
        lineHeight="24px"
        fontWeight="light"
      />

      <Spacer height={20} />

      <Typography
        text="Enter your One Time Passcode:"
        fontColor={theme.colors.yellow}
        fontWeight="light"
        lineHeight="24px"
      />
      <Spacer height={5} />
      <CodeTextInput
        value={values.code}
        placeholder="Enter Passcode"
        onChange={handleChange('code')}
        
      />

      {errors.code && (
        <ErrorContainer>
          <Typography
            text={<>{errors.code}</>}
            fontColor={theme.colors.red}
            fontSize="fz14"
            fontWeight="light"
            lineHeight="24px"
          />
        </ErrorContainer>
      )}

      <Spacer height={25} />

      <FirstButton
        height={45}
        width={226}
        borderRadius={50}
        borderColor={theme.colors.white}
        isLoading={loading}
        label={
          <div style={{ display: 'flex' }}>
            <ContentButton text="verify" fontSize="fz16" fontWeight="bold" />
            &nbsp;
            <ContentButton
              text="code"
              fontSize="fz16"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
            />
          </div>
        }
        onClick={handleSubmit}
      />
    </TwoFaContainer>
  );
};

const TwoFaContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const ErrorContainer = styled.div`
  margin-left: 6px;
`;

const FirstButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const CodeTextInput = styled(TextInput)`

  & > input {

  padding-left: 0;

  }
`;

export default TwoFaRecoverModal;
