import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserById($userId: UUID!) {
    userById(id: $userId) {
      email
      location
      slug
      username
      fileByAvatarId {
        url
        id
      }
      facebook
      instagram
      twitter
      youtube
      tiktok
      myCollectionPublic
      tutorialClosed
    }
  }
`;

export const GET_USERS_TUTORIAL_STATE = gql`
  query UserTutorialState($id: UUID!) {
    userById(id: $id) {
      tutorialClosed
    }
  }
`;
