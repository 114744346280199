import { createStore, createTypedHooks } from 'easy-peasy';

import model from './models/mainModel';
import { StoreModel } from './models/mainModel.types';

const { useStoreActions, useStoreState, useStoreDispatch } =
  createTypedHooks<StoreModel>();

export { useStoreActions, useStoreDispatch, useStoreState };

const store = createStore(model);

export default store;
