import * as React from 'react';

import { IconProps } from '../common.types';

const Card = ({ width = 63, height = 48 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.846 0C2.17 0 0 2.148 0 4.8v2.4h63V4.8C63 2.148 60.831 0 58.154 0H4.846ZM0 14.4v28.8C0 45.852 2.169 48 4.846 48h53.308C60.83 48 63 45.852 63 43.2V14.4H0Zm7.27 4.8h19.384v2.4H7.269v-2.4Z"
      fill="#FFD600"
    />
  </svg>
);

export default Card;
