import React, { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import restService from 'services/rest.service';

import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import theme from 'theme/theme';
import * as Yup from 'yup';

interface TwoFaAuthCode {
  onCodeSubmit: (code: string) => void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  code: Yup.string()
    .min(6, 'code is too short. must be 6-7 chars')
    .max(7, 'code is too long. must be 6-7 chars')
    .required('code is required'),
});

const TwoFaAuthCode: FC<TwoFaAuthCode> = ({ onCodeSubmit }) => {
  const initialValues = useMemo<any>(
    () => ({
      code: '',
    }),
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {
    setLoading(true);

    await restService
      .post2FA(values.code.replace(' ', ''))
      .then((response) => {
        onCodeSubmit(response.backup_code);
        setLoading(false);
      })
      .catch((error) => {
        error;
        errors.code = 'Invalid code';
        setLoading(false);
      });
  }

  return (
    <TwoFaContainer>
      <Spacer height={25} />

      <Typography
        text="Enter the code for SongBits from the authenticator app on your device"
        fontWeight="light"
        lineHeight={'24px'}
      />

      <Spacer height={70} />

      <InputContainer>
        <Typography text="Code: " fontWeight="light" />
        <TextInput
          value={values.code}
          placeholder="Enter code"
          onChange={handleChange('code')}
          errorPoistionBottom={'-20px'}
          errorPoistionLeft={'5px'}
          error={touched.code && errors.code ? errors.code?.toString() : ''}
        />
      </InputContainer>

      <Spacer height={35} />

      <FirstButton
        height={45}
        width={226}
        borderRadius={50}
        borderColor={theme.colors.white}
        isLoading={loading}
        label={
          <div style={{ display: 'flex' }}>
            <ContentButton text="sumbit" fontSize="fz16" fontWeight="bold" />
            &nbsp;
            <ContentButton
              text="code"
              fontSize="fz16"
              fontColor={theme.colors.yellow}
              fontWeight="bold"
            />
          </div>
        }
        onClick={handleSubmit}
      />
    </TwoFaContainer>
  );
};

const TwoFaContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FirstButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

export default TwoFaAuthCode;
