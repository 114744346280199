import React, { useMemo, FC, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import theme from 'theme/theme';
import MetamaskClient from 'services/web3.client';
import { PaymentModalType } from 'modules/payments/types';
import { useStoreActions } from 'store/store';

interface ModalProps {
  onChange: () => void;
  type: PaymentModalType;
  onConnected: () => void;
  noClose?: boolean;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  addressNickname: Yup.string().min(3).required('Nickname min length 3'),
});

const AddOutgoingCryptoModal: FC<ModalProps> = ({
  onChange,
  onConnected,
}) => {
  const [stateConnectedAccount, setStateConnectedAccount] =
    useState<boolean>(false);
  const [connectedAddress, setConnectedAddress] = useState<string>('');
  const [connectedChain, setConnectedChain] = useState<string>('');
  stateConnectedAccount;
  connectedAddress;
  connectedChain

  const metamaskClient = new MetamaskClient(process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID);

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const initialValues = useMemo(
    () => ({
      addressNickname: '',
    }),
    [],
  );

  const { values, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {
    if (values.addressNickname.length >= 3) {
      metamaskClient
        .connect(values.addressNickname)
        .then(() => {
          setStateConnectedAccount(true);
          setConnectedAddress(metamaskClient.connected_wallet);
          setConnectedChain(metamaskClient.chain);
          onConnected();
        })
        .catch((error) => {
          console.error(error);
          setStateConnectedAccount(false);
          setConnectedAddress('');
          setConnectedChain('');
          setGlobalBanner({
            title: 'Error',
            text: error.message
          });
        });
    }
  }

  function handleClose() {
    onChange();
  }

  function connect_button() {
    return (
      <div style={{ width: '100%' }}>
        <InputContainer>
          <Spacer height={40} />
          <SectionText text="Create a wallet nickname" />
          <InputWithIcon
            value={values.addressNickname}
            height={70}
            type="text"
            onChange={handleChange('addressNickname')}
            placeholder="Enter nickname for your wallet"
            error={
              Boolean(errors.addressNickname && touched.addressNickname)
                ? errors.addressNickname
                : undefined
            }
          />
          <WalletButton
            height={45}
            borderRadius={50}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            label={
              <TextContent
                text="Connect to Wallet"
                fontSize="fz16"
                fontWeight="bold"
                fontColor={theme.colors.black}
              />
            }
            onClick={submitHandler}
          />
          <Spacer height={20} />
        </InputContainer>
      </div>
    );
  }

  function get_content() {
    return connect_button();
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText text="wallet name" fontWeight="bold" fontSize="fz48" />
      <Spacer height={20} />

      {get_content()}

    </CustomComponentContainer>
  );
};

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const InputWithIcon = styled(TextInput)`
  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  left: 350px;
  font-size: 20px;
  cursor: pointer;
`;

const CustomComponentContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -10px;
  padding-left: 15px;
`;

const WalletButton = styled(Button)`
  padding: 0 114px;
`;

const SectionText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 24px;
  color: ${theme.colors.yellow};
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export default AddOutgoingCryptoModal;
