import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import { md, xs } from 'utils/constants';

export const BackgroundImageContainer = styled.div`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: initial;
  }
`;

export const Image = styled.img`
  visibility: visible;
  object-fit: cover;
  height: 100vh;
  width: 100vw;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 60px;

  @media (min-width: 576px) {
    padding-bottom: 0px;
  }

  @media (min-width: 768px) {
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (min-width: 992px) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    // padding-top: 300px;
    padding-bottom: 0px;
  }

  @media (min-width: 1200px) {
    // padding-top: 350px;
    padding-bottom: 0px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${md}px) {
    flex-direction: row;
  }
`;

export const SongBannerContainer = styled.div`
  margin-top: 0px;

  @media (min-width: 576px) {
    margin-top: 0;
    margin-bottom: 40px;
    position: relative;
  }
`;

export const SongImage = styled.img<{
  playing?: boolean;
}>`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 10px solid #ffd600;
  /*filter: drop-shadow(0px 4px 250px #ffd600);*/

  ${(props) => props.playing && `filter: brightness(60%);`};
  
  @media (min-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @media (min-width: 1200px) {
    width: 342px;
    height: 342px;
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  width: 200px;
  height: 200px;
  z-index: 3;
  cursor: pointer;

  padding: 0;
  margin: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0);

  @media (min-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @media (min-width: 1200px) {
    width: 342px;
    height: 342px;
  }
`;

export const PlayImage = styled.img`
  height: 48px;

  @media (min-width: 768px) {
    height: 80px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  z-index: 2;

  @media (max-width: 576px) {
    justify-content: initial;
    max-width: 100vw;
  }

  @media (min-width: 992px) {
    padding: 0 33px;
    align-items: normal;
  }
`;

export const StrapLineTypographyLarge = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 48px;
  text-align: center;

  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 20px;

  @media (min-width: 768px) {
    font-size: 60px;
    line-height: 48px;
    padding: 20px;
    text-align: start;
  }

  @media (min-width: 992px) {
    font-size: 80px;
    line-height: 64px;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 575px;
  }

  @media (min-width: 1200px) {
    font-size: 100px;
    line-height: 113px;
    margin-bottom: 10px;
    margin-left: 15px;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 48px;
  text-align: center;

  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 20px;

  @media (min-width: 768px) {
    font-size: 60px;
    line-height: 70px;
    padding: 20px;
    text-align: start;
  }

  @media (min-width: 992px) {
    font-size: 80px;
    line-height: 90px;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 575px;
  }

  @media (min-width: 1200px) {
    font-size: 100px;
    line-height: 113px;
    margin-bottom: 20px;
    margin-left: 15px;
  }
`;

export const SongMainInfo = styled.p`
  display: flex;
  align-items: center;
  font-family: 'HKGrotesk-Black';

  background-color: #000000;
  width: fit-content;
  padding: 6px 13px;

  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 25px;
  }

  @media (min-width: 992px) {
    font-size: 30px;
    margin-left: 30px;
  }

  @media (min-width: 1200px) {
    font-size: 36px;
  }
`;

export const SongExtraInfo = styled.div`
  background-color: #000000;
  padding: 6px 13px;

  width: fit-content;

  font-family: 'HKGrotesk-Black';
  font-style: normal;
  font-size: 15px;
  line-height: 31px;
  letter-spacing: -0.03em;
  color: #ffd600;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 992px) {
    font-size: 20px;
  }

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;

export const LightText = styled.span`
  font-family: 'HKGrotesk-Light';
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 15px;
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  order: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 25px;
  gap: 20px;

  @media (min-width: ${xs}px) {
    order: 0;
    margin-top: 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;

    padding-top: 25px;
    gap: 0px;
  }
  @media (min-width: 992px) {
    justify-content: start;
  }
`;

export const MobileButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  order: 1;
  gap: 20px;
  @media (min-width: ${xs}px) {
    order: 0;
  }
`;

export const Button = styled.button<{
  backgroundColor: string;
  textColor: string;
  border?: string;
  opacity?: number;
}>`
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`};
  ${(props) => props.textColor && `color: ${props.textColor};`};
  ${(props) => props.border && `border: 3px solid ${props.border};`};
  border-radius: 50px;
  padding: 3px 0px;
  cursor: pointer;
  width: 172.23px;

  ${(props) => props.opacity && `opacity: ${props.opacity};`};

  font-family: 'HKGrotesk-Black';
  font-size: 16px;
  line-height: 36px;

  @media (min-width: 992px) {
    padding: 3px 0px;
    margin-right: 20px;
  }
`;

export const Divider = styled.span`
  font-family: 'HKGrotesk-Light';
  margin: 0 3px;
  line-height: 36px;
`;

export const SongInfo = styled.p`
  width: fit-content;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  // @media (min-width: 992px) {
  //   font-size: px;
  // }

  @media (min-width: 1200px) {
    font-size: 28px;
  }
`;

export const Cost = styled.span`
  cursor: pointer;
`;

export const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: black;
  color: white;
  padding: 10px 10px;
  width: fit-content;
  height: 42px;

  @media (min-width: 576px) {
    margin-left: 40px;
    padding: 10px 20px;
  }

`;
