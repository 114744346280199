import React, { FC, memo, useState } from 'react';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'store/store';
import styled from 'styled-components';
import theme from 'theme/theme';

import Triangle from 'common/icons/Triangle.icon';
import Apple from 'common/icons/Apple.icon';
import Spotify from 'common/icons/Spotify.icon';
import SongDataInstructionData from 'common/icons/SongDataInstructionData.icon';
import ToolTipInstructionData from 'common/icons/ToolTipInstructionData.icon';
import ExplicitLyrics from 'common/icons/ExplicitLyrics.icon';

import Typography from 'common/components/Typography/Typography';
import Tip from 'common/components/Tip/Tip';
import ImagePlayer from './components/ImagePlayer';
import Spacer from 'common/components/Spacer/Spacer';

import {
  SongInfoContainer,
  SongDetailsContainer,
  Trending,
  SongInfoText,
  TextContent,
  SocialContainer,
  BitPriceInfo,
  SongInformation,
  NoWrapContiner,
  BaseSongInfo,
  ArtistProfileButton,
  SocialLink,
} from './SongInfo.styled';
import SocialShareSection from './components/SocialShareSection';

import { InstractionalStages } from 'typings/types';
import { SongDataType, SongsTag } from 'modules/library/types';
import { lg, md, sm, xs } from 'utils/constants';
import Data from '../../../assets/MOCK_API_DATA';
import DefaultButton from 'common/components/DefautButton/DefaultButton';
import ArrowDownSmall from 'common/icons/ArrowDownSmall';
import ExplicitLuricsIcon from 'common/icons/ExplicitLurics.icon';

interface SongInfoProps {
  songData: SongDataType;
}

const SongInfo: FC<SongInfoProps> = ({ songData }) => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const [showBitValueDAI, setShowBitValueDAI] = useState<boolean>(false);
  const [showMobileSongDetails, setShowMobileSongDetails] =
    useState<boolean>(false);

  const instructionalStage = useStoreState((state) => state.instructions.stage);

  const bitValueCurrencyHandler = () => {
    setShowBitValueDAI(!showBitValueDAI);
  };

  function getSongMinutesSeconds() {
    if (songData) {
      return (
        Math.trunc(songData.audioLengthMs / 1000 / 60) +
        'm ' +
        (Math.trunc(songData.audioLengthMs / 1000) % 60) +
        's'
      );
    }
  }

  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  const formatArtistStreams = (streams: number) => {
    const billion = 1000000000;
    const million = 1000000;
    const thousand = 1000;
    if (streams >= billion) {
      return `${streams / billion}B`;
    } else if (streams >= million) {
      return `${streams / million}M`;
    } else if (streams >= thousand) {
      return `${streams / thousand}K`;
    } else {
      return `${streams}`;
    }
  };

  return (
    <>
      <SongInfoContainer
        highlight={instructionalStage === InstractionalStages.songInfo}>
        <SongDataInstructionDataContainer
          show={instructionalStage === InstractionalStages.songInfo}>
          <SongDataInstructionData />
        </SongDataInstructionDataContainer>

        <ToolTipInstractionDataContainer
          show={instructionalStage === InstractionalStages.songInfo}>
          <ToolTipInstructionData />
        </ToolTipInstractionDataContainer>

        <ImagePlayer srcImage={songData.artwork} songUrl={songData.songUrl} />

        <SongDetailsContainer>
          <BaseSongInfo>
            <ArtistProfileButton
              label={
                <TextContent
                  fontWeight="bold"
                  text={songData.title}
                  fontColor={theme.colors.white}
                  fontSize={viewport.width < 576 ? 'fz24' : 'fz36'}
                  marginBottom={10}
                />
              }
              onClick={() => {
                navigateToHandler(`/${songData.artistSlug}`);
              }}
            />
            <TextContent
              fontWeight="light"
              text={songData.artistName}
              fontColor={theme.colors.white}
              fontSize={viewport.width < 576 ? 'fz24' : 'fz36'}
            />
          </BaseSongInfo>

          <SocialShareSection show={viewport.width >= xs} songData={songData} />

          {viewport.width >= 576 && <Spacer height={15} />}

          <SongInformation display={viewport.width < 576 ? 'none' : 'flex'}>
            {songData.tags.map((el: SongsTag, i: number) => (
              <SongInfoText
                fontSize="fz24"
                fontWeight="light"
                key={i}
                text={`${el.tagName}${i < songData.tags.length - 1 ? ',' : ''}`}
              />
            ))}
            <SongInfoText fontSize="fz24" fontWeight="light" text="|" />
            <SongInfoText
              fontSize="fz24"
              fontWeight="light"
              text={`${songData.releaseYear}`}
            />
            {songData.combinedStreamCount && (
              <>
                <SongInfoText fontSize="fz24" fontWeight="light" text="|" />
                <NoWrapContiner>
                  <SongInfoText
                    fontSize="fz24"
                    fontWeight="light"
                    text={`${formatArtistStreams(
                      songData.combinedStreamCount,
                    )} artist streams`}
                  />
                  <Tip
                    text={
                      <div>
                        <Typography
                          text={
                            'This is the total number of streams the artist has amassed from all songs over time'
                          }
                          fontSize={'fz14'}
                          lineHeight={'18px'}
                        />
                      </div>
                    }
                    width={210}
                    marginBottom={viewport.width < 576 ? 2 : -1}
                    gtag_event="buy_help_text_streams"
                  />
                </NoWrapContiner>
              </>
            )}

            {songData.explicitContent && (
              <ExplicitWrapper>
                <ExplicitLyrics
                  color={theme.colors.yellow50}
                  width={viewport.width > 576 ? 100 : 70}
                  height={17}
                />
              </ExplicitWrapper>
            )}
          </SongInformation>

          {viewport.width < 576 && <Spacer height={15} />}

          <SongInformation>
            {viewport.width < xs && songData.explicitContent && (
              <>
                <ExplicitLuricsIcon />
                <FlexLineBreak />
              </>
            )}

            <TextContentContainer>
              <TextContent
                text={String(getSongMinutesSeconds())}
                fontColor={theme.colors.yellow}
                fontWeight="bold"
                fontSize={viewport.width < 576 ? 'fz24' : 'fz36'}
                lineHeight={viewport.width < 576 ? '24px' : '28px'}
              />
              {viewport.width > sm && (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontColor={theme.colors.yellow}
                    fontWeight="light"
                    text={`(${songData.audioLengthMs.toLocaleString()} bits)`}
                  />
                  <Tip
                    text={
                      <div>
                        <Typography
                          text={
                            'This is the length of the song and maximum number of bits for sale. A bit represents 1ms (millisecond) of the song.'
                          }
                          fontSize={'fz14'}
                          lineHeight={'18px'}
                        />
                      </div>
                    }
                    width={200}
                    marginLeft={5}
                  />
                </>
              )}
            </TextContentContainer>
            <TextContent
              text="|"
              fontColor={theme.colors.yellow}
              fontSize={viewport.width < 576 ? 'fz24' : 'fz36'}
            />
            <BitPriceInfo onClick={bitValueCurrencyHandler}>
              <TextContent
                text={`${
                  showBitValueDAI
                    ? (songData.bitPrice > 1
                        ? Math.round(songData.bitPrice * Data.DAI)
                        : (songData.bitPrice * Data.DAI)
                            .toFixed(2)
                            .toString()) + 'USDC'
                    : 'US$' + songData.bitPrice
                }`}
                fontColor={theme.colors.yellow}
                fontWeight="bold"
                fontSize={viewport.width < 576 ? 'fz24' : 'fz36'}
              />
              <TextContentContainer gap="5px">
                <TextContent
                  fontSize={viewport.width > sm ? 'fz24' : 'fz18'}
                  fontWeight="light"
                  fontColor={theme.colors.yellow}
                  text={'/bit'}
                  lineHeight="20px"
                />
                <TextContent
                  fontSize={viewport.width > sm ? 'fz18' : 'fz12'}
                  fontWeight="light"
                  fontColor={theme.colors.yellow}
                  text={showBitValueDAI ? '(view USD)' : '(view USDC)'}
                />
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'This is the price per bit. Tap to reveal the approximate USDC equivalent.'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginLeft={5}
                  gtag_event="buy_help_text_price"
                />
              </TextContentContainer>
            </BitPriceInfo>
          </SongInformation>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SongInformation>
              {Number(songData.soldPercent) > 75 ? (
                <>
                  <SongInfoText
                    fontSize="fz18"
                    fontWeight="bold"
                    color={theme.colors.white}
                    text={`${songData.soldPercent}% Sold`}
                  />
                  <SongInfoText fontSize="fz18" fontWeight="light" text="|" />
                </>
              ) : (
                <></>
              )}
              <SongInfoText
                fontSize="fz18"
                fontWeight="light"
                spaced
                text={`${songData.remainingBits.toLocaleString()} bits remaining`}
              />

              {viewport.width >= 576 ? (
                <>
                  <SongInfoText fontSize="fz18" fontWeight="light" text="|" />
                </>
              ) : (
                <>
                  <SongInfoText fontSize="fz18" fontWeight="light" text="|" />
                </>
              )}
              <SongInfoText
                fontSize="fz18"
                fontWeight="light"
                letterSpacing="-0.1em"
                text={`${songData.owners} Owner${
                  songData.owners > 1 ? 's' : ''
                }`}
              />

              {songData.numberOfStreams && songData.numberOfStreams > 0 ? (
                <>
                  <SongInfoText
                    fontSize="fz18"
                    fontWeight="light"
                    text={`~${formatArtistStreams(
                      songData.numberOfStreams,
                    )} streams`}
                  />
                </>
              ) : (
                <></>
              )}

              {viewport.width >= xs && (
                <>
                  {(songData.musicLinks.spotify ||
                    songData.musicLinks.applemusic) && (
                    <>
                      <SongInfoText
                        fontSize="fz18"
                        fontWeight="light"
                        text="|"
                      />
                    </>
                  )}
                  <SocialContainer>
                    {(songData.musicLinks.spotify ||
                      songData.musicLinks.applemusic) && (
                      <TextContent
                        text={'Listen on'}
                        fontSize={viewport.width > sm ? 'fz18' : 'fz16'}
                        fontWeight="light"
                        fontColor={theme.colors.yellow}
                      />
                    )}
                    {songData.musicLinks.spotify ? (
                      <>
                        <SocialLink
                          target="_blank"
                          rel="noreferrer"
                          href={`${
                            songData.musicLinks.spotify
                              ? `${songData.musicLinks.spotify}`
                              : ''
                          }`}
                          onClick={() =>
                            (window as any).gtag('event', 'buy_spotify', {})
                          }>
                          <Spotify />
                        </SocialLink>
                      </>
                    ) : (
                      <></>
                    )}
                    {songData.musicLinks.applemusic ? (
                      <>
                        <SocialLink
                          target="_blank"
                          rel="noreferrer"
                          style={{ marginTop: '-1px' }}
                          href={`${
                            songData.musicLinks.applemusic
                              ? `${songData.musicLinks.applemusic}`
                              : ''
                          }`}
                          onClick={() =>
                            (window as any).gtag('event', 'buy_apple_music', {})
                          }>
                          <Apple height={15} width={12} />
                        </SocialLink>
                      </>
                    ) : (
                      <></>
                    )}
                    <Tip
                      text={
                        <div>
                          <Typography
                            text={
                              'Total number of streams of the song to date. Less relevant for newly released songs'
                            }
                            fontSize={'fz14'}
                            lineHeight={'18px'}
                          />
                        </div>
                      }
                      width={210}
                      marginBottom={viewport.width < 576 ? 5 : 2}
                      position={viewport.width < 576 ? 'right' : 'left'}
                      left={viewport.width < 576 ? 10 : -200}
                      gtag_event="buy_help_text_owners"
                    />
                  </SocialContainer>
                </>
              )}

            </SongInformation>

            <Trending>
              <Triangle
                width={viewport.width < 576 ? 15 : 18}
                height={viewport.width < 576 ? 15 : 18}
              />
              <Typography
                text="Trending"
                fontWeight={viewport.width < 576 ? 'light' : 'bold'}
                fontSize={viewport.width < 576 ? 'fz14' : 'fz18'}
                fontColor={theme.colors.yellow}
              />
            </Trending>
          </div>

          <SongInformation display={viewport.width >= xs ? 'none' : 'flex'}>
            {!showMobileSongDetails && (
              <DetailsButtonMobileContainer>
                <DetailsButton
                  onClick={() => setShowMobileSongDetails((prev) => !prev)}>
                  <Typography
                    text="view more details"
                    fontSize="fz14"
                    fontColor={theme.colors.yellow}
                  />
                  <ArrowDownSmallContainer open={showMobileSongDetails}>
                    <ArrowDownSmall />
                  </ArrowDownSmallContainer>
                </DetailsButton>
              </DetailsButtonMobileContainer>
            )}

            {showMobileSongDetails && (
              <>
                {songData.tags.map((el: SongsTag, i: number) => (
                  <SongInfoText
                    fontSize="fz24"
                    fontWeight="light"
                    key={i}
                    text={`${el.tagName}${
                      i < songData.tags.length - 1 ? ',' : ''
                    }`}
                  />
                ))}
                <SongInfoText fontSize="fz24" fontWeight="light" text="|" />
                <SongInfoText
                  fontSize="fz24"
                  fontWeight="light"
                  text={`${songData.releaseYear}`}
                />

                <FlexLineBreak />

                {songData.combinedStreamCount && (
                  <>
                    <NoWrapContiner>
                      <SongInfoText
                        fontSize="fz24"
                        fontWeight="light"
                        text={`${formatArtistStreams(
                          songData.combinedStreamCount,
                        )} artist streams`}
                      />
                    </NoWrapContiner>
                  </>
                )}

                {songData.explicitContent && (
                  <ExplicitWrapper>
                    <ExplicitLyrics
                      color={theme.colors.yellow50}
                      width={viewport.width > 576 ? 100 : 70}
                      height={17}
                    />
                  </ExplicitWrapper>
                )}

                {(songData.musicLinks.spotify ||
                  songData.musicLinks.applemusic) && (
                  <>
                    <SongInfoText fontSize="fz18" fontWeight="light" text="|" />
                  </>
                )}
                <SocialContainer>
                  {(songData.musicLinks.spotify ||
                    songData.musicLinks.applemusic) && (
                    <TextContent
                      text={'Listen on'}
                      fontSize={viewport.width > sm ? 'fz18' : 'fz16'}
                      fontWeight="light"
                      fontColor={theme.colors.yellow}
                    />
                  )}
                  {songData.musicLinks.spotify ? (
                    <>
                      <SocialLink
                        target="_blank"
                        rel="noreferrer"
                        href={`${
                          songData.musicLinks.spotify
                            ? `${songData.musicLinks.spotify}`
                            : ''
                        }`}
                        onClick={() =>
                          (window as any).gtag('event', 'buy_spotify', {})
                        }>
                        <Spotify />
                      </SocialLink>
                    </>
                  ) : (
                    <></>
                  )}
                  {songData.musicLinks.applemusic ? (
                    <>
                      <SocialLink
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginTop: '-1px' }}
                        href={`${
                          songData.musicLinks.applemusic
                            ? `${songData.musicLinks.applemusic}`
                            : ''
                        }`}
                        onClick={() =>
                          (window as any).gtag('event', 'buy_apple_music', {})
                        }>
                        <Apple height={15} width={12} />
                      </SocialLink>
                    </>
                  ) : (
                    <></>
                  )}
                </SocialContainer>
              </>
            )}
          </SongInformation>
        </SongDetailsContainer>
      </SongInfoContainer>
    </>
  );
};

const TextContentContainer = styled.div<{ gap?: string }>`
  display: flex;
  align-items: flex-end;
  column-gap: ${(props) => (props.gap ? props.gap : '10px')};
`;

const SongDataInstructionDataContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  position: absolute;
  left: 50px;
  top: 70px;

  z-index: 10;

  @media (min-width: ${xs}px) {
    left: 100px;
    top: 0px;
  }

  @media (min-width: ${sm}px) {
    left: 216px;
    top: 80px;
  }

  @media (min-width: ${md}px) {
    left: 216px;
    top: 100px;
  }

  @media (min-width: ${lg}px) {
    left: 216px;
    top: 100px;
  }
`;

const ToolTipInstractionDataContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  position: absolute;
  left: 30px;
  top: 330px;

  z-index: 10;

  @media (min-width: ${xs}px) {
    left: 200px;
    top: 220px;
  }

  @media (min-width: ${sm}px) {
    left: 430px;
    top: 330px;
  }

  @media (min-width: ${md}px) {
    left: 516px;
    top: 320px;
  }

  @media (min-width: ${lg}px) {
    left: 516px;
    top: 320px;
  }
`;

const ExplicitWrapper = styled.div`
  display: none;

  @media (min-width: ${sm}px) {
    display: block;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }
`;

const DetailsButtonMobileContainer = styled.div`
  width: 100%;
`;

const DetailsButton = styled(DefaultButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
`;

const ArrowDownSmallContainer = styled.div<{ open: boolean }>`
  height: min-content;

  ${(props) => props.open && 'transform: rotate(180deg);'}
`;

const FlexLineBreak = styled.div`
  width: 100%;
`;

export default memo(SongInfo);
