import React, { useEffect } from 'react';
import { useViewport } from 'use-viewport';

import { escToClose } from 'utils/functions';

import ModalLayout from '../ModalLayout/ModalLayout';
import Typography from 'common/components/Typography/Typography';
import ProfileRectangleIcon from 'common/icons/ProfileRectangle.icon';
import StockUpIcon from 'common/icons/StockUp.icon';

import {
  ContentContainer,
  FooterContainer,
  IconContainer,
  RowContainer,
} from './index.styled';
import { xs } from 'utils/constants';

type BioDataModalProps = {
  intro: string;
  description: string;
  onClose: () => void;
};

const BioDataModal = ({ onClose, intro, description }: BioDataModalProps) => {
  const viewport = useViewport();

  useEffect(escToClose(onClose), []);

  return (
    <ModalLayout onClose={onClose} title="bio and data">
      <ContentContainer>
        <RowContainer direction="column" gap="20px">
          <IconContainer>
            <ProfileRectangleIcon />
          </IconContainer>

          {intro.split('\\n').map((block, key) => (
            <Typography
              text={block.replace('\\n', '').replace('\n', '')}
              fontSize={viewport.width >= xs ? 'fz14' : 'fz16'}
              lineHeight={'16px'}
              key={key}
            />
          ))}
        </RowContainer>
        
        {description ? <>
        <RowContainer marginBottom="0" direction="column">
          <IconContainer>
            <StockUpIcon />
          </IconContainer>

          {description.split('\\n').map((block, key) => (
            <Typography
              text={block.replace('\\n', '').replace('\n', '')}
              fontSize={viewport.width >= xs ? 'fz20' : 'fz16'}
              lineHeight={'28px'}
              fontWeight={'light'}
              key={key}
            />
          ))}
        </RowContainer>
        </> : <></>}


        {viewport.width >= xs && (
          <FooterContainer>
            <Typography text={'*Artist supplied data.'} fontSize={'fz10'} />
          </FooterContainer>
        )}
      </ContentContainer>
    </ModalLayout>
  );
};

export default BioDataModal;
