import React, { FC, memo, useCallback, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import AddCardModal from 'modules/account/pages/components/Modals/AddCardModal';
import { CardSchema, PaymentModalType } from '../types';
import { useViewport } from 'use-viewport';
// import ReactGA from 'react-ga4';

interface PaymentModalProps {
  isOpen: boolean;
  type: PaymentModalType;
  changePaymentMethod?: () => void;
  onClose: () => void;
  onCardAdded: (card: CardSchema) => void;
  fromMyWallet?: boolean;
  cardId?: string;
}

const AddCard: FC<PaymentModalProps> = ({
  isOpen,
  type,
  onClose,
  onCardAdded,
  changePaymentMethod,
  fromMyWallet = false,
  cardId,
}) => {
  const viewport = useViewport();
  const getTopPosition = useCallback(() => {
    if (
      viewport.width >= 1024 &&
      viewport.width < 1200 &&
      viewport.width > viewport.height
    ) {
      return '0%';
    } else {
      return '5%';
    }
  }, [viewport.width, viewport.height]);

  const getHeight = useCallback(() => {
    if (viewport.height < 850) {
      return '85%';
    } else {
      return '780px';
    }
  }, [viewport.width, viewport.height]);

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag('event', 'purchase_add_card', {});
    }
  }, [isOpen]);

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height={getHeight()}
      top={getTopPosition()}
      mobileHeight="90%"
      mobileTop="2%"
      overflow={'scroll'}
      content={
        <AddCardModal
          type={type}
          changePaymentMethod={() => {
            if (changePaymentMethod) {
              changePaymentMethod();
            }
          }}
          onChange={onClose}
          onCardAdded={onCardAdded}
          fromMyWallet={fromMyWallet}
          cardId={cardId}
        />
      }
    />
  );
};

export default memo(AddCard);
