import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';

import store from 'store/store';
import theme from 'theme/theme';

import App from './App';
import client from './graphql/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './reset.css';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistor, storePersist } from 'store-persist/configureStore';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_URL}`,
});



const getLibrary = (provider: any) => {
  return new Web3(provider);
};


ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      {/* Main store */}
      <Provider store={storePersist}>
        {/* Persist store */}
        <PersistGate loading={null} persistor={persistor}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ApolloProvider client={client}>
              <ThemeProvider theme={theme}>
                  <App />
              </ThemeProvider>
            </ApolloProvider>
          </Web3ReactProvider>
        </PersistGate>
      </Provider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
