import React, { FC } from 'react';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router-dom';
import theme from 'theme/theme';

import { getSoldPercent } from 'utils/calculation';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

import arrow from '../../../assets/arrow-down-right.png';

import {
  ButtonWrapper,
  Drop,
  DropButton,
  DropDetails,
  DropDetailsContainer,
  DropsContainer,
  DropTypeContainer,
  Pipe,
} from './Drops.styled';
import { ArrowImg, SongImg } from '../../styled/ArtistSettings.styled';

interface OtherReleasesProps {
  drops: any[];
}

export const OtherReleases: FC<OtherReleasesProps> = ({ drops }) => {
  const viewport = useViewport();
  const navigate = useNavigate();

  function formatDate(date: string) {
    const values = date.split('T')[0].split('-');

    const new_date = new Date(
      parseInt(values[0].substring(0)),
      parseInt(values[1]) - 1,
      parseInt(values[2]),
    );

    return (
      values[2] +
      ' ' +
      new_date.toLocaleString('default', { month: 'short' }) +
      ' ' +
      values[0].substring(0)
    );
  }

  return (
    <DropsContainer>
      <DropTypeContainer>
        <Typography
          text={'other releases'}
          fontSize={'fz24'}
          fontWeight="bold"
          fontColor={theme.colors.yellow}
        />
        <Spacer height={10} />
        {drops.map((d, i) => (
          <DropButton
            width={viewport.width < 576 ? viewport.width * 0.9 : 450}
            key={`Key for DropButton is - ${i}`}
            label={
              <Drop>
                <ArrowImg src={arrow} />
                <SongImg src={d.image} />
                <DropDetailsContainer>
                  <Typography
                    text={d.title}
                    fontSize={d.title.length > 30 ? 'fz14' : 'fz18'}
                    fontWeight="regular500"
                    className="songName"
                    fontColor={theme.colors.white}
                  />
                  <Spacer height={5} />
                  <DropDetails>
                    {d.songType && d.songType === 'COMING_SOON' ? (
                      <></>
                    ) : (
                      <>
                        <Typography
                          text={formatDate(d.release_at)}
                          fontSize="fz14"
                          fontWeight="light"
                          fontColor={theme.colors.white}
                        />

                        <Pipe>|</Pipe>
                      </>
                    )}
                    <Typography
                      fontSize="fz14"
                      fontWeight="light"
                      text={`${getSoldPercent(
                        d.audio_length_ms,
                        1,
                        d.sold_bits_inverted
                      )}% Sold`}
                      fontColor={theme.colors.white}
                    />
                  </DropDetails>
                </DropDetailsContainer>
              </Drop>
            }
            onClick={() => {
              navigate('/artist/releases/summary?id=' + d.song_id);
            }}
          />
        ))}
      </DropTypeContainer>
      <Spacer height={15} />
      <ButtonWrapper onClick={() => navigate('/artist/releases')}>
        <Typography text="View All Releases" fontColor={theme.colors.yellow} />
      </ButtonWrapper>
    </DropsContainer>
  );
};
