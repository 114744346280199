import React, { useMemo, useEffect } from 'react';
import { useViewport } from 'use-viewport';

import TextContentContainer from 'common/components/TextContentContainer/TextContentContainer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';

import Banner from '../assets/careers-bg.jpg';

import { footerHeight, footerMobileHeight, xlg, xs, sm } from 'utils/constants';

import {
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  TextContent,
} from './styled/TellMeMore.styled';

import {
  Header,
  Title
} from './styled/Company.styled';
import Spacer from 'common/components/Spacer/Spacer';
const Careers = () => {
  const viewport = useViewport();

  const contentContainerHeight = useMemo(
    () =>
      viewport.width < xs ? '100%' : viewport.width >= xlg ? '450px' : 'auto',
    [viewport.width],
  );

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src =
      'https://apps.elfsight.com/p/platform.js';
    script.async = true;
    script.defer = true;
  
    const cookieDiv = document.getElementById('careers');
  
    if (cookieDiv) {
      cookieDiv.appendChild(script);
    }
  
    return () => {
      const cookieDiv = document.getElementById('careers');
  
      if (cookieDiv) {
        cookieDiv.removeChild(script);
      }
    };
  }, []);

  return (
    <PageContainer
      pageTitle='Careers | SongBits'
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ImageContainer>
        <Image src={Banner} />
        <ImageGradient banner={Banner} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>

        <Header>
          <Title text="careers" fontColor={theme.colors.white} />
          &nbsp;
          <Title text="." fontColor={theme.colors.white} />
          &nbsp;
          <Title text="." fontColor={theme.colors.white} />
          &nbsp;
          <Title text="." />
        </Header>
        <Spacer height={viewport.width < sm ? 10 : 40}></Spacer>
        
          <TextContentContainer
            height={contentContainerHeight}
            altText="Supercharge your career at the music company redefining the artist-fan relationship forever.">
            <TextContent
              text=" Check out of current opportunities below."
              fontSize="fz14"
              fontColor={theme.colors.white}
            />


            <div id="careers" className='elfsight-app-901c7bd4-0e29-4501-8da3-c87d4c576ce6'></div>


          </TextContentContainer>
        </HeadingSection>
          
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(Careers);
