import React, { FC, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'store/store';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';

import { InstractionalStages } from 'typings/types';

import Typography from 'common/components/Typography/Typography';
import ModalInstructionalLayout from '../ModalInstructionalLayout/ModalInstructionalLayout';

import { RowContainer, Title, TitleCenter } from './index.styled';

interface InstructionalModalProps {
  show: boolean;
  onClose: () => void;
}

const InstructionalModal: FC<InstructionalModalProps> = ({ show, onClose }) => {
  const viewport = useViewport();

  const stage = useStoreState((state) => state.instructions.stage);

  const setShowHeader = useStoreActions((state) => state.style.setShowHeader);
  const setStage = useStoreActions((state) => state.instructions.setStage);

  useEffect(() => (show ? setShowHeader(false) : setShowHeader(true)), [show]);

  return (
    <ModalInstructionalLayout
      show={show}
      onClose={() => {
        onClose();
        setStage(InstractionalStages.initial);
      }}
      onNext={() => setStage(stage + 1)}>
      <InstructionalContainer>
              <TitleCenter
                text={'tutorial'}
                fontWeight="bold"
                fontSize={viewport.width >= 576 ? 'fz48' : 'fz36'}
                letterSpacing="-0.03em"
              />
        <ContentContainer>
          {stage === InstractionalStages.initial ? (
            <>
              <Title
                text={
                  stage === InstractionalStages.initial
                    ? 'instructions'
                    : 'tutorial'
                }
                fontWeight="bold"
                fontSize={viewport.width >= 576 ? 'fz48' : 'fz36'}
                letterSpacing="-0.03em"
              />
              <RowContainer>
                <Typography
                  text={'Take a quick overview of how to buy a SongBit'}
                />
              </RowContainer>
            </>
          ) : (
            <>
            </>
          )}
        </ContentContainer>
      </InstructionalContainer>
    </ModalInstructionalLayout>
  );
};

const InstructionalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

export default InstructionalModal;
