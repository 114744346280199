import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';

import restService from 'services/rest.service';
import { useStoreState } from 'store/store';
import { getUniqueInsightLocations } from 'utils/artistAnalytics';

import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import UserLocations from '../components/UserLocations/UserLocations';
import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';

import { GET_ALL_DROPS } from 'modules/artist/graphql/Queries.graphql';
import { ILocationsData } from 'services/types';
import RegionsStatistics from '../ArtistAnalytics/components/layout/RegoinsStatistics/RegionsStatistics';
import { useLocation, useSearchParams } from 'react-router-dom';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import InsightLocationPlaceholder from 'common/components/Placeholder/InsightLocation';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  {
    title: 'View Users',
    link: '/artist/insights/users',
  },
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
];

const InsightLocation = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const accessToken = useStoreState(
    (state) => state.authentication.accessToken,
  );

  const {
    data: dropsData = {
      allSongs: {
        nodes: [],
      },
    },
    loading,
  } = useQuery(GET_ALL_DROPS, {
    variables: {
      userId: jwtDecode<{ user_id: string }>(accessToken ?? '').user_id,
    },
  });

  const DropsList_dropdown = useMemo(
    () => [
      {
        label: 'All Releases',
        value: 'all',
      },
      ...dropsData.allSongs.nodes.map((dropData: any) => ({
        label: dropData.title,
        value: dropData.id,
      })),
    ],
    [dropsData],
  );

  const [currentDrop, setCurrentDrop] = useState<{
    value: string | number;
    label: string;
  }>(DropsList_dropdown[0]);
  const [currentDropLocations, setCurrentDropLocations] = useState<
    ILocationsData[]
  >([]);
  const [currentRegion, setCurrentRegion] = useState<string>('');

  useEffect(() => {
    const queryParams = location.state as { [x: string]: any };

    if (queryParams?.region) {
      setCurrentRegion(queryParams.region);
    }

    restService.getInsights().then((data) => {
      const tempLocations = data.locations;
      setCurrentDropLocations(getUniqueInsightLocations(tempLocations));
    });
  }, [searchParams]);

  useEffect(() => {
    const dropData = dropsData.allSongs.nodes.find(
      (drop: any) => drop.id === currentDrop.value,
    );

    if (dropData) {
      restService.getInsightsSong(dropData.id).then((insightDrop) => {
        setCurrentDropLocations(
          getUniqueInsightLocations(insightDrop.locations),
        );
      });
    } else if (currentDrop.value === 'all') {
      restService.getInsights().then((data) => {
        const tempLocations = data.locations;
        const uniqueLocations = getUniqueInsightLocations(tempLocations);
        setCurrentDropLocations(uniqueLocations);
      });
    }
  }, [currentDrop, dropsData]);

  const content = (
    <>
      <Dropdown
        data={DropsList_dropdown}
        currentItem={currentDrop}
        onSelect={(item) => setCurrentDrop(item)}
      />

      <UserLocations
        locationsData={currentDropLocations}
        onLocationClick={(regionName) => {
          setCurrentRegion(regionName);
        }}
      />

      <RegionsStatistics
        name={currentRegion}
        currentDrop={currentDrop}
        locations={currentDropLocations.sort(
          (a, b) => Number(b.amount) - Number(a.amount),
        )}
      />
    </>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Location Insights & Analytics | SongBits'}>
      <PageLayout
        title="Insights & Analytics"
        is2ColumnLayout
        sections={[
          {
            content: !loading ? content : <InsightLocationPlaceholder />,
          },
          {
            content: !loading ? (
              <QuickLinks links={quickLinks} />
            ) : (
              <div style={{ marginTop: '-20px' }}>
                <QuickLinksPlaceholder noLinks={5} />
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export default InsightLocation;
