import React from 'react';
import {
  ToastContainer,
  toast,
  ToastOptions,
  ToastPosition,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Toaster.scss';

const AUTO_CLOSE_TIME = 2000;

enum notifyStatuses {
  modal = 'modal',
  success = 'success',
}

export const DEFAULT_TOASTER_OPTIONS: ToastOptions = {
  draggable: false,
  progress: undefined,
  closeOnClick: false,
  hideProgressBar: true,
  position: 'bottom-center',
  className: 'toaster-notify',
};

export const notify = ({
  message,
  type = 'modal',
  customComponent,
  autoClose = true,
  closeButton = true,
  position = 'bottom-center',
  style = {},
}: {
  message?: string;
  autoClose?: boolean;
  closeButton?: boolean;
  customComponent?: JSX.Element;
  type?: keyof typeof notifyStatuses;
  position?: ToastPosition | undefined;
  style?: object | undefined;
}) => {
  let toastID;

  const CustomComponent: any = customComponent
    ? () => customComponent
    : undefined;

  switch (type) {
    case 'modal':
      toastID = toast(customComponent ? <CustomComponent /> : message, {
        ...DEFAULT_TOASTER_OPTIONS,
        closeButton: closeButton,
        autoClose: autoClose ? AUTO_CLOSE_TIME : false,
        position: position ? position : 'bottom-center',
        style: style ? style : {},
      });

      return toastID;
    case 'success':
      toastID = toast(customComponent ? customComponent : message, {
        ...DEFAULT_TOASTER_OPTIONS,
        closeButton: closeButton,
        autoClose: autoClose ? AUTO_CLOSE_TIME : false,
        position: position ? position : 'bottom-center',
        style: style ? style : {},
      });

      return toastID;
    default:
      toastID = toast(customComponent ? customComponent : message, {
        ...DEFAULT_TOASTER_OPTIONS,
        closeButton: closeButton,
        autoClose: autoClose ? AUTO_CLOSE_TIME : false,
        position: position ? position : 'bottom-center',
        style: style ? style : {},
      });

      return toastID;
  }
};

const ToasterContainer = () => {
  return <ToastContainer position="bottom-center" />;
};

export default ToasterContainer;
