import React, { useEffect } from 'react';

import { escToClose } from 'utils/functions';

import ModalLayout from '../ModalLayout/ModalLayout';


import {
  ContentContainer,
  IconContainer,
  RowContainer,
  Text,
} from './index.styled';

import { PurchasePerksType } from 'modules/artist/types';
import { getPerkIcon } from '../SongProfileIconsSection/SongProfileIconsSection';

type BenefitsModalProps = {
  onClose: () => void;
  perks: PurchasePerksType[];
};

const InfoBenefitsModal = ({ onClose, perks }: BenefitsModalProps) => {

  useEffect(escToClose(onClose), []);

  return (
    <ModalLayout onClose={onClose} title={'info & benefits'}>
      <ContentContainer>
        {perks
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((p, key) => {
            return (
              <RowContainer key={key}>
                <IconContainer>{getPerkIcon(p)}</IconContainer>
                <Text text={p.description.replaceAll('|', '')} />
              </RowContainer>
            );
          })}
      </ContentContainer>
    </ModalLayout>
  );
};

export default InfoBenefitsModal;
