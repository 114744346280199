import React, { FC, memo, useEffect, useState } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import { BalanceDetails, CryptoAddressSchema } from '../types';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import MetamaskClient from 'services/web3.client';
import restService from 'services/rest.service';
import { useStoreActions } from 'store/store';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';
import { useAppSelector } from 'hooks/reduxToolkit';

interface PaymentModalProps {
  isOpen: boolean;
  header: JSX.Element;
  balanceDetails: BalanceDetails;
  costOfSelected: string;
  purchaseExpired: () => void;
  onClose: () => void;
  onUseCard: () => void;
  onError: (error: Error) => void;
  onPaymentComplete: (id: string) => void;
}

const CryptoAuto: FC<PaymentModalProps> = ({
  isOpen,
  header,
  balanceDetails,
  costOfSelected,
  onClose,
  onUseCard,
  onError,
  onPaymentComplete,
  purchaseExpired,
}) => {
  const viewport = useViewport();
  const dispatch = useAppDispatch();
  const metamaskClient = new MetamaskClient(
    process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [stateConnectedAccount, setStateConnectedAccount] =
    useState<boolean>(false);
  const [connectedAddress, setConnectedAddress] = useState<string>('');
  const [connectedChain, setConnectedChain] = useState<string>('');

  const [addressList, setAddressList] = useState<CryptoAddressSchema[]>([]);

  const [walletBalanceETH, setwalletBalanceETH] = useState<string>('');
  const [walletBalanceUSDC, setwalletBalanceUSDC] = useState<string>('');

  const { expiryTime } = useAppSelector(
    (state) => state.modalSlice,
  );
  
  stateConnectedAccount;
  connectedAddress;
  connectedChain;
  walletBalanceETH;
  walletBalanceUSDC;

  // TODO: Error Handling
  useEffect(() => {
    restService
      .getCryptoAddresses()
      .then((res) => {
        const addresses: CryptoAddressSchema[] = [];
        res.forEach((address: any) => {
          addresses.push({
            address: address.address,
            nickname: address.nickname,
            currency: address.currency,
            chain: address.chain,
            status: address.status,
          });
        });
        setAddressList(addresses);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {

      purchaseExpired()

    } else if (isOpen) {
      dispatch(setModalType('CryptoAuto'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  // Call if no connected wallet
  async function connectWallet() {
    metamaskClient
      .connect('Wallet Address')
      .then(() => {
        setStateConnectedAccount(true);
        setConnectedAddress(metamaskClient.connected_wallet);
        setConnectedChain(metamaskClient.chain);

        // Wallet connected - continue with purchase
        makeCryptoPurchase();
      })
      .catch((error) => {
        console.error(error);
        setGlobalBanner({
          title: 'Failed to connect wallet: ',
          text: error.message,
        });
        setStateConnectedAccount(false);
        setConnectedAddress('');
        setConnectedChain('');
      });
  }

  async function makeCryptoPurchase() {
    const transfer_amount = parseFloat(costOfSelected);
    
    metamaskClient.connect().then(() => {
      metamaskClient
        .getBalanceETH()
        .then(() => {
          const eth_bal = parseFloat(metamaskClient.balance_ETH);
          if (eth_bal === 0) {
            setGlobalBanner({
              title: 'Transfer Failed: ',
              text: 'No ETH for gas fees',
            });
            const e = new Error('No ETH for gas fees');
            onError(e);
            throw e;
          }
          setwalletBalanceETH(metamaskClient.balance_ETH);
          metamaskClient
            .getBalanceUSDC()
            .then(() => {
              const usdc_bal = parseFloat(metamaskClient.balance_USDC);
              if (usdc_bal < transfer_amount) {
                throw new Error('Not enough USDC to transfer');
              }
              setwalletBalanceUSDC(metamaskClient.balance_USDC);
              if (addressList[0]) {
                metamaskClient
                  .transferUSDC(
                    addressList[0].address,
                    parseFloat(costOfSelected),
                  )
                  .then((transaction) => {
                    transaction;
                    onPaymentComplete('123');
                  })
                  .catch((error) => {
                    console.error('Transfer error', error);
                    setGlobalBanner({
                      title: 'Transfer failed: ',
                      text: error.message,
                    });
                  });
              } else {
                console.error('No Incoming Address Available');
              }
            })
            .catch((error) => {
              console.error(error);
              setGlobalBanner({
                title: 'Failed to retrieve USDC balance: ',
                text: error.message,
              });
              setwalletBalanceUSDC('');
            });
        })
        .catch((error) => {
          console.error(error);
          setGlobalBanner({
            title: 'Failed to retrieve ETH balance: ',
            text: error.message,
          });
          setwalletBalanceETH('');
        });
    });
  }

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth='100%'
      height='662px'
      top='10%'
      mobileTop='2%'
      withProceedingText={true}
      withFeeText={true}
      withSettings={false}
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text="crypto payment"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          <Spacer height={65} />
          <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
            <FirstButton
              height={45}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text={
                    +balanceDetails.availableBalance >
                    parseFloat(costOfSelected)
                      ? 'Buy with crypto balance'
                      : 'Balance too low. Top up now'
                  }
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={() => {
                if (addressList.length === 0) {
                  connectWallet();
                } else {
                  makeCryptoPurchase();
                }
              }}
            />
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              labelColor={theme.colors.yellow}
              label={
                <TextContent
                  text={
                    +balanceDetails.availableBalance >
                    parseFloat(costOfSelected)
                      ? 'Top up crypto balance'
                      : 'Use card payment'
                  }
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={() => {
                if (
                  +balanceDetails.availableBalance > parseFloat(costOfSelected)
                ) {
                } else {
                  onUseCard();
                }
              }}
            />
          </PaymentRow>
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  width: 30px;

  @media (min-width: 576px) {
    width: 350px;
    margin-left: -5px;
  }
`;

const PaymentRow = styled.div`
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

export default memo(CryptoAuto);
