import { motion } from 'framer-motion';
import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'store/store';

import { footerHeight, footerMobileHeight, sm } from 'utils/constants';

import {
  animationVariants,
  pageAnimationTransition,
} from 'utils/framer-animation-constants';

const WithRouteAnimation = <T,>(
  WrappedComponent: ComponentType<T>,
  reduceHeight?: boolean,
): React.ComponentType<T> => {
  const AnimatedComponent = (props: T) => {
    const isAuthenticated = useStoreState(
      (state) => state.authentication.isAuthenticated,
    );

    return (
      <MotionContainer
        exit="out"
        animate="in"
        initial="initial"
        variants={animationVariants}
        transition={pageAnimationTransition}
        $reduceheight={reduceHeight}
        $authenticated={isAuthenticated}>
        <WrappedComponent {...props} />
      </MotionContainer>
    );
  };

  return AnimatedComponent;
};

const MotionContainer = styled(motion.div)<{
  $reduceheight?: boolean;
  $authenticated?: boolean;
}>`
  min-height: ${(props) =>
    props.$reduceheight ? `calc(100% - ${footerMobileHeight}px)` : '100%'};
  width: 100%;
  display: flex;

  @media (min-width: ${sm}px) {
    min-height: ${(props) =>
      props.$reduceheight && !props.$authenticated
        ? `calc(100% - ${footerHeight}px)`
        : '100%'};
  }
`;

export default WithRouteAnimation;
