import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

import { useStoreState, useStoreActions } from 'store/store';

const GlobalBanner = () => {
  const bannerTitle = useStoreState((state) => state.globalbanner.title);
  const bannerText = useStoreState((state) => state.globalbanner.text);
  const hideBanner = useStoreActions(
    (actions) => actions.globalbanner.hideGlobalBanner,
  );

  const seconds = 5;

  useEffect(() => {
    const timer = setTimeout(() => {
      hideBanner();
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <GlobalBannerContainer id="global-banner">
      <div>
        <BannerTitle>{bannerTitle}</BannerTitle>
        &nbsp;&nbsp;
        <BannerText>{bannerText}</BannerText>
        <Button
          onClick={() => {
            hideBanner();
          }}>
          X
        </Button>
      </div>
    </GlobalBannerContainer>
  );
};

const GlobalBannerContainer = styled.div`
  background-color: ${theme.colors.yellow};
  width: 100vw;
  height: 70px;
  position: absolute;
  top: 0;
  z-index: 1040;
  text-align: center;
  padding-top: 28px;

  @media (min-width: 768px) {
    height: 100px;
    padding-top: 40px;
  }
`;

const Button = styled.button`
  background-color: ${theme.colors.yellow};
  position: absolute;
  right: 10px;
  top: 24px;
  border: none;
  font-size: 20px;
  font-style: bold;
  cursor: pointer;

  @media (min-width: 768px) {
    right: 20px;
    top: 40px;
  }
`;

const BannerTitle = styled.span`
  font-size: 18px;
  font-weight: 900;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const BannerText = styled.span`
  font-size: 18px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export default GlobalBanner;
