import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import SBModal from 'common/components/Modal/SBModal';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import MetamaskClient from 'services/web3.client';
import { useStoreActions } from 'store/store';
import restService from 'services/rest.service';
import { CryptoAddressSchema } from '../types';

interface PaymentModalProps {
  isOpen: boolean;
  header: JSX.Element;
  costOfSelected: string;
  onClose: () => void;
  onPaymentComplete: () => void;
}

const TryAgain: FC<PaymentModalProps> = ({
  isOpen,
  header,
  onClose,
  onPaymentComplete,
  costOfSelected,
}) => {
  const viewport = useViewport();
  const metamaskClient = new MetamaskClient(
    process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [addressList, setAddressList] = useState<CryptoAddressSchema[]>([]);

  useEffect(() => {
    restService
      .getCryptoAddresses()
      .then((res) => {
        const addresses: CryptoAddressSchema[] = [];
        res.forEach((address: any) => {
          addresses.push({
            address: address.address,
            nickname: address.nickname,
            currency: address.currency,
            chain: address.chain,
            status: address.status,
          });
        });
        setAddressList(addresses);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  async function makeCryptoPurchase() {
    const transfer_amount = parseFloat(costOfSelected);

    metamaskClient.connect().then(() => {
      metamaskClient
        .getBalanceETH()
        .then(() => {
          const eth_bal = parseFloat(metamaskClient.balance_ETH);

          // change modal to balance

          if (eth_bal === 0) {
            setGlobalBanner({
              title: 'Transfer Failed: ',
              text: 'No ETH for gas fees',
            });
          }
          metamaskClient
            .getBalanceUSDC()
            .then(() => {
              const usdc_bal = parseFloat(metamaskClient.balance_USDC);
              if (usdc_bal < transfer_amount) {
                // change modal to USDC
                //onInsufficientFunds();
                return;
              }
              if (addressList[0]) {
                metamaskClient
                  .transferUSDC(
                    addressList[0].address,
                    parseFloat(costOfSelected),
                  )
                  .then((transaction) => {
                    transaction;
                    onPaymentComplete();
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                console.error('No Incoming Address Available');
              }
            })
            .catch((error) => {
              // Reject transfer
              // switch modal

              if (error.code === 4001) {
                // cancelled transaction
              } else {
                console.error('Transfer error', error);
              }
            });
        })
        .catch((error) => {
          console.error(error);
          setGlobalBanner({
            title: 'Failed to retrieve ETH balance: ',
            text: error.message,
          });
        });
    });
  }

  return (
    <SBModal
      isOpen={isOpen}
      withProceedingText={true}
      withFeeText={true}
      feeBottomPos="60px"
      withSettings={false}
      top="10%"
      mobileTop="2%"
      width="434px"
      mobileWidth="100%"
      height="662px"
      content={
        <>
          <CloseButton onClick={onClose}>X</CloseButton>
          <BoldText
            text="crypto payment"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          <Column>
            <Spacer height={20} />
            <Typography
              text="Cancelled Purchase in MetaMask"
              fontWeight="light"
            />
            {/*<FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.white}
              labelColor={theme.colors.yellow}
              label={
                <TextContent
                  text="Contact Support"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
              }
              onClick={() => {}}
            />*/}
            <FirstButton
              height={45}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text="Try Again"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={makeCryptoPurchase}
            />
          </Column>
        </>
      }
    />
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  margin-left: -5px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 350px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  left: 360px;
  font-size: 20px;
  cursor: pointer;

  @media (min-width: 576px) {
    left: 380px;
  }
`;

export default TryAgain;
