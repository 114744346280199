import React, { FC, useEffect, useState } from 'react';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import Copy from '../../../assets/copy-icon.png';
import {
  ArtistName,
  BitsNumber,
  BoldText,
  CloseButton,
  CopyButton,
  ExportModal,
  InfoContainer,
  ModalHeader,
  Row,
  SongContainer,
  SongImage,
  SongImageContainer,
  SongInfo,
  SongName,
  SuccessInfo,
  TransactionContent,
  TransactionText,
} from './ExportBitsModal';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';

interface ExportDetailsProps {
  contractAddress: string;
  nftTokenId: string;
  type: string;
  image: string;
  artistName: string;
  songName: string;
  bits: string;
  handleClose: () => void;
}

const ExportDetailsModal: FC<ExportDetailsProps> = ({
  contractAddress,
  nftTokenId,
  type,
  image,
  artistName,
  songName,
  bits,
  handleClose,
}) => {
  const viewport = useViewport();
  const [tokenCopied, setTokenCopied] = useState<boolean>(false);
  const [addressCopied, setAddressCopied] = useState<boolean>(false);

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text);

    if (type === 'address') {
      setAddressCopied(true);
    }
    if (type === 'token') {
      setTokenCopied(true);
    }
  };

  useEffect(() => {
    if (addressCopied) {
      setTimeout(() => {
        setAddressCopied(false);
      }, 3000);
    }
    if (tokenCopied) {
      setTimeout(() => {
        setTokenCopied(false);
      }, 3000);
    }
  }, [addressCopied, tokenCopied]);

  return (
    <ExportModal>
      <ModalHeader>
        <CloseButton onClick={handleClose}>X</CloseButton>
        <BoldText
          text={type === 'royalty' ? 'export bits' : 'export image'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />

        <SongContainer>
          <SongImageContainer>
            <SongImage src={image} />
          </SongImageContainer>
          <SongInfo>
            <ArtistName text={artistName} fontColor={theme.colors.yellow} />
            <SongName text={songName} />
            <BitsNumber text={bits + ' bits'} fontColor={theme.colors.yellow} />
          </SongInfo>
        </SongContainer>
      </ModalHeader>
      <InfoContainer width="360px">
        <Typography text="NFT Details" fontSize="fz24" fontWeight="bold" />
        <Spacer height={10} />
        {type === 'royalty' ? (
          <SuccessInfo text="Import the NFT by manually copying the following data via 'Import Token' in your Meta Mask Wallet" />
        ) : (
          <SuccessInfo text="Import the Image NFT by manually copying the following data via 'Import Token' in your Meta Mask Wallet" />
        )}
        <Spacer height={10} />
        <Row>
          <TransactionContent text="Address: " fontWeight="bold" />
          <TransactionText text={contractAddress} />
          <CopyButton>
            <img
              src={Copy}
              onClick={() => copyToClipboard(contractAddress, 'address')}
            />
          </CopyButton>
        </Row>

        {addressCopied ? (
          <Typography
            text={'copied!'}
            fontWeight="light"
            fontColor="yellow"
            fontSize="fz14"
          />
        ) : (
          <Spacer height={5} />
        )}
        <Spacer height={addressCopied ? 10 : 18} />

        <Row>
          <TransactionContent text="NFT Token ID: " fontWeight="bold" />
          <TransactionText text={nftTokenId} />
          <CopyButton>
            <img
              src={Copy}
              onClick={() => copyToClipboard(nftTokenId, 'token')}
            />
          </CopyButton>
        </Row>
        {tokenCopied ? (
          <Typography
            text={'copied!'}
            fontWeight="light"
            fontColor="yellow"
            fontSize="fz14"
          />
        ) : (
          <Spacer height={5} />
        )}
      </InfoContainer>
    </ExportModal>
  );
};

export default ExportDetailsModal;
