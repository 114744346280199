import { gql } from '@apollo/client';

export const GET_WALLETS_QUERY = gql`
  query GET_WALLETS($id: UUID!) {
    allCircleWallets(filter: { userid: { equalTo: $id } }) {
      nodes {
        id
      }
    }
  }
`;

export const GET_SONG_QUERY = gql`
  query GET_SONG($id: UUID!) {
    songById(id: $id) {
      id
      title
      actualBitPrice
      editionsCount
      remainingBits
      soldBits
      soldBitsInverted
      ownersCount
      releaseAt
      fileByCoverId {
        url
      }
      fileByAudioId {
        originalName
        url
      }
      songsTagsBySongId {
        nodes {
          tagName
        }
      }
      description
      audioLengthMs
      artistProfileByArtistId {
        artistName
        intro
        slug
      }
      createdAt
      explicitContent
    }
  }
`;

export const GET_ARTIST_QUERY = gql`
  query GET_ARTIST_INFO {
    allArtistProfiles {
      edges {
        node {
          artistName
          songsByArtistId {
            nodes {
              actualBitPrice
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_SONGS = gql`
  query MyQuery {
    allSongs {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const GET_SONG_BY_SLUG = gql`
  query GET_SONG_BY_SLUG($slug: String!, $userid: UUID) {
    songBySlug(slug: $slug) {
      maximumBitPurchase
      nftInstancesBySongId(
        filter: {
          parentId: { isNull: true }
          remainingBits: { greaterThan: 0 }
        }
        first: 1
      ) {
        nodes {
          id
        }
      }
      audioLengthMs
      editionsCount
      commission
      slug
      artistName
      title
      remainingBits
      soldBits
      soldBitsInverted
      releaseAt
      releaseYear
      ownersCount
      id
      fpStatus
      actualBitPrice
      spotify
      apple
      youtube
      trackd
      numberOfStreams
      minimumBitPurchase
      releaseAt
      status
      songType
      artistProfileByArtistId {
        artistName
        slug
        userId
        combinedStreamCount
      }
      description
      explicitContent
      fileByAudioId {
        url
        id
      }
      fileByCoverId {
        url
      }
      songsTagsBySongId {
        nodes {
          tagName
        }
      }
      songlikesByLikedSong(condition: { likedBy: $userid }) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

// allSongs(condition: { status: PUBLISHED }) {
// allSongs(filter: {status: {in: [PUBLISHED, WAIT_NFT_APPROVE]}}) {

export const GET_HOT_DROPS = gql`
  query HotDrops {
    allReleases(orderBy: DISPLAY_ORDER_ASC) {
      nodes {
        songBySongId {
          slug
          id
          releaseAt
          songType
          explicitContent
          remainingBits
          bannerFocalPoint
          soldBitsInverted
          soldBits
          artistName
          fileByCoverId {
            url
          }
          fileByBannerId {
            url
          }
          title
          artistProfileByArtistId {
            artistName
            slug
          }
        }
      }
    }
  }
`;

export const GET_ALL_TRANSACTIONS = gql`
  query AllTransactions($id: Int!) {
    allTransactions(
      filter: { walletId: { equalTo: $id } }
      orderBy: CREATE_AT_DESC
    ) {
      totalCount
      nodes {
        id
        createAt
        fee
        nftPrice
        nftTokenId
        songBySongId {
          id
          title
          slug
          artistProfileByArtistId {
            artistName
            slug
          }
          fileByCoverId {
            url
          }
          audioLengthMs
        }
        nftInstanceByNftInstanceId {
          id
          actualBitPrice
          bitLength
          transactionHash
        }
      }
    }
  }
`;

export const GET_LIBRARY_LISTINGS = gql`
  query LibraryListings {
    allSongs(filter: { status: { equalTo: PUBLISHED } }) {
      nodes {
        id
        artistName
        actualBitPrice
        audioLengthMs
        description
        editionsCount
        releaseAt
        fileByCoverId {
          url
        }
        fileByAudioId {
          songByAudioId {
            title
            slug
            remainingBits
            soldBits
            soldBitsInverted
            id
            description
          }
        }
        artistProfileByArtistId {
          slug
        }
      }
    }
  }
`;

export const GET_COMING_SOON = gql`
  query ComingSoon(
    $greaterThan: Datetime = ""
    $lessThanOrEqualTo: Datetime = ""
  ) {
    allSongs(
      filter: {
        status: { equalTo: PUBLISHED }
        releaseAt: {
          greaterThan: $greaterThan
          lessThanOrEqualTo: $lessThanOrEqualTo
        }
      }
    ) {
      nodes {
        id
        artistName
        actualBitPrice
        audioLengthMs
        description
        editionsCount
        fileByCoverId {
          url
        }
        fileByAudioId {
          songByAudioId {
            title
            slug
            remainingBits
            soldBits
            soldBitsInverted
            id
            description
          }
        }
        artistProfileByArtistId {
          slug
        }
      }
    }
  }
`;

export const GET_JUST_DROPPED = gql`
  query JustDropped(
    $greaterThan: Datetime = ""
    $lessThanOrEqualTo: Datetime = ""
  ) {
    allSongs(
      filter: {
        status: { equalTo: PUBLISHED }
        releaseAt: {
          greaterThan: $greaterThan
          lessThanOrEqualTo: $lessThanOrEqualTo
        }
      }
    ) {
      nodes {
        id
        artistName
        actualBitPrice
        audioLengthMs
        description
        editionsCount
        fileByCoverId {
          url
        }
        fileByAudioId {
          songByAudioId {
            title
            slug
            remainingBits
            soldBits
            soldBitsInverted
            id
            description
          }
        }
        artistProfileByArtistId {
          slug
        }
      }
    }
  }
`;

export const GET_SOLD_OUT = gql`
  query SoldOut {
    allSongs(
      filter: {
        status: { equalTo: PUBLISHED }
        soldBitsInverted: { equalTo: 0 }
      }
    ) {
      nodes {
        id
        artistName
        actualBitPrice
        audioLengthMs
        description
        editionsCount
        fileByCoverId {
          url
        }
        fileByAudioId {
          songByAudioId {
            title
            slug
            remainingBits
            soldBits
            soldBitsInverted
            id
            description
          }
        }
        artistProfileByArtistId {
          slug
        }
      }
    }
  }
`;

export const GET_USERS_COUNTRY = gql`
  query UsersCountry($id: UUID!) {
    userById(id: $id) {
      countryByCountry {
        circleProhibited
        name
      }
    }
  }
`;

// export const SUBSCRIPTION_REMAINIG = gql`
//   subscription GET_REMAINIG_BITS {
//     listen(topic: "song-ae73e12e-d409-4ba0-bf08-f6045d823ba8:nft-update") {
//       relatedNode {
//         ... on Song {
//           id
//           remainingBits
//         }
//       }
//     }
//   }
// `;

// export const GET_REMAINIG = gql`
//   query GET_REMAINING($slug: String!) {
//     songBySlug(slug: $slug) {
//       nftInstancesBySongId(
//         filter: {
//           parentId: { isNull: true }
//           remainingBits: { greaterThan: 0 }
//         }
//         first: 1
//       ) {
//         nodes {
//           id
//           remainingBits
//         }
//       }
//     }
//   }
// `;
