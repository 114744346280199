
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwtDecode from 'jwt-decode';
import { REFRESH_TOKEN } from 'modules/auth/graphql/Mutations.graphql';
import store from 'store/store';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

let pendingAccessToken: any = null;

async function getRefreshedToken() {
  try {
    const { data } = await graphqlClient.mutate({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken: store.getState().authentication.refreshToken },
    });
    if (data) {
      store
        .getActions()
        .authentication.setAccessToken(data.refreshToken.auth.accessToken);
      store
        .getActions()
        .authentication.setRefreshToken(data.refreshToken.auth.refreshToken);

      const decoded = jwtDecode(
        data.refreshToken.auth.accessToken,
      );

      store.getActions().authentication.setExpiryTime((decoded as any).exp);

      return data.refreshToken.auth.accessToken;
    }
  } catch (error) {
    store.getActions().authentication.setLogout();
    return error;
  }
}

async function getAccessToken() {
  const currentToken = store.getState().authentication.accessToken;
  const expiryTime = store.getState().authentication.expiryTime;
  const timeNow = Math.floor(Date.now() / 1000);
  const tokenValid = timeNow < Number(expiryTime);

  if (currentToken && tokenValid) {
    return new Promise((resolve) => resolve(currentToken));
  } else {
    if (!pendingAccessToken) {
      pendingAccessToken = getRefreshedToken().finally(
        () => (pendingAccessToken = null),
      );
      return pendingAccessToken;
    }
  }
}

const tokenLink = setContext(async (request, { headers }) => {
  if (store.getState().authentication.isAuthenticated) {
    if (request.operationName === "ResendVerificationEmail" || request.operationName === "AUTHENTICATE" || request.operationName === "REGISTER_USER") {
      return { headers: { ...headers } }
    }
    else if (request.operationName === "RefreshToken") {
      const accessToken = store.getState().authentication.accessToken;

      if (accessToken) return { headers: { ...headers } };
      //else console.log('Unable to retrieve Access Token');
    } else {
      const accessToken = await getAccessToken();

      if (accessToken) return { headers: { ...headers, Authorization: `Bearer ${accessToken}` } };
      //else console.log('Unable to retrieve Access Token');
    }

  }/* else {
    console.log('User is not authenticated');
  }*/
});

const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([tokenLink, httpLink]),
});

export default graphqlClient;

