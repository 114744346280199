import React, { FC, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import { useFormik } from 'formik';
import Yup from 'yup-extended';
import { InputWithIcon } from './styled/AddCardModal.styled';
import { CVVEncryption } from 'utils/encryption';

import { useAppSelector } from 'hooks/reduxToolkit';

interface ModalProps {
  last4?: string;
  type?: string;
  onEncryptedCvv: (encryptedCvv: string) => void;
  onClose: () => void;
  purchaseExpired?: () => void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  cvv: Yup.string()
    .required('CVV is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(3, 'Must be 3 or 4 digits')
    .max(4, 'Must be 3 or 4 digits'),
});

const CVVModal: FC<ModalProps> = ({
  onEncryptedCvv,
  onClose,
  last4,
  type,
  purchaseExpired,
}) => {
  const { expiryTime } = useAppSelector((state) => state.modalSlice);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      if (purchaseExpired) {
        purchaseExpired();
      }
    }
  }, []);

  const initialValues = useMemo(
    () => ({
      cvv: '',
    }),
    [],
  );

  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  function submitHandler() {
    setLoading(true);
    setShowError(false);

    if (Object.keys(errors).length !== 0) {
      // errors.cvv = 'CVV is required.';
      setLoading(false);
      setShowError(true);
      return;
    }

    const encryption = new CVVEncryption(values.cvv);

    encryption.secure().then((res) => {
      onEncryptedCvv(res.encryptedMessage);
      setLoading(false);
    });
  }

  return (
    <CustomComponentContainer>
      <CloseButton
        onClick={() => {
          onClose();
        }}>
        X
      </CloseButton>

      <Spacer height={10} />
      <CVVHeadingText
        text={`Please enter your CVV number of your card ending ${
          last4 ? last4 : ''
        }`}></CVVHeadingText>
      <Spacer height={10} />

      <InputContainer>
        <InputWithPadding
          height={70}
          type="password"
          placeholder="Enter CVV number"
          value={values.cvv}
          tabIndex={0}
          inputMode={'numeric'}
          onChange={handleChange('cvv')}
          error={
            (touched.cvv && errors.cvv) || showError ? errors.cvv : undefined
          }
        />
      </InputContainer>
      <Spacer height={10} />
      <WhiteButton
        label={type === 'addfunds' ? 'Submit' : 'Complete Purchase'}
        onClick={handleSubmit}
        height={45}
        borderRadius={50}
        fontSize={theme.fontSizes.fz16}
        isLoading={loading}
        borderColor={
          type === 'addfunds' ? theme.colors.white : theme.colors.yellow
        }
        bgColor={type === 'addfunds' ? theme.colors.white : theme.colors.yellow}
        labelColor={theme.colors.black}
      />
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const InputWithPadding = styled(InputWithIcon)`
  padding-left: 10px;
`;

const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  width: 99%;

  @media (min-with: 768px) {
    margin: initial;
  }
`;

const CVVHeadingText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  color: #fff;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.03em;
`;

export default CVVModal;
