import Button from 'common/components/Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import React, { FC, memo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';
import DatePicker from 'react-datepicker';
import 'common/styles/stylesheets/sb-datepicker.css';
import { useViewport } from 'use-viewport';
import Dropdown from '../../components/Dropdown/Dropdown';
import { TimeList_dropdown } from 'utils/artistAnalytics/schedule-times';

interface ConfirmSendMessageProps {
  isOpen: boolean;
  numSelectedUsers: number;
  onClose: () => void;
  onSchedule: (sendAt: string) => void;
}

type DropdownItem = {
  value: string | number;
  label: string;
};

const ScheduleMessage: FC<ConfirmSendMessageProps> = ({
  isOpen,
  numSelectedUsers,
  onClose,
  onSchedule,
}) => {
  const viewport = useViewport();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [selectedTime, setSelectedTime] = useState<DropdownItem>(
    TimeList_dropdown[0],
  );

  function getFormattedDateTime() {
    if (selectedDate && selectedTime) {
      const date_string = `${selectedDate.toLocaleDateString('en-CA')}T${
        selectedTime.value
      }:00`;

      return new Date(date_string).toISOString();
    }
  }

  function handleSubmit() {
    const scheduleTime = getFormattedDateTime();
    if (scheduleTime) onSchedule(scheduleTime);
  }

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top="10%"
      mobileTop="2%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text="schedule"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={50} />

          <Typography
            text={`Confirm date/time you wish to send this email to ${numSelectedUsers} user(s)?`}
            fontColor={theme.colors.yellow}
            fontSize="fz24"
            fontWeight="bold"
          />

          <Spacer height={80} />

          <InputContainer>
            <div>
              <CustomDatePicker
                selected={selectedDate}
                placeholderText={'Date to schedule'}
                onChange={(date: Date) => setSelectedDate(date)}
              />
            </div>

            <div>
              <TimeInput
                data={TimeList_dropdown}
                currentItem={selectedTime}
                onSelect={(item) => setSelectedTime(item)}
                width={360}
              />
            </div>

            <FirstButton
              height={45}
              width={357}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text="submit"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={handleSubmit}
            />
          </InputContainer>
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 357px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CustomDatePicker = styled(DatePicker)`
  width: 360px;

  .react-datepicker__input-container input {
    width: 360px !important;
  }
`;

const TimeInput = styled(Dropdown)`
  width: 360px !important;

  button {
    width: 360px !important;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  alignitems: center;
  gap: 25px;
`;

export default memo(ScheduleMessage);
