import { action } from 'easy-peasy';

import { PurchaseModel } from './purchaseModel.types';

export const purchase: PurchaseModel = {
  numberOfBits: null,
  costOfSelected: null,
  commission: null,
  
  setNumberOfBits: action((state, payload) => {
    state.numberOfBits = payload;
  }),

  setCostOfSelected: action((state, payload) => {
    state.costOfSelected = payload;
  }),

  setCommission: action((state, payload) => {
    state.commission = payload;
  }),

  resetPurchaseData: action((state) => {
    state.numberOfBits = null;
    state.costOfSelected = null;
    state.commission = null;
  }),

};
