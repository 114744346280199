import React, { FC } from 'react';

import { IconProps } from 'common/common.types';

const GoogleIcon: FC<IconProps> = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.103 7.998C19.226 8.657 19.293 9.346 19.293 10.065C19.293 15.689 15.529 19.688 9.844 19.688C8.55116 19.6884 7.27091 19.434 6.0764 18.9395C4.88189 18.4449 3.79654 17.7198 2.88236 16.8056C1.96818 15.8915 1.2431 14.8061 0.748528 13.6116C0.25396 12.4171 -0.000393722 11.1368 4.57452e-07 9.844C-0.000393722 8.55116 0.25396 7.27091 0.748528 6.0764C1.2431 4.88189 1.96818 3.79654 2.88236 2.88236C3.79654 1.96818 4.88189 1.2431 6.0764 0.748528C7.27091 0.25396 8.55116 -0.000393722 9.844 4.57452e-07C12.502 4.57452e-07 14.723 0.978001 16.427 2.566L13.652 5.341V5.334C12.619 4.35 11.308 3.845 9.844 3.845C6.596 3.845 3.956 6.589 3.956 9.838C3.956 13.086 6.596 15.836 9.844 15.836C12.791 15.836 14.797 14.151 15.209 11.837H9.844V7.998H19.104H19.103Z"
        fill="white"
      />
    </svg>
  );
};

export default GoogleIcon;
