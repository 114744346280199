import styled from 'styled-components';

import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import theme from 'theme/theme';

export const ResetPasswordText = styled(Typography)`
  text-decoration: underline;
`;

export const ResetPasswordButton = styled(Button)`
  position: absolute;
  width: 100px;
  bottom: -3px;
`;

export const Container = styled.div<{
  width?: number;
  height?: number;
  withBorder?: boolean;
  borderColor?: string;
  borderRadius?: string;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
  height: ${(props) => (props.height ? props.height + 'px' : 'auto')};
  border: ${(props) => (props.withBorder ? '1px solid' : 'none')};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : theme.colors.yellow};
  border-radius: ${(props) => (props.withBorder ? props.borderRadius : 'none')};
`;

export const Input = styled.input.attrs(() => ({
  required: true,
}))<{ small?: boolean; alignText?: string }>`
  border: none;
  height: 100%;
  font-size: ${(props) => (props.small ? '14px' : '18px')};
  font-family: HKGrotesk-Light, sans-serif;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.transparent};
  z-index: 4;

  ${(props) => (props.alignText ? 'text-align: center' : 'text-align: left')};
  ${(props) => (props.alignText ? 'padding: none' : 'padding: 0 0 0 5px')};

  &:focus {
    outline: none;
  }

  &::selection {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.yellow};
  }

  @media (min-width: 576px) {
    font-size: 18px;
  }
`;

export const Label = styled.label<{ value?: string }>`
  top: 50%;
  left: 10px;
  opacity: 50%;
  position: absolute;
  pointer-events: inherit;
  transition: 0.2s ease all;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.white};

  input:focus ~ &,
  input:not(:placeholder-shown).input:not(:focus) ~ .label,
  input:valid ~ & {
    top: 0;
    left: -15px;
    transform: scale(0.8);
  }
`;

export const BottomLine = styled.div`
  bottom: 0;
  height: 1px;
  width: 105%;
  position: absolute;
  opacity: 0.5;
  margin-left: -5px;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
`;

export const ErrorContainer = styled.div<{
  withBorder?: boolean;
  positionLeft?: string;
  positionBottom?: string;
}>`
  position: absolute;
  bottom: ${(props) => (props.positionBottom ? props.positionBottom : '2px')};
  left: ${(props) => (props.positionLeft ? props.positionLeft : 0)};

  display: flex;
  align-items: flex-end;
  ${(props) => props.withBorder && 'padding-left: 5px;'};
`;

export const ResetPasswordContainer = styled.div`
  position: relative;
`;
