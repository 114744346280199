import { action } from 'easy-peasy';

import { GlobalModel } from 'store/models/globalModel/globalModel.types';

export const globalbanner: GlobalModel = {
  show_global_banner: false,
  title: '',
  text: '',
  setGlobalBanner: action((state, payload) => {
    state.title = payload.title;
    state.text = payload.text;
    state.show_global_banner = true
  }),
  hideGlobalBanner: action((state) => {
    state.show_global_banner = false
    state.title = '';
    state.text = '';
  }),
};
