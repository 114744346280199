import React, { memo, useState, useEffect, useRef } from 'react';
import { useStoreState } from 'store/store';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Spacer from 'common/components/Spacer/Spacer';
import HamburgerClose from 'common/icons/HamburgerClose.icon';
import MenuItem from 'common/components/MenuItem/MenuItem';
import Intercom from 'common/icons/Intercom.icon';
import { useIntercom } from 'react-use-intercom';

import Button from 'common/components/Button/Button';
import {
  BorderContainer,
  Container,
  NavButton,
  ProfileIconContainer,
  TypographyMenu,
} from './styled/HamburgerMenu.styled';

import {
  authedMenuLinks,
  unauthedMenuLinks,
  authedMenuArtistLinks,
  unauthedMenuClaimLinks,
} from 'utils/menuLinks';
import { IMenuItem } from 'typings/types';
import ArrowToDown from '../icons/ArrowToDown.icon';
import styled from 'styled-components';
import Typography from '../components/Typography/Typography';
import theme from '../../theme/theme';
import Telegram from '../icons/Telegram.icon';
import TikTok from '../icons/TikTokFooter.icon';
import InstagramSmallWhite from '../icons/InstagramSmallWhite.icon';
import Discord from '../icons/Discord.icon';

import { md, sm } from 'utils/constants';

type HamburgerMenuProps = {
  isHamburgerOpen: boolean;
  setHamburgerOpen: (hamburgerOpen: boolean) => void;
};

const HamburgerMenu = ({
  isHamburgerOpen = false,
  setHamburgerOpen,
}: HamburgerMenuProps) => {
  const location = useLocation();
  const { show, boot } = useIntercom();

  const { isAuthenticated } = useStoreState((state) => state.authentication);
  const isArtist = useStoreState((state) => state.authentication.isArtist);
  const [navLinks, setNavLinks] = useState<IMenuItem[]>([]);
  const [showBottomArrow, setShowBottomArrow] = useState<boolean>(true);

  const menuRef = useRef<any>(null);


  useEffect(() => {
    if (isAuthenticated) {
      let tempNavLinks = [];
      if (isArtist) {
        tempNavLinks = [...authedMenuArtistLinks];
      } else {
        tempNavLinks = [...authedMenuLinks];
      }

      setNavLinks(tempNavLinks);
    } else {
      if (location.pathname.includes('giveaway')) {
        setNavLinks(unauthedMenuClaimLinks);
      } else {
        setNavLinks(unauthedMenuLinks);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setInterval(() => {
      if (menuRef.current) {
        const scrollWidth =
          menuRef.current.scrollHeight - menuRef.current.offsetHeight;
        const hasVerticalScrollbar =
          menuRef.current.scrollHeight > menuRef.current.clientHeight;
        const isBottom = scrollWidth <= menuRef.current.scrollTop;
        if (hasVerticalScrollbar && !isBottom) {
          setShowBottomArrow(true);
        } else {
          setShowBottomArrow(false);
        }
      }
    }, 500);
  }, []);

  function toggleIntercom() {
    boot();
    show();
  }

  return (
    <BorderContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      isHamburgerOpen={isHamburgerOpen}>
      <Container ref={menuRef}>
        <div
          style={{
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <TypographyMenu
              text="menu"
              fontSize="fz100"
              fontWeight="bold"
              letterSpacing="-0.03em"
            />
            <ProfileIconContainer>
              <NavButton
                label={<HamburgerClose />}
                onClick={() => {
                  setHamburgerOpen(false);
                }}
              />
            </ProfileIconContainer>
          </div>
          <Spacer height={30} />

          {navLinks.map((item, index) => (
            <MenuItem
              key={`Main menu item - ${item.text} - ${index}`}
              item={item}
              isLast={navLinks.length - 1 === index}
              isFirst={index === 0}
              onLinkClick={() => {
                setHamburgerOpen(false);
              }}
            />
          ))}
        </div>
      </Container>
      <div
        style={{
          position: 'relative',
          marginTop: 'auto',
          padding: '10px 30px 20px 48px',
        }}>
        <ArrowToDownSection
          className={showBottomArrow ? 'show' : ''}
          onClick={() => {
            menuRef.current.scrollTo({
              top: menuRef.current.offsetHeight,
              behavior: 'smooth',
            });
          }}>
          <ArrowToDown stroke={'#FFD600'} width={27} />
        </ArrowToDownSection>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}>
            <SocialIcon
              href="https://twitter.com/SongBitsfans"
              target="_blank"
              rel="noreferrer">
              <Telegram />
            </SocialIcon>
            <SocialIcon
              href="https://discord.gg/GHBH9YhjDU"
              target="_blank"
              rel="noreferrer">
              <Discord />
            </SocialIcon>
            <SocialIcon
              href="https://www.tiktok.com/@songbits.fans"
              target="_blank"
              rel="noreferrer">
              <TikTok />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/songbits.fans/"
              target="_blank"
              rel="noreferrer">
              <InstagramSmallWhite />
            </SocialIcon>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'right',
              textAlign: 'right',
            }}>
            <IntercomContainer
              label={<Intercom width={52} height={52} />}
              onClick={() => {
                toggleIntercom();
              }}
            />
          </div>
        </div>
        <CopyRightsContainer>
          <Typography
            text={
              '© ' +
              new Date().getFullYear().toString() +
              ' SongBits Ltd. All Rights Reserved.'
            }
            fontSize={'fz9'}
            fontColor={theme.colors.white}></Typography>
          <Typography
            text={'|'}
            fontSize={'fz9'}
            fontColor={theme.colors.white}></Typography>
          <CopyRightsLink fontSize={'fz9'} to="/legal">
            Legal & Copyright
          </CopyRightsLink>
        </CopyRightsContainer>
      </div>
    </BorderContainer>
  );
};

const ArrowToDownSection = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0, -100%);
  width: 100%;
  padding: 20px 0 10px 0;
  text-align: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 22.92%);
  cursor: pointer;
  display: none;

  &:hover {
    svg {
      opacity: 0.7;
    }
  }

  &.show {
    display: block;
  }
`;

export const CopyRightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;

  gap: 5px;
  width: 100%;
  text-align: left;
`;

export const CopyRightsLink = styled(Link)<{ fontSize?: string }>`
  color: #fff;
  font-size: 9px;
  text-decoration: none;
`;

export const CopyRights = styled(Typography)`
  width: 100%;
  text-align: center;

  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
`;

export const IntercomContainer = styled(Button)`
  padding: 0;

  z-index: 2;

  &:hover {
    background-color: rgb(0, 0, 0, 0);
  }

  @media (min-width: ${sm}px) {
    bottom: 75px;
    right: 30px;
  }

  @media (min-width: ${md}px) {
    right: 100px;
  }
`;

export const SocialIcon = styled.a`
  opacity: 0.5;
  transition: 0.3s linear;
  height: 18px;
  width: auto;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export default memo(HamburgerMenu);
