import React, { FC } from 'react';
import { useViewport } from 'use-viewport';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStoreActions } from 'store/store';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import {
  CloseButton,
  ModalContainer,
  ModalContentContainer,
  Title,
} from './index.styled';

import {
  CreateButton,
  CreateButtonContent,
  LoginButton,
  LoginNow
} from './styled/SignInModal.styled';
import Spacer from 'common/components/Spacer/Spacer';


interface ModalProps {
  onClose: () => void;
  artistId?: string;
}

const SignInModal: FC<ModalProps> = ({ onClose, artistId }) => {
  const viewport = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const setReturnUrl = useStoreActions(
    (actions) => actions.location.setPreviousUrl,
  );

  const navigateTo = (link: string) => () => {
    console.log(link)
    onClose();
    setReturnUrl(location.pathname)
    navigate(link, { replace: true });
  };


  return (
    <ModalContainer>
      <CloseButton onClick={() => onClose()}>X</CloseButton>
      <Title
        text="sign in"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz60' : 'fz36'}
      />

      <Typography text="Sign in to continue" />

      <ModalContentContainer>
        <CreateButton
          label={
            <CreateButtonContent
              text="Create Account"
              fontSize="fz18"
              fontColor={theme.colors.black}
              fontWeight="bold"
            />
          }
          borderRadius={40}
          onClick={navigateTo(artistId ? '/create-account?artist=' + artistId : '/create-account')}
        />

        {viewport.width >= 576 ? <>
        <LoginButton
          label={<><Typography text="Already have a SongBits Account? " /><LoginNow text="Login Now" /></>}
          onClick={navigateTo('/login')}
        />
        </> : <>
        <LoginButton
          label={<><Typography text="Already have a SongBits Account? " /></>}
          onClick={navigateTo('/login')}
        />
        <Spacer height={10} />
        <LoginButton
          label={<><LoginNow text="Login Now" /></>}
          onClick={navigateTo('/login')}
        />
        </>}
      </ModalContentContainer>
    </ModalContainer>
  );
};

export default SignInModal;
