import React, { useMemo } from 'react';
import { useViewport } from 'use-viewport';

import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksMobile from 'common/components/QuickLinksMobile/QuickLinksMobile';

import PageLayout from 'common/components/PageLayout/PageLayout';


import { termsData } from '../../../utils/termsContent';
import '../../../theme/termsStyles.scss';

const Legal = () => {
  const viewport = useViewport();
  const quickLinks = useMemo(
    () => [
      {
        title: 'Privacy Policy',
        link: '/legal/privacy-policy',
      },
      {
        title: 'Cookie Policy',
        link: '/legal/cookie-policy',
      },
      /*{
        title: 'Risk Factors',
        link: '/legal/risks',
      },*/
      /*{
        title: 'Community Guidelines',
        link: '/legal/community-guidelines',
      },*/
      {
        title: 'Company',
        link: '/company',
      },
    ],
    [],
  );

  const contentSection = (
    <>
      {viewport.width <= 767 ? (
        <QuickLinksMobile links={quickLinks} loading={false}></QuickLinksMobile>
      ) : (
        <></>
      )}

      <div
        className="terms"
        style={{ color: 'white' }}
        dangerouslySetInnerHTML={{ __html: termsData }}></div>
    </>
  );

  return (
    <PageContainer pageTitle='Terms | SongBits'>
      <PageLayout
        title="Terms & Conditions"
        is2ColumnLayout
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <>
                {viewport.width <= 767 ? (
                  <></>
                ) : (
                  <QuickLinks links={quickLinks}></QuickLinks>
                )}
              </>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export default Legal;
