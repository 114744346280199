import React, { FC, useState, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import MetamaskClient from 'services/web3.client';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnected: () => void;
  header: JSX.Element;
  costOfSelected: string;
}

enum ConnectWalletStates {
  ConnectToMetamask,
  MetamaskRequired,
  UnsupportedBrowser,
  ConnectionSuccessful,
  USDCTooLow,
}

const ConnectWallet: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onConnected,
  header,
  costOfSelected,
}) => {
  const viewport = useViewport();
  const dispatch = useAppDispatch();

  const metamaskClient = new MetamaskClient(
    process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID,
  );

  const [connectWalletState, setConnectWalletState] =
    useState<ConnectWalletStates>(ConnectWalletStates.ConnectToMetamask);

  useEffect(() => {
    if (isOpen) {
      dispatch(setModalType('ConnectWallet'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  async function connectWallet() {
    metamaskClient.connect().then(() => {
      metamaskClient.getBalanceUSDC().then(() => {
        const usdc_bal = parseFloat(metamaskClient.balance_USDC);
        if (usdc_bal < parseFloat(costOfSelected)) {
          setConnectWalletState(ConnectWalletStates.USDCTooLow);
        } else {
          onConnected();
        }
      });
    });
  }

  const connectToMetaMask = () => {
    return (
      <>
        <Spacer height={70} />
        <PaymentRow>
          <FirstButton
            height={45}
            borderRadius={50}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
            label={
              <TextContent
                text="Connect to MetaMask"
                fontSize="fz16"
                fontWeight="bold"
                fontColor={theme.colors.black}
              />
            }
            onClick={connectWallet}
          />
        </PaymentRow>
      </>
    );
  };

  const metamaskRequired = () => {
    return (
      <Column>
        <Spacer height={40} />
        <FirstButton
          height={45}
          borderRadius={50}
          borderColor={theme.colors.yellow}
          labelColor={theme.colors.black}
          label={
            <TextContent
              text="MetaMask Required"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          }
          onClick={() => {}}
        />
        <Typography text="How to install and use MetaMask" fontWeight="light" />
      </Column>
    );
  };

  const unsupportedBrowser = () => {
    return (
      <Column>
        <Spacer height={40} />
        <FirstButton
          height={45}
          borderRadius={50}
          borderColor={theme.colors.yellow}
          labelColor={theme.colors.black}
          label={
            <TextContent
              text="Non-Supported Browser"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          }
          onClick={() => {}}
        />
        <Typography text="View Support Article" fontWeight="light" />
      </Column>
    );
  };

  const successfulConnection = () => {
    return (
      <Column>
        <Spacer height={20} />
        <Typography text="Connection Successful" fontWeight="light" />
        <FirstButton
          className="confirm-crypto-payment-button"
          height={45}
          borderRadius={50}
          bgColor={theme.colors.yellow}
          labelColor={theme.colors.black}
          label={
            <TextContent
              text="Confirm Payment"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.black}
            />
          }
          onClick={() => {}}
        />
      </Column>
    );
  };

  const insufficientUSDC = () => {
    return (
      <Column>
        <Spacer height={10} />
        <Typography text="USDC balance too low" fontWeight="light" />
        <FirstButton
          height={45}
          borderRadius={50}
          bgColor={theme.colors.yellow}
          labelColor={theme.colors.black}
          label={
            <TextContent
              text="Try Again"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.black}
            />
          }
          onClick={() => {}}
        />
        {/*<FirstButton
          height={45}
          borderRadius={50}
          borderColor={theme.colors.yellow}
          labelColor={theme.colors.yellow}
          label={
            <TextContent
              text="Contact Support"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          }
          onClick={() => {}}
        />*/}
      </Column>
    );
  };

  function getModalContent() {
    if (connectWalletState === ConnectWalletStates.ConnectToMetamask) {
      return connectToMetaMask();
    } else if (connectWalletState === ConnectWalletStates.MetamaskRequired) {
      return metamaskRequired();
    } else if (connectWalletState === ConnectWalletStates.UnsupportedBrowser) {
      return unsupportedBrowser();
    } else if (
      connectWalletState === ConnectWalletStates.ConnectionSuccessful
    ) {
      return successfulConnection();
    } else if (connectWalletState === ConnectWalletStates.USDCTooLow) {
      return insufficientUSDC();
    }
  }

  return (
    <SBModal
      isOpen={isOpen}
      withProceedingText={true}
      withFeeText={true}
      withSettings={false}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top="10%"
      mobileTop="2%"
      content={
        <>
          <CloseButton
            onClick={() => {
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text="crypto payment"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          {getModalContent()}
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  margin-left: -5px;

  @media (min-width: 576px) {
    min-width: 350px;
  }
`;

const PaymentRow = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export default ConnectWallet;
