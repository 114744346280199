export const paypalTermsData = `<p class="c9">
<p class="c4"><span class="c3"></span></p>
<p class="c9"><span class="c3">Last Updated: August 25, 2023</span></p>
<p class="c4"><span class="c3"></span></p>
<p class="c9"><span class="subtitle-coloured">Terms &amp; Conditions</span></p>
<p class="c4"><span class="c3"></span></p>
<p class="c9"><span>By using the "Login with PayPal"/"Connect with PayPal" features of <span class="c10"><a class="c14" href="https://songbits.com" target="_blank">https://songbits.com</a></span> you agree that:</span></p>
<ol class="c2 lst-kix_o7rcp3uhhhar-0 start" start="1">
   <li class="c0 li-bullet-0"><span>We may use your personal data from PayPal to create a user account with SongBits, and subsequently login to SongBits.</span><p class="c4"><span class="c3"></span></p></li>
   <li class="c0 li-bullet-0"><span>We may use your PayPal unique account ID to initiate a withdrawal of funds from your SongBits account directly to your PayPal account.</span></li>
</ol>
<p class="c4"><span class="c3"></span></p>`
