import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import { useStoreActions } from 'store/store';

import Card from 'common/icons/Card.icon';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import restService from 'services/rest.service';

interface ModalProps {
  onClose: () => void;
  onCardData: () => void;
  onNoAvailableCards: () => void;
  country: string;
}

const CryptoNotAvailableModal: FC<ModalProps> = ({
  onClose,
  onCardData,
  onNoAvailableCards,
  country
}) => {
  const viewport = useViewport();


  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );


  return (
    <>
      <CloseButton onClick={onClose}>X</CloseButton>
      <BoldText
        text="crypto payment"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <Spacer height={30} />
        <BoldText
          text="Crypto purchase not available!"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          fontSize="fz24"
        />

        <Spacer height={3} />
        <TextContent
          text={
            <TextContentInnerContainer>
              Since you currently reside in{' '}
              <TextContentBold>{country}</TextContentBold> state - we are unable
              to process purchases for residents of that US state.
            </TextContentInnerContainer>
          }
          fontSize="fz14"
          fontColor={theme.colors.white}
        />

        <Spacer height={30} />
        <ModalText
          text="If this was an error, please contact support or use card payment below instead."
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.white}
        />

        <PaymentOptionsContainer>
        <PaymentOption
          onClick={() => {
            restService
              .getCardsList()
              .then((res) => {
                const numCards = res.length;

                if (numCards > 0) {

                  if (onCardData) {
                    onCardData();
                  }
                } else {
                  // Pop add card modal
                    onNoAvailableCards();
                }
              })
              .catch((error) => {
                setGlobalBanner({
                  title: 'Failed to retrieve cards:',
                  text: error.message,
                });
              });
          }}>
          <IconContainer>
            <Card />
          </IconContainer>
          <TextContent
            fontSize={viewport.width < 576 ? 'fz16' : 'fz18'}
            fontColor={theme.colors.white}
            text="Buy using credit/debit card"
          />
        </PaymentOption>
        </PaymentOptionsContainer>
    </>
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;


export const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.023em;
  font-size: 18px;
  font-family: 'HKGrotesk-Light';
  font-weight: 300;
`;

export const TextContentInnerContainer = styled.div``;

export const TextContentBold = styled.span`
  font-weight: bold;
  font-family: 'HKGrotesk-Black';
`;


const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 30px;
`;

const PaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  text-align: center;
  max-width: 130px;
  line-height: 30px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 63px;
  display: flex;
  justify-content: center;
  transform: scale(0.7);

  @media (min-width: 576px) {
    transform: none;
  }
`;

const ModalText = styled(Typography)`
  line-height: 24px;
`;



export default CryptoNotAvailableModal;
