import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import SBModal from 'common/components/Modal/SBModal';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';

interface PaymentModalProps {
  isOpen: boolean;
  header: JSX.Element;
  onClose: () => void;
}

const PurchaseExpired: FC<PaymentModalProps> = ({
  isOpen,
  header,
  onClose,
}) => {
  const viewport = useViewport();

  useEffect(() => {
    if (isOpen) {
      (window as any).gtag("event", "purchase_expired", {});
    }
  }, [isOpen])

  return (
    <SBModal
      isOpen={isOpen}
      withSettings={false}
      width="434px"
      mobileWidth="100%"
      height="662px"
      top="10%"
      mobileTop="2%"
      content={
        <>
          <CloseButton onClick={onClose}>X</CloseButton>
          <BoldText
            text="expired"
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          <Spacer height={10} />
          <Typography
            text={
              <>
                <InlineText>Time Expired!</InlineText> Please close this{'\n'}
                window and make a new selection
              </>
            }
            fontSize={viewport.width < 576 ? 'fz20' : 'fz24'}
            fontWeight="bold"
            letterSpacing="-1px"
          />
          <Spacer height={20} />
          <Column>
            <Spacer height={50} />
            <FirstButton
              height={45}
              borderRadius={50}
              borderColor={theme.colors.yellow}
              labelColor={theme.colors.white}
              label={
                <TextContent
                  text="close"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              }
              onClick={() => {
                // Clear store
                onClose();
              }}
            />
          </Column>
        </>
      }
    />
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  min-width: 300px;
  margin-left: -5px;
  border-width: 1px;

  @media (min-width: 576px) {
    min-width: 357px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  left: 370px;
  font-size: 20px;
  cursor: pointer;
`;

const InlineText = styled.span`
  color: ${theme.colors.yellow};
`;

export default PurchaseExpired;
