import { useFormik } from 'formik';
import React, { FC, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import Yup from 'yup-extended';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';

import restService from 'services/rest.service';
import MetamaskClient from 'services/web3.client';

import TextInput from 'common/components/TextInput/TextInput';
import Wallet from 'common/icons/Wallet.icon';
import Copy from '../../../assets/copy-icon.png';
import MetaMask_Icon from '../../../assets/metamask-icon.png';
import { escToClose } from 'utils/functions';
import { useStoreActions, useStoreState } from 'store/store';
import { isBrowserSupported } from 'utils/browser-check';
import { ExportModalType } from 'modules/account/types';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { v4 as uuidv4 } from 'uuid';
import { BeatLoader } from 'react-spinners';

const VALIDATION_SCHEMA = Yup.object().shape({
  address: Yup.string()
    .isWalletAddress('Wallet address appears to be incorrect. Please re-check')
    .required('Wallet Address is required'),
  code: Yup.string()
    .min(6, 'code is too short. must be 6/7 chars')
    .max(7, 'code is too long. must be 6/7 chars')
    .required('code is required'),
});

interface GiftBitsModalProps {
  onChange: (showConfirmModal?: boolean) => void;
  nftId: string;
  image: string;
  songName: string;
  artistName: string;
  bits: string;
  reloadAPI: () => void;
  type: ExportModalType;
}

const ExportBitsModal: FC<GiftBitsModalProps> = ({
  onChange,
  nftId,
  image,
  songName,
  artistName,
  bits,
  reloadAPI,
  type,
}) => {
  const viewport = useViewport();
  const accessToken = useStoreState(
    (state) => state.authentication.accessToken,
  );

  const [stateConnectedAccount, setStateConnectedAccount] =
    useState<boolean>(false);
  const [connectedAddress, setConnectedAddress] = useState<string>('');
  const [exported, setExported] = useState<boolean>(false);

  const [contractAddress, setContractAddress] = useState<string>('');
  const [nftTokenId, setNftTokenId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [minting, setMinting] = useState<boolean>(false);

  const [addressCopied, setAddressCopied] = useState<boolean>(false);
  const [tokenCopied, setTokenCopied] = useState<boolean>(false);

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const metamaskClient = new MetamaskClient(
    process.env.REACT_APP_METAMASK_MINT_NETWORK_ID,
    '/library/my-collection?nft=' + nftId,
  );

  const initialValues = useMemo<{
    address: string;
    code: string;
  }>(
    () => ({
      address: '',
      code: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  useEffect(escToClose(handleClose), []);

  async function connectAccount() {
    if (!stateConnectedAccount) {
      metamaskClient
        .connect('nft_auto')
        .then(() => {
          setStateConnectedAccount(true);
          setConnectedAddress(metamaskClient.connected_wallet);
          values.address = metamaskClient.connected_wallet;
        })
        .catch((error) => {
          console.error(error);
          setGlobalBanner({
            title: 'Failed to connect wallet: ',
            text: error.message,
          });
          setStateConnectedAccount(false);
          setConnectedAddress('');
        });
    }
  }

  function submitHandler() {
    if (type === 'royalty') {
      exportBits();
    } else {
      exportNFTImage();
    }
  }

  function exportNFTImage() {
    setLoading(true);
    restService
      .exportNFTImage({ id: nftId, code: values.code.replace(' ', '') })
      .then(() => {
        reloadAPI();
        setMinting(true);
        setLoading(false);
      })
      .catch((error) => {
        error;
        errors.code = 'Invalid code';
        setLoading(false);
      });
  }

  function exportBits() {
    setLoading(true);

    restService
      .exportBit({
        id: nftId,
        code: values.code.replace(' ', ''),
      })
      .then(() => {
        reloadAPI();
        setMinting(true);
        setLoading(false);
      })
      .catch((error) => {
        error;
        //if (error.message.includes('users_username_key')) {
        errors.code = 'Invalid code';

        setLoading(false);
      });
  }

  function handleClose() {
    onChange();
  }

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text);

    if (type === 'address') {
      setAddressCopied(true);
    }
    if (type === 'token') {
      setTokenCopied(true);
    }
  };

  useEffect(() => {
    if (addressCopied) {
      setTimeout(() => {
        setAddressCopied(false);
      }, 3000);
    }
    if (tokenCopied) {
      setTimeout(() => {
        setTokenCopied(false);
      }, 3000);
    }
  }, [addressCopied, tokenCopied]);

  const wsURL = process.env.REACT_APP_REST_URL?.replace('https', 'wss') + '/ws';

  const { sendJsonMessage } = useWebSocket(wsURL, {
    share: true,
    onOpen: () => {
      
      if (accessToken) {
        sendJsonMessage({
          message_id: uuidv4(),
          command: 'authenticate',
          params: {
            token: accessToken,
          },
        });
      }
    },
    onClose: () => {},
    onMessage: (message) => {
      const messageData = JSON.parse(message.data);

      let status = 'pending';
      try {
        status = messageData.event.data.blockchain.status;
      } catch {}

      if (status === 'processed') {
        setContractAddress(messageData.event.data.blockchain.contract_address);
        setNftTokenId(messageData.event.data.blockchain.nft_token_id);
        setExported(true);
      }
    },
  });

  const initialContent = (
    <>
      {stateConnectedAccount === true ? (
        <InfoContainer>
          <Typography text="Your Wallet Address" />
          <Spacer height={20} />
          <WalletAddress>
            <Wallet />
            <WalletAddressText text={connectedAddress} />
          </WalletAddress>
          <Spacer height={25} />
          <Typography
            text="Enter the code for SongBits from the Google authenticator app on your device"
            lineHeight="24px"
          />
          <InputContainer>
            <Icon>
              <Typography text={'Code: '} />
            </Icon>
            <InputWithLabel
              height={viewport.width >= 576 ? 70 : 50}
              type="text"
              value={values.code}
              placeholder="Enter Code"
              onChange={handleChange('code')}
              error={
                Boolean(errors.code && touched.code) ? errors.code : undefined
              }
            />
          </InputContainer>
          <Spacer height={15} />
          <SubmitButton
            width={172}
            borderRadius={50}
            height={45}
            borderColor={theme.colors.white}
            isLoading={loading}
            label={
              <SubmitButtonContent justifyContent="center">
                <BoldText
                  text="export "
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.white}
                />
                <BoldText
                  text="bits"
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                />
              </SubmitButtonContent>
            }
            onClick={() => {
              handleSubmit();
            }}
          />
        </InfoContainer>
      ) : isBrowserSupported() ? (
        <>
          <ConnectionContainer>
            <Spacer height={10} />
            <Typography text="Export your bits as an NFT to MetaMask" />

            <Spacer height={30} />

            <SubmitButton
              width={278}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <SubmitButtonContent>
                  <MetaMaskIcon src={MetaMask_Icon} width={27} />
                  <ConnectLabel text="Connect Wallet" fontSize="fz18" />
                </SubmitButtonContent>
              }
              onClick={connectAccount}
            />

            <Spacer height={15} />

            <DownloadLink href="https://metamask.io/download/" target="_blank">
              Download or Install MetaMask
            </DownloadLink>
          </ConnectionContainer>
        </>
      ) : (
        <>
          <ConnectionContainer>
            <Typography text="Non-Supported Browser" fontWeight="bold" />

            <Spacer height={25} />
            <Typography
              text={
                <>
                  <span
                    style={{
                      lineHeight: '24px',
                      fontFamily: 'HKGrotesk-Light',
                    }}>
                    Please use
                    <UnderlineText
                      target={'_blank'}
                      href="https://www.google.com/chrome/">
                      Chrome
                    </UnderlineText>
                    or
                    <UnderlineText target={'_blank'} href="https://brave.com/">
                      Brave
                    </UnderlineText>
                    or visit songbits.com on a mobile with the MetaMask app
                    installed.
                  </span>
                </>
              }
            />
          </ConnectionContainer>
        </>
      )}
    </>
  );

  const mintingContent = (
    <>
      <Typography text="Code Valid!" fontSize="fz24" fontWeight="bold" />
      <Spacer height={15} />
      <Typography
        text="Now minting your NFT."
        fontSize="fz18"
        fontWeight="regular"
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          paddingTop: '80px',
        }}>
        <BeatLoader color="white" loading={true} />
      </div>
    </>
  );

  const exportedContent = (
    <InfoContainer width="360px">
      <Typography text="Success!" fontSize="fz24" fontWeight="bold" />
      <Spacer height={10} />
      {type === 'royalty' ? (
        <SuccessInfo text="Import the NFT by manually copying the following data via 'Import Token' in your Meta Mask Wallet" />
      ) : (
        <SuccessInfo text="Import the Image NFT by manually copying the following data via 'Import Token' in your Meta Mask Wallet" />
      )}
      <Spacer height={10} />
      <Row>
        <TransactionContent text="Address: " fontWeight="bold" />
        <TransactionText text={contractAddress} />
        <CopyButton>
          <img
            src={Copy}
            onClick={() => copyToClipboard(contractAddress, 'address')}
          />
        </CopyButton>
      </Row>

      {addressCopied ? (
        <Typography
          text={'copied!'}
          fontWeight="light"
          fontColor="yellow"
          fontSize="fz14"
        />
      ) : (
        <Spacer height={5} />
      )}
      <Spacer height={addressCopied ? 10 : 18} />

      <Row>
        <TransactionContent text="NFT Token ID: " fontWeight="bold" />
        <NFTText text={nftTokenId} />
        <CopyButton>
          <img
            src={Copy}
            onClick={() => copyToClipboard(nftTokenId, 'token')}
          />
        </CopyButton>
      </Row>
      {tokenCopied ? (
        <Typography
          text={'copied!'}
          fontWeight="light"
          fontColor="yellow"
          fontSize="fz14"
        />
      ) : (
        <Spacer height={5} />
      )}
    </InfoContainer>
  );

  const getModalContent = () => {
    if (exported === true) {
      return exportedContent;
    } else if (minting) {
      return mintingContent;
    } else {
      return initialContent;
    }
  };

  return (
    <ExportModal>
      <ModalHeader>
        <CloseButton onClick={handleClose}>X</CloseButton>
        <BoldText
          text={type === 'royalty' ? 'export bits' : 'export image'}
          fontWeight="bold"
          fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
        />
        <Spacer height={20} />

        <SongContainer>
          <SongImageContainer>
            <SongImage src={image} />
          </SongImageContainer>
          <SongInfo>
            <ArtistName text={artistName} fontColor={theme.colors.yellow} />
            <SongName text={songName} />
            <BitsNumber text={bits + ' bits'} fontColor={theme.colors.yellow} />
          </SongInfo>
        </SongContainer>
      </ModalHeader>

      <ModalContent>{getModalContent()}</ModalContent>

      <ModalFooter>
        {stateConnectedAccount !== true ? (
          <>
            <HelpLink href="https://metamask.io/faqs/" target="_blank">
              Need help with MetaMask?
            </HelpLink>
            <Spacer height={25} />
          </>
        ) : (
          <></>
        )}
        <Typography
          text="By proceeding, I agree to SongBits Terms of Service and acknowledge that this transaction is irreversible and nonrefundable."
          fontSize="fz10"
        />
      </ModalFooter>
    </ExportModal>
  );
};

export const TransactionText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CopyButton = styled.button`
  background-color: rgb(0, 0, 0, 0);
  border: none;
  margin-left: auto;
  cursor: pointer;
`;

export const TransactionContent = styled(Typography)`
  min-width: 143px;
  letter-spacing: -1px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const SuccessInfo = styled(Typography)`
  line-height: 25px;
`;

const WalletAddress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  width: 100%;
`;

const ModalContent = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Icon = styled.div`
  position: absolute;
  bottom: 28px;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: auto;
`;

const MetaMaskIcon = styled.img`
  margin-left: 10px;
`;

export const ModalHeader = styled.div``;

const DownloadLink = styled.a`
  text-decoration: none;
`;

const HelpLink = styled.a`
  font-size: 18px;
  text-decoration: none;
`;

export const SongContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const SongImageContainer = styled.div`
  width: 90px;
  height: 90px;

  overflow: hidden;
`;

export const SongImage = styled.img`
  height: 90px;
`;

export const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
`;

export const ArtistName = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const SongName = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const BitsNumber = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const ExportModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ConnectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const InfoContainer = styled.div<{ width?: string }>`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 576px) {
    width: ${(props) => (props.width ? props.width : '100%')};
  }
`;

export const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

export const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const SubmitButton = styled(Button)<{ borderWidth?: number }>`
  padding: 2px 15px;
  margin-left: 0;
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : 2)}px;
`;

const SubmitButtonContent = styled.div<{ justifyContent?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
`;

const ConnectLabel = styled(Typography)`
  font-family: 'HKGrotesk-Regular';
  font-weight: 900;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.03em;
  margin: auto;
`;

const NFTText = styled(TransactionText)`
  // transform: translateX(-35px);
`;

const UnderlineText = styled.a`
  text-decoration: underline;
  margin-left: 5px;
  margin-right: 5px;
  font-family: HKGrotesk-Light;
`;

const InputWithLabel = styled(TextInput)`
  margin-left: 60px;
  width: 100%;
  font-size: 18px;

  & > input {
    margin-top: -7px;
    padding: 0px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const WalletAddressText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ExportBitsModal;
