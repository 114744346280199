import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';

import PageLayout from 'common/components/PageLayout/PageLayout';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import PageContainer from 'common/layout/PageContainer';
import Tip from 'common/components/Tip/Tip';
import BottomLine from 'common/components/BottomLine/BottomLine';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';

import { md, sm } from 'utils/constants';

import restService from 'services/rest.service';
import { IRoyaltyOverviewData } from 'typings/account';
import RoyaltyDashboardOverview from 'common/components/Placeholder/Royalty/RoyaltyDashboardOverview';

// import { MONTHS } from 'utils/constants';
import Button from 'common/components/Button/Button';
import { ProfileImageContainer } from 'modules/artist/pages/Dashboard/ArtistDashboard.styled';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';
import ImagePicker from 'common/components/ImagePicker/ImagePicker';
import { WidthSpacer } from 'modules/library/pages/styled/ArtistProfile.styled';
import OpenYellow from 'common/icons/OpenYellow.icon';
import { Link } from 'react-router-dom';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

import {roundNumberDown} from 'utils/functions'

const quickLinks = [
  { title: 'Account Settings', link: '/account/settings' },
  {
    title: 'My Wallet',
    link: '/account/wallet',
  },
  {
    title: 'My Collection',
    link: '/library/my-collection',
  },
  {
    title: 'Song Royalty Overview',
    link: '/account/royalty/songs'
  },
  {
    title: 'Song Royalty History',
    link: '/account/royalty/history',
  },
  { title: 'Transaction History', link: '/account/transactions' },
];

const RoyaltyOverview = () => {
  const viewport = useViewport();

  const [royaltyData, setRoyaltyData] = useState<IRoyaltyOverviewData>();

  useEffect(() => {
    restService
      .getRoyaltyOverview()
      .then((res) => {
        const data = res;
        data.total_bits = parseInt(res.total_bits);
        data.total_purchase = parseFloat(res.total_purchase);
        data.royalty_period = parseFloat(res.royalty_period);
        data.total_royalty = parseFloat(res.total_royalty);
        data.withdraw_royalty = parseFloat(res.withdraw_royalty);
        setRoyaltyData(data);
      })
      .catch((error) => {
        error;
      });
  }, []);

  return (
    <PageContainer pageTitle={'Royalty Overview | SongBits'}>
      <BreadcrumbsContainer>
        {viewport.width < 576 && <MobileBreadcrumbs />}
      </BreadcrumbsContainer>
      <PageLayout
        padding={
          viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
        }
        title="Royalty Overview"
        sections={[
          {
            content: (
              <>
                <ProfileImageContainer>
                  {!royaltyData ? (
                    <ImagePickerPlaceholder></ImagePickerPlaceholder>
                  ) : (
                    <ImagePicker
                      type="avatar"
                      image={royaltyData.profile_image}
                      handleImage={() => {}}
                    />
                  )}
                </ProfileImageContainer>
                <WidthSpacer width={20} />
              </>
            ),
          },
          {
            content: (
              <>
                {!royaltyData ? (
                  <RoyaltyDashboardOverview />
                ) : (
                  <Container>
                    {royaltyData.royalty_period_month_reported ? (
                      <>

                    <StatsSection>
                        <StatContainer>
                        <Typography
                          fontSize="fz24"
                          fontWeight="bold"
                          text={`overview for ${royaltyData.royalty_period_month_reported}*`}
                        />
                        <TipWrapper top={6}>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Royalty reports are delayed by 4 months. Royalty payments are delayed by 6 months from the song release date.'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : -22}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                        </StatContainer>
                    </StatsSection>
                      </>
                    ) : (
                      <>
                        <Typography
                          fontSize="fz24"
                          fontWeight="bold"
                          text={`overview`}
                        />
                      </>
                    )}

                    <Spacer height={5} />
                    <Typography
                      fontSize="fz14"
                      fontWeight="light"
                      fontColor={theme.colors.yellow}
                      text={`*based on delayed reports from DSP's`}
                    />

                    <Spacer height={15} />

                    <StatsSection>
                      <StatContainer>
                        <Typography
                          text={`${royaltyData.total_bits.toLocaleString(
                            'en-US',
                            {},
                          )}`}
                          fontSize="fz60"
                          fontWeight="bold"
                          lineHeight="78px"
                          letterSpacing="-0.03em"
                          fontColor={theme.colors.yellow}
                        />
                        <Typography
                          text={`total bits held`}
                          fontSize="fz24"
                          fontWeight="light"
                          lineHeight="16px"
                          fontColor={theme.colors.white}
                        />
                        <Typography
                          text={`${royaltyData.total_bits_month_diff} from last month`}
                          fontSize="fz14"
                          fontWeight="bold"
                          lineHeight="36px"
                          fontColor={theme.colors.white}
                        />

                        <SongLink to={`/account/royalty/songs`}>
                          <OpenYellow width={20} height={20} />
                          <Typography
                            text={`view song royalty overview`}
                            fontSize="fz18"
                            fontWeight="regular500"
                            lineHeight="24px"
                            letterSpacing="-0.03em"
                            fontColor={theme.colors.yellow}
                          />
                        </SongLink>
                        <TipWrapper>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Total number of bits purchased.'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : 2}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                      </StatContainer>

                      <Spacer height={3} />
                      <BottomLine />

                      <StatContainer>
                        <Typography
                          text={`$${royaltyData.total_purchase.toLocaleString(
                            'en-US',
                            { maximumFractionDigits: 2, minimumFractionDigits: 2 },
                          )}`}
                          fontSize="fz60"
                          fontWeight="bold"
                          lineHeight="78px"
                          letterSpacing="-0.03em"
                          fontColor={theme.colors.yellow}
                        />
                        <Typography
                          text={`total paid for all bits held`}
                          fontSize="fz24"
                          fontWeight="light"
                          lineHeight="16px"
                          fontColor={theme.colors.white}
                        />
                        <Typography
                          text={`average of $${royaltyData.total_purchase_avg} per bit`}
                          fontSize="fz14"
                          fontWeight="bold"
                          lineHeight="36px"
                          fontColor={theme.colors.white}
                        />

                        <SongLink to="/account/transactions">
                          <OpenYellow width={20} height={20} />
                          <Typography
                            text={`view transaction history`}
                            fontSize="fz18"
                            fontWeight="regular500"
                            lineHeight="24px"
                            letterSpacing="-0.03em"
                            fontColor={theme.colors.yellow}
                          />
                        </SongLink>
                        <TipWrapper>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Total paid for bits purchased.'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : 2}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                      </StatContainer>

                      <Spacer height={3} />
                      <BottomLine />
                      <StatContainer>
                        <Typography
                          text={`$${roundNumberDown(royaltyData.total_royalty.toString())}`}
                          fontSize="fz60"
                          fontWeight="bold"
                          lineHeight="78px"
                          letterSpacing="-0.03em"
                          fontColor={theme.colors.yellow}
                        />
                        <Typography
                          text={`lifetime royalty earnings`}
                          fontSize="fz24"
                          fontWeight="light"
                          lineHeight="16px"
                          fontColor={theme.colors.white}
                        />

                        <SongLink
                          to="/account/royalty/history"
                          style={{ marginTop: '10px' }}>
                          <OpenYellow width={20} height={20} />
                          <Typography
                            text={`view royalty history`}
                            fontSize="fz18"
                            fontWeight="regular500"
                            lineHeight="24px"
                            letterSpacing="-0.03em"
                            fontColor={theme.colors.yellow}
                          />
                        </SongLink>
                        <TipWrapper>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Royalty earned based on royalty reported to date.'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : 2}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                      </StatContainer>
                      <Spacer height={3} />
                      <BottomLine />

                      <StatContainer>
                        <Typography
                          text={`$${roundNumberDown(royaltyData.royalty_period.toString())}`}
                          fontSize="fz60"
                          fontWeight="bold"
                          lineHeight="78px"
                          letterSpacing="-0.03em"
                          fontColor={theme.colors.yellow}
                        />
                        {royaltyData.royalty_period_month_reported ? (
                          <>
                            <Typography
                              text={`royalty earned in ${royaltyData.royalty_period_month_reported}`}
                              fontSize="fz24"
                              fontWeight="light"
                              lineHeight="16px"
                              fontColor={theme.colors.white}
                            />
                          </>
                        ) : (
                          <>
                            <Typography
                              text={`royalty earned`}
                              fontSize="fz24"
                              fontWeight="light"
                              lineHeight="16px"
                              fontColor={theme.colors.white}
                            />
                          </>
                        )}

                        <Typography
                          text={`based on ${royaltyData.royalty_period_month} royalties`}
                          fontSize="fz14"
                          fontWeight="bold"
                          lineHeight="36px"
                          fontColor={theme.colors.white}
                        />

                        <SongLink to="/account/royalty/history">
                          <OpenYellow width={20} height={20} />
                          <Typography
                            text={`view royalty history`}
                            fontSize="fz18"
                            fontWeight="regular500"
                            lineHeight="24px"
                            letterSpacing="-0.03em"
                            fontColor={theme.colors.yellow}
                          />
                        </SongLink>
                        <TipWrapper>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Royalty earned in the latest period reported.'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : 2}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                      </StatContainer>
                      <Spacer height={3} />
                      <BottomLine />
                      <StatContainer>
                        <Typography
                          text={`$${roundNumberDown(royaltyData.withdraw_royalty.toString())}`}
                          fontSize="fz60"
                          fontWeight="bold"
                          lineHeight="78px"
                          letterSpacing="-0.03em"
                          fontColor={theme.colors.yellow}
                        />
                        <Typography
                          text={`available to withdraw`}
                          fontSize="fz24"
                          fontWeight="light"
                          lineHeight="16px"
                          fontColor={theme.colors.white}
                        />
                        <SongLink
                          to="/account/wallet"
                          style={{ marginTop: '10px' }}>
                          <OpenYellow width={20} height={20} />
                          <Typography
                            text={`view wallet`}
                            fontSize="fz18"
                            fontWeight="regular500"
                            lineHeight="24px"
                            letterSpacing="-0.03em"
                            fontColor={theme.colors.yellow}
                          />
                        </SongLink>
                        <TipWrapper>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Royalty that is eligible for withdrawal.'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : 2}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                      </StatContainer>
                      <Spacer height={3} />
                      <BottomLine />
                      <StatContainer>
                        <Typography
                          text={`${royaltyData.no_songs}`}
                          fontSize="fz60"
                          fontWeight="bold"
                          lineHeight="78px"
                          letterSpacing="-0.03em"
                          fontColor={theme.colors.yellow}
                        />
                        <Typography
                          text={`songs in your collection`}
                          fontSize="fz24"
                          fontWeight="light"
                          lineHeight="16px"
                          fontColor={theme.colors.white}
                        />
                        <Typography
                          text={`${royaltyData.no_songs_diff} from last month`}
                          fontSize="fz14"
                          fontWeight="bold"
                          lineHeight="36px"
                          fontColor={theme.colors.white}
                        />

                        <SongLink to={`/account/royalty/songs`}>
                          <OpenYellow width={20} height={20} />
                          <Typography
                            text={`view song royalty overview`}
                            fontSize="fz18"
                            fontWeight="regular500"
                            lineHeight="24px"
                            letterSpacing="-0.03em"
                            fontColor={theme.colors.yellow}
                          />
                        </SongLink>
                        <TipWrapper>
                          <Tip
                            text={
                              <div>
                                <Typography
                                  text={
                                    'Number of unique songs based on the bits you have purchased'
                                  }
                                  fontSize={'fz14'}
                                  lineHeight={'18px'}
                                />
                              </div>
                            }
                            width={210}
                            marginBottom={viewport.width < 576 ? 5 : 2}
                            position={viewport.width < 576 ? 'left' : 'left'}
                            left={viewport.width < 576 ? -210 : -200}
                          />
                        </TipWrapper>
                      </StatContainer>
                    </StatsSection>
                  </Container>
                )}
              </>
            ),
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                }}>
                {!royaltyData ? (
                  <QuickLinksPlaceholder />
                ) : (
                  <>
                    {viewport.width > 767 && <QuickLinks links={quickLinks} />}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 488px;

  @media (max-width: ${md}px) {
    width: 100%;
  }
`;

export const MoneyContainer = styled.div`
  width: 100%;

  @media (max-width: ${sm}px) {
    padding-left: 24px;
  }
`;

export const PeriodTitleContainer = styled.div`
  width: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${sm}px) {
    width: 123px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SongBitsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
`;

export const Tooltip = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 17px;
`;

export const WithdrawButton = styled(Button)`
  border: 3px solid gray;
  border-radius: 50px;
`;

export const StatsSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 10px;
`;

export const StatContainer = styled.div`
  position: relative;
  text-align: left;
`;

export const SongLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
`;

export const TipWrapper = styled.div<{
  top?: number;
}>`
  position: absolute;
  right: 50px;
  top: 20px;

  top: ${(props) => (props.top ? props.top : '20')}px;
`;

export default RoyaltyOverview;
