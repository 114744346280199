import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import restService from 'services/rest.service';

import PageContainer from 'common/layout/PageContainer';
import PageLayout from 'common/components/PageLayout/PageLayout';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import StatContainer from '../components/StatContainer/StatContainer';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import { OtherReleases } from '../components/Drops/OtherReleases';

import { BottomLine } from '../styled/ArtistSettings.styled';

import DropsPlaceholder from 'common/components/Placeholder/Drops';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import { useViewport } from 'use-viewport';
import theme from 'theme/theme';
import { footerHeight, footerMobileHeight } from 'utils/constants';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  {
    title: 'View Users',
    link: '/artist/insights/users',
  },
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
];

type ReleaseType = {
  title: string;
  song_image: string;
  sold_bits: string;
  total_bits: string;
  bit_price: number;
  sold_percent: string;
  drop_value: string;
  average_purchase_price: string;
  average_purchase_price_last_month_diff: string;
  drops: any[];
};

const DropSummary = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [releaseData, setReleaseData] = useState<ReleaseType>(
    {} as ReleaseType,
  );

  useEffect(() => {
    const songId = searchParams.get('id');
    if (!songId) return navigate(-1);

    restService
      .getReleaseSummary(songId)
      .then((releaseData) => {
        setReleaseData(releaseData);
      })
      .finally(() => setLoading(false));
  }, [searchParams]);

  /*function roundBitsSold(bits: number) {
    if (bits >= 1000000) {
      return (bits / 1000000).toFixed(0) + 'M';
    } else if (bits >= 1000) {
      return (bits / 1000).toFixed(0) + 'k';
    } else {
      return bits.toString();
    }
  }*/

  const firstColumn = (
    <>
      {loading ? (
        <ImagePickerPlaceholder />
      ) : (
        <DropImageWrapper>
          <DropImage src={releaseData.song_image} />
        </DropImageWrapper>
      )}
    </>
  );

  const content = (
    <>
      {loading ? (
        <>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
          <TextInputPlaceholder></TextInputPlaceholder>
        </>
      ) : (
        <Wrapper>
          <ContentContainer>
            <Typography
              text={releaseData?.title}
              fontWeight="bold"
              fontSize="fz24"
            />

            <StatContainer
              desc={`bits sold of ${releaseData?.total_bits}`}
              value={
                releaseData.sold_bits
                  ? releaseData.sold_bits
                  : ''
              }
              analytic={`${releaseData.sold_percent}% Sold`}
              tooltipText={'The number of bits sold to date'}
            />
            <BottomLine />
            <StatContainer
              desc="release value"
              value={`$${releaseData?.drop_value}`}
              analytic="representing 50% of the streaming rights sold*"
              tooltipText={'The value of the release \nwhen sold out'}
            />
            <BottomLine />
            <StatContainer
              desc="average purchase price"
              value={`$${releaseData?.average_purchase_price}`}
              analytic={`${releaseData?.average_purchase_price_last_month_diff} since last drop`}
              tooltipText={'The average purchase \nprice to date'}
            />
            <BottomLine />
            <StatContainer
              desc="bit price"
              value={`$${releaseData?.bit_price.toFixed(2).toString()}`}
              padding={10}
              tooltipText={'The bit price for the release'}
            />
            <BottomLine />

            <Spacer height={31} />

            <ButtonLink onClick={() => navigate('/artist/insights')}>
              <Typography
                text={'Insights & Analytics'}
                fontColor={theme.colors.yellow}
              />
            </ButtonLink>
          </ContentContainer>
        </Wrapper>
      )}
    </>
  );

  const additionalColumn = (
    <ContentContainer>
      {loading ? (
        <DropsPlaceholder></DropsPlaceholder>
      ) : (
        <>
          <OtherReleases drops={releaseData?.drops ?? []} />
        </>
      )}

      <Spacer height={30} />

      {loading && viewport.width >= 767 ? (
        <QuickLinksPlaceholder></QuickLinksPlaceholder>
      ) : (
        <>{viewport.width <= 767 ? <></> : <QuickLinks links={quickLinks} />}</>
      )}
    </ContentContainer>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Song Dashboard | SongBits'}>
      <PageLayout
        title="Release Summary"
        titleAlign="center"
        sections={[
          {
            content: firstColumn,
          },
          {
            content: content,
          },
          {
            content: additionalColumn,
          },
        ]}
      />
    </PageContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DropImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const DropImage = styled.img`
  width: 120px;
  height: 120px;

  border-radius: 10px;

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonLink = styled.div`
  cursor: pointer;
`;

export default DropSummary;
