import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';

import { lg, md, sm, xlg, xs } from 'utils/constants';

export const HeadingSection = styled.div`
  font-family: 'HKGrotesk-Black';
  font-weight: 900;
  font-size: 50px;
  line-height: 70px;
  letter-spacing: -0.05em;

  width: auto;
  margin-left: 0;
  margin-top: 30px;

  padding: 50px 30px 0 52px;

  @media (min-width: ${sm}px) {
    line-height: 80px;
    padding: 0;
  }

  @media (min-width: ${md}px) {
    line-height: 80px;
  }
`;

export const HeadContainer = styled.div`
  flex: 1;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 0;


  @media (min-width: ${xs}px) {
    padding-right: 48px;
  }

  @media (min-width: ${sm}px) {
    padding-left: 202px;
    padding-top: 115px;
    padding-right: 100px;
  }

  @media (min-width: ${lg}px) {
    display: flex;
    flex-directiom: column;
    justify-content: center;
    padding-top: 0;
  }
  @media (min-width: ${lg}px) and (max-height: 750px) {
    padding-top: 80px;
  }

`;

export const StrapLineBreak = styled.span`
  display: block;

  @media (min-width: ${md}px) {
    display: inline;
  }
`;

export const StrapLineTypography = styled(Typography)`
  font-size: 65px;
  line-height: 55px;

  @media (max-width: ${xs}px) {
    white-space: pre-line;
  }

  @media (min-width: ${sm}px) {
    font-size: 100px;
    line-height: 80px;
    width: 700px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
    width: 800px;
  }
`;

export const StrapLineTypographyAlt = styled(Typography)`
  font-size: 65px;
  line-height: 55px;

  @media (min-width: ${sm}px) {
    line-height: 80px;
    font-size: 100px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const StrapLineTypographyAltDot = styled(Typography)`
  font-size: 65px;
  line-height: 55px;
  margin-left: 5px;

  @media (min-width: ${sm}px) {
    line-height: 80px;
    font-size: 100px;
  }

  @media (min-width: ${xlg}px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const ButtonContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-beetwen;
  align-items: center;

  margin: ${(props) => (props.margin ? props.margin : '0')};

  @media (min-width: ${sm}px) {
    flex-direction: row;
    justify-content: start;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  left: 33%;
  position: relative;
  height: 100%;

  @media (min-width: ${sm}px) {
    height: 100%;
    left: 0;
  }
`;

export const ImageGradient = styled.div`
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: linear-gradient(
    180deg,
    #000000,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
`;

export const ShowMeLink = styled(Link)`
  text-decoration: inherit;
  font-family: 'HKGrotesk-Black';
  font-weight: 900;
  display: block;
  width: 172.23px;
  margin: 0;

  @media (min-width: ${sm}px) {
    width: auto;
    margin: initial;
  }

  cursor: pointer;
`;

export const FirstButton = styled(Button)`
  font-family: 'HKGrotesk-Black';

  width: 172.23px;
  min-width: 172.23px;

  background: #000;
  border-color: #ffd600;

  margin-bottom: 15px;
  padding: 0 32px;

  @media (min-width: ${sm}px) {
    margin-left: 0;
    margin-bottom: 0;
  }

  @media (min-width: ${xlg}px) {
    width: 220px;
    min-width: 220px;
  }
`;

export const LeftMarginButton = styled(Button)`
  font-family: 'HKGrotesk-Black';
  font-size: 16px;
  font-weight: 900;
  line-height: 101%;
  text-align: center;

  width: 100%;
  min-width: 172.23px;

  opacity: 0.5;
  background: #000;

  margin-bottom: 15px;
  padding: 0 35px;

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${sm}px) {
    margin-left: 17px;
    width: auto;
    margin-bottom: 0;
  }

  @media (min-width: ${xlg}px) {
    width: 220px;
    min-width: 220px;
  }
`;

export const VideoBackground = styled.video.attrs({
  autoPlay: true,
  muted: true,
  loop: true,
  playsinline: true,
  'webkit-playsinline': true,
})`
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';

  @media (min-width: ${xlg}px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const SubText = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;

  margin: 15px 10px 30px 0;

  @media (min-width: ${sm}px) {
    font-size: 24px;
    margin: 28px 0;
  }

  @media (min-width: ${md}px) {
    margin: 38px 0;
  }

  @media (min-width: ${xlg}px) {
    font-size: 30px;
    line-height: 30px;
  }
`;
