import { createMessage, encrypt, readKey } from 'openpgp';
import { PublicKey } from 'services/rest.service';
import store from 'store/store';

interface CardType {
  card_name: string;
  card_number: string;
  card_expiry_month: string;
  card_expiry_year: string;
  card_cvv: string;
}

export default class Encryption {
  publicKey: PublicKey;
  card: CardType;
  constructor(card: CardType) {
    this.publicKey = store.getState().authentication.publicKey;
    this.card = card;
  }

  async secure() {
    const dataToEncrypt = {
      cvv: this.card.card_cvv,
      number: this.card.card_number,
    };
    if (this.card.hasOwnProperty('card_number')) {
      dataToEncrypt.number = this.card.card_number.replace(/\s/g, '');
    }
    const decodedPublicKey = await readKey({
      armoredKey: atob(this.publicKey.publicKey),
    });
    const message = await createMessage({
      text: JSON.stringify(dataToEncrypt),
    });
    const data = await encrypt({
      message,
      encryptionKeys: decodedPublicKey,
    }).then((ciphertext) => {
      return {
        encryptedMessage: btoa(ciphertext.toString()),
        keyId: this.publicKey.keyId,
      };
    });
    return data;
  }
}

export class CVVEncryption {
  publicKey: PublicKey;
  cvv: string;
  constructor(cvv: string) {
    this.publicKey = store.getState().authentication.publicKey;
    this.cvv = cvv;
  }

  async secure() {
    const dataToEncrypt = {
      cvv: this.cvv,
    };

    const decodedPublicKey = await readKey({
      armoredKey: atob(this.publicKey.publicKey),
    });
    const message = await createMessage({
      text: JSON.stringify(dataToEncrypt),
    });
    const data = await encrypt({
      message,
      encryptionKeys: decodedPublicKey,
    }).then((ciphertext) => {
      return {
        encryptedMessage: btoa(ciphertext.toString()),
        keyId: this.publicKey.keyId,
      };
    });
    return data;
  }
}
