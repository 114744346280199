import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';

export const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  margin-top: 80px;
  padding-left: 15px;

  @media (min-width: 576px) {
    display: none;
  }
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
  mobileHide?: boolean;
  marginBottom?: number;
}>`
  display: ${(props) => (props.mobileHide ? 'none' : 'inline-block')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 30)}px;
  letter-spacing: -0.03em;
  flex-shrink: 0;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};

  @media (min-width: 768px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : 32)}px;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : 36)}px;
  }
`;

export const PlayerContainer = styled.div`
  margin: 0;
`;

export const InfoSpacer = styled.div<{ height: number; mobileHide?: boolean }>`
  height: ${(props) => props.height / 2}px;
  display: ${(props) => (props.mobileHide ? 'none' : 'block')};
  @media (min-width: 992px) {
    height: ${(props) => props.height}px;
    display: block;
  }
`;


export const ErrorMessage = styled.p`
  color: #ffffff;
  text-decoration: none;
  white-space: pre-wrap;
`;
export const ErrorTextBold = styled.p`
  color: #ffffff;
  text-decoration: none;
  font-family: 'HKGrotesk-Black';
  font-weight: 900;
  white-space: pre-wrap;
`;

export const ExplicitContainer = styled.div`
  
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  @media (min-width: 992px) {
    margin-left: 20px;
    padding-left: 5px;
  }

  @media (min-width: 1200px) {
    padding-left: 20px;
    margin-bottom: 20px;
  }
`;
