import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import USADetectionModal from 'modules/account/pages/components/Modals/USADetectionModal';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { setModalType, setShowState } from 'store-persist/reducers/modalSlice';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onMetaMaskNotInstalled: () => void;
  onBrowserNotSupported: () => void;
  onWalletConnected: () => void;
  ConnectWallet: () => void;
  onNoAvailableAddresses: () => void;
  UDSCRestrictedState: (country: string) => void;
}

const USADetection: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onMetaMaskNotInstalled,
  onBrowserNotSupported,
  onWalletConnected,
  ConnectWallet,
  onNoAvailableAddresses,
  UDSCRestrictedState,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(setModalType('USADetection'));
      dispatch(setShowState(isOpen));
    }
  }, [isOpen]);

  return (
    <SBModal
      isOpen={isOpen}
      width="434px"
      mobileWidth="100%"
      height="518px"
      top="10%"
      mobileTop="2%"
      withProceedingText={true}
      withSettings={false}
      content={
        <USADetectionModal
          onClose={onClose}
          onMetaMaskNotInstalled={onMetaMaskNotInstalled}
          onBrowserNotSupported={onBrowserNotSupported}
          onWalletConnected={onWalletConnected}
          ConnectWallet={ConnectWallet}
          onNoAvailableAddresses={onNoAvailableAddresses}
          UDSCRestrictedState={UDSCRestrictedState}
        />
      }
    />
  );
};

export default memo(USADetection);
