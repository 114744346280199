import * as React from 'react';

import { IconProps } from '../common.types';

const Twitter = ({ width = 15, height = 15, opacity = 0.5 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.2683 2.84083C28.191 3.32358 27.0339 3.64845 25.8193 3.79497C27.0597 3.04529 28.0109 1.8583 28.4589 0.442993C27.2995 1.13702 26.014 1.64135 24.6452 1.91396C23.5499 0.73605 21.9898 0 20.263 0C16.9468 0 14.2587 2.71362 14.2587 6.05878C14.2587 6.53358 14.3128 6.99702 14.4141 7.43887C9.42403 7.18671 5.00018 4.7741 2.03745 1.10748C1.52189 2.00256 1.22584 3.04302 1.22584 4.15505C1.22584 6.25642 2.28509 8.11132 3.89591 9.19722C2.91208 9.16541 1.98566 8.8928 1.17631 8.43958C1.17631 8.46571 1.17631 8.48956 1.17631 8.51569C1.17631 11.4519 3.24528 13.9009 5.99302 14.4563C5.48985 14.5949 4.95853 14.6687 4.41034 14.6687C4.02424 14.6687 3.64714 14.6301 3.2813 14.5608C4.04562 16.9678 6.26317 18.7204 8.89047 18.7693C6.83614 20.3947 4.24712 21.3636 1.43297 21.3636C0.948932 21.3636 0.470526 21.3352 0 21.2784C2.65768 22.997 5.81291 24 9.20452 24C20.2495 24 26.2875 14.7676 26.2875 6.76075C26.2875 6.49837 26.2819 6.23711 26.2707 5.977C27.4447 5.12168 28.4634 4.05509 29.2683 2.84083Z"
      fill="white"
      fillOpacity={opacity}
    />
  </svg>
);

export default Twitter;
