import React, { useEffect, useState } from 'react';

import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';

import PreviewMessage from './PreviewMessage';
import restService from 'services/rest.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import '../assets/editorSkins/songbitsskin/skin/skin.css';
import '../assets/editorSkins/songbitsskin/skin/content.css';
import { MessagePreviewOpenedFrom } from './types';

const MessagePreview = () => {
  const location = useLocation();
  const { id, from } = location.state as {
    id: string;
    from: MessagePreviewOpenedFrom;
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [messagePreview, setMessagePreview] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (id) {
      restService
        .getMessagePreview(id)
        .then((res) => {
          setMessagePreview(
            <PreviewMessage
              content={res}
              onBack={() => {
                if (from.location === 'send-message') {
                  navigate(`/artist/insights/users/send-message/${id}`);
                } else {
                  navigate(`/artist/insights/users/message-list`);
                }
              }}
            />,
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          navigate(`/artist/insights/users/message-list`, {
            state: {
              banner: {
                title: 'Error: ',
                text: 'Failed to retrieve message preview',
              },
            },
          });
        });
    }
  }, []);

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <PageLayout
        title="message preview"
        is1ColumnLayout
        sections={[
          {
            content: loading ? <></> : messagePreview,
          },
        ]}
      />
    </PageContainer>
  );
};

export default MessagePreview;
