import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import store, { useStoreActions, useStoreState } from 'store/store';
import theme from 'theme/theme';
import { md, sm } from 'utils/constants';

import { useAppDispatch } from 'hooks/reduxToolkit';
import { resetPersist } from 'store-persist/reducers/modalSlice';
import { useIntercom } from 'react-use-intercom';

type Link = { title: string; link: string };

type QuickLinksProps = {
  links: Link[];
  isLogout?: boolean;
  addFunds?: boolean;
  addFundsTrigger?: () => void;
};

const QuickLinks = ({
  links,
  isLogout,
  addFunds,
  addFundsTrigger,
}: QuickLinksProps) => {
  const navigate = useNavigate();
  const logout = useStoreActions((state) => state.authentication.setLogout);
  const viewport = useViewport();
  const dispatch = useAppDispatch();
  const { shutdown } = useIntercom();

  const isArtist = useStoreState((state) => state.authentication.isArtist);

  const navigateTo = (link: string) => () => navigate(link);

  const onLogout = () => {
    store.persist.clear().then(() => {
      dispatch(resetPersist());
      window.localStorage.removeItem('purchaseId');
      // Used to prevent unauth banner showing on logout
      window.localStorage.setItem('user_logout', 'logout');
      // Shutdown Intercom
      shutdown();
      logout();
      navigate('/');
    });
  };

  return (
    <Container>
      <Heading
        fontSize={viewport.width >= 576 ? 'fz24' : 'fz18'}
        fontWeight="bold"
        text="quick links"
        fontColor={theme.colors.yellow}
      />
      <Spacer height={10} />
      {addFunds ? (
        <>
          <NavButton
            key="abs"
            height={40}
            label={'Add Funds to Wallet'}
            className="button"
            onClick={() => {
              if (addFundsTrigger) {
                addFundsTrigger();
              }
            }}
          />
          <Spacer height={10} />
        </>
      ) : (
        <></>
      )}
      {links.map(({ title, link }, index) => (
        <React.Fragment key={`link-${title}-${index}`}>
          {title === 'Artist Dashboard' ? (
            <>
              {isArtist && (
                <>
                  <NavButton
                    key={`Key for link button item is - ${index}`}
                    height={40}
                    label={title}
                    className="button"
                    onClick={navigateTo(link)}
                  />
                  <Spacer key={`Key for space item is - ${index}`} height={9} />
                </>
              )}
            </>
          ) : (
            <>
              <NavButton
                key={`Key for link button item is - ${index}`}
                height={40}
                label={title}
                className="button"
                onClick={navigateTo(link)}
              />
              <Spacer key={`Key for space item is - ${index}`} height={9} />
            </>
          )}
        </React.Fragment>
      ))}
      {isLogout && (
        <NavButton
          height={40}
          label="Logout"
          className="button"
          onClick={onLogout}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: auto;

  @media (min-width: ${sm}px) {
    width: max-content;
  }
`;

const NavButton = styled(Button)`
  padding-left: 0;
  cursor: pointer;
  font-family: 'HKGrotesk-Regular';
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding-left: 20px;

  &:hover {
    color: ${theme.colors.yellow};
    background: none;
  }

  @media (min-width: ${sm}px) {
    font-size: ${theme.fontSizes.fz18 + 'px'};
    padding-left: 0;
  }

  @media (min-width: ${md}px) {
    padding-left: 20px;
    font-size: 18px;
  }
`;

const Heading = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  font-size: 18px;
  letter-spacing: -0.04em;

  @media (min-width: ${sm}px) {
    font-size: 24px;
  }
`;

export default QuickLinks;
