import { IMenuItem } from 'typings/types';

export const authedMenuLinks: IMenuItem[] = [
  {
    text: 'My Account',
    item: [
      { text: 'Account Settings', item: '/account/settings' },
      {
        text: 'My Wallet',
        item: [
          { text: 'Wallet', item: '/account/wallet' },
          { text: 'Transaction History', item: '/account/transactions' },
        ],
      },
      { text: 'My Collection', item: '/library/my-collection' },
      {
        text: 'My Royalties',
        item: '/account/royalty',
      },
    ],
  },
  { text: 'Releases', item: '/library/hot-releases' },
  /*{ text: 'Marketplace', item: '/marketplace' },*/ {
    text: 'Company',
    item: [
      { text: 'Information', item: '/company' },
      { text: 'Careers', item: '/careers' },
    ],
  },
  { text: 'Artists + Labels', item: '/labels-artists' },
  { text: 'FAQ', item: '/faq' },
  { text: 'Support', item: '/support' },
  { text: 'Log out', item: '/logout' },
];

export const authedMenuArtistLinks: IMenuItem[] = [
  {
    text: 'Artist Area',
    item: [
      { text: 'Artist Dashboard', item: '/artist/dashboard' },
      {
        text: 'Artist Profile',
        item: [
          { text: 'Edit Profile', item: '/artist/settings' },
          {
            text: 'View Profile',
            item: '',
          },
        ],
      },
      { text: 'Manage Releases', item: '/artist/releases' },
      { text: 'Insights & Analytics', item: '/artist/insights' },
    ],
  },
  {
    text: 'My Account',
    item: [
      { text: 'Account Settings', item: '/account/settings' },
      {
        text: 'My Wallet',
        item: [
          { text: 'Wallet', item: '/account/wallet' },
          { text: 'Transaction History', item: '/account/transactions' },
        ],
      },
      { text: 'My Collection', item: '/library/my-collection' },
    ],
  },
  { text: 'Releases', item: '/library/hot-releases' },
  /*{ text: 'Marketplace', item: '/marketplace' },*/ {
    text: 'Company',
    item: [
      { text: 'Information', item: '/company' },
      { text: 'Careers', item: '/careers' },
    ],
  },
  { text: 'Artists + Labels', item: '/labels-artists' },
  { text: 'FAQ', item: '/faq' },
  { text: 'Support', item: '/support' },
  { text: 'Log out', item: '/logout' },
];

export const unauthedMenuLinks: IMenuItem[] = [
  { text: 'Create Account', item: '/create-account' },
  { text: 'Show me how', item: '/how' },
  { text: 'Show me why', item: '/why' },
  { text: 'Show me who', item: '/who' },
  /*{text: 'Releases', item: '/library/hot-releases'},*/
  {
    text: 'Company',
    item: [
      { text: 'Information', item: '/company' },
      { text: 'Careers', item: '/careers' },
    ],
  },
  { text: 'Artists + Labels', item: '/labels-artists' },
  { text: 'Log In', item: '/login' },
];

export const unauthedMenuClaimLinks: IMenuItem[] = [
  { text: 'SongBits Giveaway', item: '/create-account' },
  { text: 'Home', item: '/' },
  {
    text: 'Company',
    item: [
      { text: 'Information', item: '/company' },
      { text: 'Careers', item: '/careers' },
    ],
  },
  { text: 'Artists + Labels', item: '/labels-artists' },
  { text: 'Log In', item: '/login' },
];
