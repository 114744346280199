import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/reduxToolkit';
import store, { useStoreActions, useStoreState } from 'store/store';

import { resetPersist } from 'store-persist/reducers/modalSlice';

import { Item, NavButton } from 'common/layout/styled/HamburgerMenu.styled';

import { IMenuItem } from 'typings/types';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';

interface MenuItemProps {
  item: IMenuItem;
  isLast?: boolean;
  isFirst?: boolean;
  onLinkClick?: () => void;
}

const MenuItem: FC<MenuItemProps> = ({
  item: menuItem,
  isLast = false,
  isFirst = false,
  onLinkClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shutdown, showSpace } = useIntercom();

  const handleFAQ = () => showSpace('help');

  const [isNestedMenuOpened, setIsNestedMenuOpened] = useState<boolean>(false);

  const slug = useStoreState((state) => state.authentication.slug);

  const logout = useStoreActions((state) => state.authentication.setLogout);

  const navigateTo = (link: string) => {
    if (onLinkClick) onLinkClick();

    if (link === '/logout') {
      store.persist.clear().then(() => {
        dispatch(resetPersist());
        window.localStorage.removeItem('purchaseId');
        // Used to prevent unauth banner showing on logout
        window.localStorage.setItem('user_logout', 'logout');
        // Shutdown Intercom
        shutdown();
        logout();
        navigate(link, { replace: true });
      });
    } else if (link === '/faq') {
      handleFAQ();
    } else {
      navigate(link);
    }
  };

  return (
    <>
      <NavButton
        className={`${menuItem.text.replace(' ', '')}-menu`}
        onClick={() => {
          if (typeof menuItem.item === 'string') {
            if (menuItem.text == 'View Profile') {
              navigateTo(`/${slug}`);
            } else {
              navigateTo(menuItem.item);
            }
          } else {
            setIsNestedMenuOpened((prev) => !prev);
          }
        }}
        label={
          <Item
            text={menuItem.text}
            fontSize="fz18"
            isLastElement={isLast}
            isFirstElement={isFirst}
          />
        }
      />

      {Array.isArray(menuItem.item) && (
        <SubMenuItem className={isNestedMenuOpened ? 'active' : ''}>
          {menuItem.item.map((item, index) => (
            <NestedMenuItem key={`Nested menu item - ${item.text} - ${index}`}>
              <MenuItem
                item={item}
                isFirst={false}
                isLast={false}
                onLinkClick={() => {
                  if (onLinkClick) onLinkClick();
                }}
              />
            </NestedMenuItem>
          ))}
        </SubMenuItem>
      )}
    </>
  );
};

const NestedMenuItem = styled.div`
  padding-left: 25px;
`;

const SubMenuItem = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: 0.75s linear;

  &.active {
    max-height: 1000px;
  }
`;

export default MenuItem;
