import { gql } from '@apollo/client';

export const UPDATE_USER_BY_ID = gql`
  mutation updateUserById(
    $id: UUID!
    $avatarId: UUID
    $location: String
    $username: Username
    $facebook: String
    $instagram: String
    $twitter: String
    $youtube: String
    $tiktok: String
    $myCollectionPublic: Boolean
    $tutorialClosed: Boolean
  ) {
    updateUserById(
      input: {
        id: $id
        userPatch: {
          avatarId: $avatarId
          location: $location
          username: $username
          facebook: $facebook
          instagram: $instagram
          twitter: $twitter
          youtube: $youtube
          tiktok: $tiktok
          myCollectionPublic: $myCollectionPublic
          tutorialClosed: $tutorialClosed
        }
      }
    ) {
      user {
        email
      }
    }
  }
`;

export const USER_UPDATE_PASSWORD = gql`
  mutation updateUserById(
    $currentPassword: PasswordType!
    $newPassword: PasswordType!
    $userid: UUID!
  ) {
    userUpdatePassword(
      input: {
        userid: $userid
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const USER_SEND_RESET_PASSWORD = gql`
  mutation userSendResetPassword($emailValue: Email!) {
    userSendResetPassword(input: { emailValue: $emailValue }) {
      responseType {
        message
      }
    }
  }
`;
